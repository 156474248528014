import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import useAuth from '../../pages/auth/useAuth';
import './LoginPage.css'; // Assuming you have similar CSS to ContactPage.css
import useMyLocation from '../../library/useMyLocation';
import useCustomAlert from '../../library/useCustomAlert';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

function LoginPage() {
    const [loginData, setLoginData] = useState({
        username: '',
        password: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const { login, currentUser } = useAuth();
    const navigate = useNavigate();
    const [location] = useMyLocation();
    const { RenderAlertModal, showAlert } = useCustomAlert();

    // Location to redirect to after successful login
    const from = location.state?.from || '/account';
    //console.log(location, from);

    useEffect(() => {
        // If already logged in, redirect
        if (currentUser?.id) {
            navigate(from);
        }
    }, [currentUser, navigate, from]);

    const handleChange = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await login(loginData.username, loginData.password);
        if (result === 1981) return;
        // console.log(result);
        if (result === 200 || result === 101 || result === 201) {
            showAlert("Please check your login name and password for incorrect characters. If the problem persists, please contact customer support or try resetting your password.", "Invalid login credentials", () => { }, false, "", true, () => { }, "Ok");
            return;
        }
        showAlert("An unknown server as occured. If the problem persists please contact customer support.", "Server Error", () => { }, false, "", true, () => { }, "Ok");
    };


    return (
        <Container className="login-container">
            <RenderAlertModal />
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <div className='form-header-login rounded-top-3 p-2 mx-auto'>
                        <h2 className='text-dark'>Login</h2>
                    </div>
                    <Form onSubmit={handleSubmit} className='form-container-login mt-0 rounded-top-0 mx-auto'>
                        <Form.Group className="mb-3" controlId="formBasicUsername">
                            <Form.Label className='form-label'>Username</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                value={loginData.username}
                                onChange={handleChange}
                                placeholder="Enter your username"
                                className='form-control'
                                style={{
                                    width: "93%"
                                }}
                                required={true}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className='form-label'>Password</Form.Label>
                            <div className='d-flex align-items-center'>
                                <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    value={loginData.password}
                                    onChange={handleChange}
                                    placeholder="Password"
                                    className='form-control'
                                    required={true}
                                />
                                {showPassword ? <EyeSlashFill className='ms-1 icon-button' size={30} onClick={() => setShowPassword(!showPassword)} />
                                    :
                                    <EyeFill className='ms-1 icon-button' size={30} onClick={() => setShowPassword(!showPassword)} />
                                }

                            </div>

                        </Form.Group>

                        <Button variant="primary" type="submit" className='btn-primary'>
                            Login
                        </Button>

                        <div className='pt-4'>
                            <small className='pe-1'>Need an account?</small>
                            <Link to={"/register"}>Register here</Link>
                        </div>
                        <div className='pt-1'>
                            <small className='pe-1'>Forgot your password?</small>
                            <Link to="/passwordreset">Reset it here</Link>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default LoginPage;
