import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import TechBackgroundImage from '../../images/tech-background.png'; // The background image used in the About page

import './TestimonialsPage.css';

function TestimonialsPage({ standalone = true }) {
    const [testimonials, setTestimonials] = useState([]);




    useEffect(() => {
        // Fake API call simulation
        const fakeTestimonials = [
            {
                id: 1,
                quote: "The AV production from X4 International was simply stunning. Our corporate event was a huge success, thanks to their top-notch technology and skilled team.",
                author: "Alice Johnson, Corporate Manager"
            },
            {
                id: 2,
                quote: "As a regular client for event management, I'm always impressed by X4's ability to handle everything with such professionalism and creativity. They truly understand our needs.",
                author: "Michael Smith, Event Coordinator"
            },
            {
                id: 3,
                quote: "X4's consulting services have been invaluable to our organization. Their strategic insights and innovative approaches have significantly enhanced our events.",
                author: "Rachel Green, Marketing Director"
            }
            // Add more testimonials as needed
        ];

        setTestimonials(fakeTestimonials);
    }, []);


    return (
        standalone ?
            <Container fluid className="testimonials-container px-0" style={{ backgroundImage: `url(${TechBackgroundImage})` }}>
                <div className='binder'>
                    <Row className="justify-content-center">
                        <Col className="text-center text-light">
                            <Card>
                                <Card.Header>
                                    <h2 className="">Client Testimonials</h2>
                                    <p>See what our clients say about our exceptional services.</p>
                                </Card.Header>
                            </Card>


                        </Col>
                    </Row>

                    {/* Testimonial Cards */}
                    <Row className="testimonials-row">
                        {testimonials.map((testimonial) => (
                            <Col key={testimonial.id} md={6} lg={4} className="testimonial-column">
                                <Card className="testimonial-card">
                                    <Card.Body>
                                        <Card.Text>
                                            "{testimonial.quote}"
                                        </Card.Text>
                                        <Card.Title>— {testimonial.author}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    {/* Additional Rows for more testimonials */}
                </div>
            </Container> :
            <>
                <Row className="justify-content-center">
                    <Col className="text-center text-light">
                        <Card>
                            <Card.Header>
                                <h2 className="">Client Testimonials</h2>
                                <p>See what our clients say about our exceptional services.</p>
                            </Card.Header>
                        </Card>


                    </Col>
                </Row>

                {/* Testimonial Cards */}
                <Row className="testimonials-row">
                    {testimonials.map((testimonial) => (
                        <Col key={testimonial.id} md={6} lg={4} className="testimonial-column">
                            <Card className="testimonial-card">
                                <Card.Body>
                                    <Card.Text>
                                        "{testimonial.quote}"
                                    </Card.Text>
                                    <Card.Title>— {testimonial.author}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </>
    );
}

export default TestimonialsPage;
