
import { MyDate, formatNumberToCurrency, is_object, is_string, isset } from "../library/library";
import QuoteRequest from "./QuoteRequest";
import Parse from 'parse';
import { Badge } from "react-bootstrap";

class Quote {
    ready = false;
    isset = false;
    objectId = null;
    userId = null;
    quoteRequestId = "";
    selectedService = "";
    toName = "";
    toCompany = "";
    toEmail = "";
    toMobileNumber = "";
    fromUserId = null;
    fromName = "";
    fromCompany = "";
    fromEmail = "";
    fromMobileNumber = "";
    amount = 0;
    tax = 0;
    total = 0;
    acceptance = "";
    serviceStartDate = null;
    serviceEndDate = null;
    serviceLocation = "";
    toAddress1 = "";
    toAddress2 = "";
    toCity = "";
    toState = "";
    toZip = "";
    items = {}; //{ 0: { quantity: 0, name: "", description: "", cost: "", notes: "" } };
    jobNotes = {}; //{ 0: { date: "", userId: "", name: "", note: "" } };
    jobNotesInternal = {} //{ 0: { date: "", userId: "", name: "", note: "" } };
    activity = { isNew: false, isUpdated: false, viewHistory: [], updateHistory: [] };
    invoiceId = "";
    isActive = true;
    date = new MyDate().nowString;
    validityDurationDays = 30;
    validTillDate = new MyDate(this.date).add(this.validityDurationDays, "DAYS").split(",")[0];
    #services = new QuoteRequest().services;
    createdAt = null;
    updatedAt = null;
    is = "QUOTE";
    error = "";




    constructor(quoteData, ready = true) {
        this.ready = ready;
        if (!isset(quoteData)) return this;
        this.isset = true;
        this.objectId = quoteData.objectId || quoteData.id;
        this.userId = is_object(quoteData.userId) && quoteData.userId.id ? quoteData.userId.id : quoteData.userId || "";
        this.quoteRequestId = quoteData.quoteRequestId?.id || quoteData.quoteRequestId || "";
        this.selectedService = quoteData.selectedService || "";
        this.toName = quoteData.toName || "";
        this.toCompany = quoteData.toCompany || "";
        this.toEmail = quoteData.toEmail || "";
        this.toMobileNumber = quoteData.toMobileNumber || "";
        this.toAddress1 = quoteData.toAddress1 || "";
        this.toAddress2 = quoteData.toAddress2 || "";
        this.toCity = quoteData.toCity || "";
        this.toState = quoteData.toState || "";
        this.toZip = quoteData.toZip || "";
        this.fromUserId = quoteData.fromUserId || "";
        this.fromName = quoteData.fromName || "";
        this.fromCompany = quoteData.fromCompany || "";
        this.fromEmail = quoteData.fromEmail || "";
        this.fromMobileNumber = quoteData.fromMobileNumber || "";
        this.amount = quoteData.amount || 0;
        this.tax = quoteData.tax || 0;
        this.total = quoteData.total || 0;
        this.serviceStartDate = quoteData.serviceStartDate || null;
        this.serviceEndDate = quoteData.serviceEndDate || null;
        this.items = quoteData.items || {};
        this.jobNotes = quoteData.jobNotes || {};
        this.jobNotesInternal = quoteData.jobNotesInternal || {};
        this.activity = quoteData.activity || { isNew: false, isUpdated: false, viewHistory: [], updateHistory: [] };
        this.acceptance = quoteData.acceptance || "";
        this.isActive = quoteData.isActive || false;
        this.serviceStartDate = this.handleDate(this.serviceStartDate);
        this.serviceEndDate = this.handleDate(this.serviceEndDate);
        this.createdAt = quoteData.createdAt || null;
        this.updatedAt = quoteData.updatedAt || null;
    }

    get id() {
        return this.objectId;
    }

    get services() {
        return this.#services;
    }

    get toUserId() {
        return this.userId;
    }


    getTabulation() {
        const currentUser = Parse.User.current()
        if (this.is === "QUOTE" && currentUser.id === this.userId) {
            console.log(this.activity);
            return {
                headers: ["Status", "Service", "To", "Company", "Email", "Mobile Number", "Amount", "Tax", "Total", "Start Date", "End Date"],
                keys: ["activity.isNew", "selectedService", "toName", "toCompany", "toEmail", "toMobileNumber", "amount", "tax", "total", "serviceStartDate", "serviceEndDate"],
                values: [
                    this.activity.isNew ? <Badge bg="success">New</Badge> : this.activity.isUpdated ? <Badge bg="success">Updated</Badge> : !this.isActive ? <Badge bg="secondary">Closed</Badge> : "",
                    this.#services[this.selectedService],
                    this.toName,
                    this.toCompany,
                    this.toEmail,
                    this.toMobileNumber,
                    formatNumberToCurrency(this.amount),
                    formatNumberToCurrency(this.tax),
                    formatNumberToCurrency(this.total),
                    this.serviceStartDate ? new MyDate(this.serviceStartDate).toString(false) : "",
                    this.serviceStartDate ? new MyDate(this.serviceEndDate).toString(false) : ""
                ]
            }
        }

        return {
            headers: ["Service", "To", "Company", "Email", "Mobile Number", "Amount", "Tax", "Total", "Start Date", "End Date"],
            keys: ["selectedService", "toName", "toCompany", "toEmail", "toMobileNumber", "amount", "tax", "total", "serviceStartDate", "serviceEndDate"],
            values: [this.#services[this.selectedService],
            this.toName,
            this.toCompany,
            this.toEmail,
            this.toMobileNumber,
            formatNumberToCurrency(this.amount),
            formatNumberToCurrency(this.tax),
            formatNumberToCurrency(this.total),
            this.serviceStartDate ? new MyDate(this.serviceStartDate).toString(false) : "",
            this.serviceStartDate ? new MyDate(this.serviceEndDate).toString(false) : ""
            ]
        }
    }

    async accept() {
        try {
            const acceptanceString = await Parse.Cloud.run('updateQuoteAcceptance', { quoteId: this.objectId });
            console.log('Quote acceptance updated:', acceptanceString);
            this.acceptance = acceptanceString;
            return acceptanceString;
        } catch (error) {
            console.error('Error updating quote acceptance:', error);
            throw new Error(error);
        }
    }

    async cancel(requester = { id: this.userId, name: this.toName }) {
        try {
            const now = new MyDate();
            const internalNotesLen = Object.values(this.jobNotesInternal).length;
            this.jobNotesInternal = { ...this.jobNotesInternal, [internalNotesLen]: { date: now.timeStamp, userId: requester.id, name: requester.name, note: `${this.is} Closed` } }
            await Parse.Cloud.run('cancelUserOrder', { quoteId: this.objectId, jobNotesInternal: this.jobNotesInternal });
            this.isActive = false;
            return true;
        } catch (error) {
            console.error('Failed to cancel order:', error);
            throw new Error(error);
        }
    }



    handleDate(dateIn) {
        if (dateIn && is_string(dateIn)) return new Date(dateIn);
        return dateIn;
    }

}



export default Quote;