import React, { useContext } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Facebook, Twitter, Instagram, Linkedin } from 'react-bootstrap-icons';
import LogoSmall from '../../images/Logo-alpha.png'
import UserContext from '../../context/UserContext';

function Footer() {
    const { siteInfo } = useContext(UserContext)

    return (
        <footer className="bg-light text-center text-lg-start footer">
            <Container className="p-4">
                <Row>
                    {/* Contact Information */}
                    <Col lg={4} md={12} className="mb-4 mb-md-0">
                        <h5 className="text-uppercase">Contact Us</h5>
                        <p>123 AV Road, City, State, Zip</p>
                        <p>Email: webmaster@x4int.com</p>
                        <p>Phone: (123) 456-7890</p>
                    </Col>

                    {/* Social Media Links */}
                    <Col lg={4} md={6} className="mb-4 mb-md-0">
                        <h5 className="text-uppercase">Follow Us</h5>
                        <a href="https://facebook.com" className="me-4 text-reset">
                            <Facebook />
                        </a>
                        <a href="https://twitter.com" className="me-4 text-reset">
                            <Twitter />
                        </a>
                        <a href="https://instagram.com" className="me-4 text-reset">
                            <Instagram />
                        </a>
                        <a href="https://linkedin.com" className="me-4 text-reset">
                            <Linkedin />
                        </a>
                    </Col>

                    {/* Legal Information */}
                    <Col lg={4} md={6} className="mb-4 mb-md-0">
                        <h5 className="text-uppercase">Legal</h5>
                        <a href="/terms" className="text-reset">Terms of Use</a><br />
                        <a href="/privacy" className="text-reset">Privacy Policy</a>
                    </Col>
                </Row>
            </Container>

            {/* Copyright */}
            <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                © {new Date().getFullYear()} <Image className="logo-small" src={LogoSmall} />
                {siteInfo.settings.companyName.substring(3)}. All rights reserved.
            </div>
        </footer>
    );
}

export default Footer;
