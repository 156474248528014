import React, { useEffect, useRef, useState } from 'react'
import { useUser } from '../../../context/UserContext';
import useAdmin from '../../../library/useAdmin';
import useCustomAlert from '../../../library/useCustomAlert';
import { is_string } from '../../../library/library';
import QuoteDisplay from '../QuoteDisplay';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useMyLocation from '../../../library/useMyLocation';
import EditAQuote from '../adminData/EditAQuote';
import Quote from '../../../classes/Quote';


function SingleQuoteDisplay() {
    const { currentUser, fetchUserQuotes, fetchUserOrders, directOpenQO, getUserQuoteOrOrder, updateQuoteViewHistory } = useUser();
    const { getAllOpenQuotesAsAdmin, saveQuote } = useAdmin();
    const navigate = useNavigate();
    const [location] = useMyLocation({ from: "" });
    const [fromLocation] = useState(location.state?.from || "/account");
    const [showQuote, setShowQuote] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const loadingQuote = useRef(false);
    const viewHistoryUpdated = useRef(false);

    useEffect(() => {
        let mounted = true;
        if (mounted && location && location?.pathname && !loadingQuote.current) {
            const quoteId = location.pathname.split("/")[3]
            //console.log(location, quoteId);
            if (quoteId) {
                loadingQuote.current = true;
                (
                    async () => {
                        const thisQuoteOrOrder = await getUserQuoteOrOrder(quoteId);
                        if (thisQuoteOrOrder.isset && thisQuoteOrOrder.ready && thisQuoteOrOrder.is === "QUOTE") {
                            setSelectedQuote(thisQuoteOrOrder);
                            setShowQuote(true);
                        }
                        if (!thisQuoteOrOrder.isset && thisQuoteOrOrder.error) {
                            showAlert(thisQuoteOrOrder.error, "Loading Error", handleCloseQuote, false, "", true);
                        }
                        if (thisQuoteOrOrder.isset && thisQuoteOrOrder.ready && thisQuoteOrOrder.is === "ORDER") {
                            showAlert("That quote has not yet been accepted to become an order.  An order hyperlink was supplied.  If you reached this page in error, please contact customer support.", "Order not found", handleCloseQuote, false, "", true);
                            setShowQuote(false);
                        }
                        loadingQuote.current = false;
                    }
                )();
            }
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [location]);


    useEffect(() => {
        let mounted = true;
        if (mounted && selectedQuote && showQuote && selectedQuote.id && !viewHistoryUpdated.current && currentUser.role === "Customer") {
            (
                async () => {
                    // console.log("calling updateQuoteViewHistory", selectedQuote.id);
                    viewHistoryUpdated.current = true;
                    await updateQuoteViewHistory(selectedQuote.id);
                }
            )();

        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [selectedQuote, showQuote, currentUser]);



    useEffect(() => {
        console.log("Updated showQuote:", showQuote, "Updated selectedQuote:", selectedQuote, "directOpenQo", directOpenQO);
        if (directOpenQO && directOpenQO.isset && directOpenQO.ready && directOpenQO.is === "QUOTE" && directOpenQO instanceof Quote === true && !showQuote && !selectedQuote) {
            setSelectedQuote(directOpenQO);
            setShowQuote(true);
        }
    }, [showQuote, selectedQuote, directOpenQO]);



    const refreshQuotes = () => {
        (
            async () => {
                const updated = await getUserQuoteOrOrder(location.pathname.split("/")[3], true);
                console.log("setting updated selected Quote", updated);
                setSelectedQuote(updated);
            }
        )();

        if (currentUser.role === "Admin") {
            getAllOpenQuotesAsAdmin(true);
        }

        if (currentUser.role === "Customer") {
            fetchUserQuotes('open', true);
            fetchUserQuotes('closed', true);
        }
    }

    //console.log("preQuotes", preQuotes);
    //console.log("currentUser", currentUser);
    // console.log("quotes", quotes);
    //console.log("quoteRequests", quoteRequests);``


    const handleCloseQuote = () => {
        setSelectedQuote(null);
        setShowQuote(false);
        navigate(fromLocation);
    }


    const handleSaveQuote = async (quoteIn) => {
        if (is_string(quoteIn.serviceStartDate)) quoteIn.serviceStartDate = new Date(quoteIn.serviceStartDate);
        if (is_string(quoteIn.serviceEndDate)) quoteIn.serviceEndDate = new Date(quoteIn.serviceEndDate);
        try {
            const savedQuote = await saveQuote(quoteIn);
            const fetchedQuotes = await getAllOpenQuotesAsAdmin(true);
            const clickedQuote = Object.values(fetchedQuotes).find(quote => quote.objectId === savedQuote.objectId);
            if (clickedQuote) {
                setSelectedQuote(clickedQuote);
            }
            refreshQuotes();
            return savedQuote;
        } catch (error) {
            throw new Error(error);
        }
    }


    const handleAcceptQuote = async (quote) => {
        setShowQuote(false);
        try {
            await quote.accept();
            refreshQuotes();
            fetchUserOrders('open', true);
            showAlert(`Quote ID ${quote.id} has been digitally signed and can now be found in your orders tab.  We look forward to working with you!`, "Quote Accepted!");
        } catch (error) {
            if (error.message === "ParseError: 137 This quote has already been accepted.") {
                showAlert(`That quote has already been accepted. You can not accept twice.`, "Invalid Signing Attempt");
            } else {
                showAlert(`A signing error has occured - please contact customer support!  We look forward to working with you!`, "Server Error!");
            }
        }

    }

    //TODO: handle view history for customer on view, etc...


    return (
        <div className='account-page'>
            <RenderAlertModal />
            {showQuote ?
                selectedQuote && currentUser.role === "Customer" ? <QuoteDisplay quote={selectedQuote} show={showQuote} onClose={handleCloseQuote} onAccept={handleAcceptQuote} noClickAway={true} />
                    : selectedQuote && currentUser.role === "Admin" ? <EditAQuote show={showQuote} handleClose={handleCloseQuote} quoteDataIn={selectedQuote} clearPreQuoteData={() => setSelectedQuote(null)} handleSave={handleSaveQuote} currentUser={currentUser}
                    /> : <></> :
                <div className='d-flex justify-content-center align-items-center w-100 text-white'>
                    <Spinner size={60} />
                    <h1 className='mx-3'>Loading, please wait...</h1>
                </div>
            }
        </div>
    )
}

export default SingleQuoteDisplay