import { useState, useEffect } from "react"
import useEventListener from "./useEventListener";

/**
 * Custom React hook to determine if a given media query matches the current state.
 * 
 * @param {string} mediaQuery - The media query string to evaluate. e.g. '(max-width: 600px)'
 * @param {boolean} [initialState=false] - Optional initial state for the media query match.
 * @returns {boolean} - A boolean value indicating whether the media query currently matches.
 *
 * This hook listens for changes in the media query's match state and updates the component
 * accordingly. It can be used for responsive designs, adapting the component's behavior or 
 * styling based on the current viewport or device characteristics.
 */
const useMediaQuery = (mediaQuery, initialState = false) => {
    const [isMatch, setIsMatch] = useState(initialState || false);
    const [mediaQueryList, setMediaQueryList] = useState(null);



    useEffect(() => {
        const list = window.matchMedia(mediaQuery);
        setMediaQueryList(list);
        setIsMatch(list.matches);

    }, [mediaQuery])

    useEventListener("change", e => setIsMatch(e.matches), mediaQueryList);

    return isMatch;
}

export default useMediaQuery