import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col, Accordion, Card, FormControl, Tooltip, OverlayTrigger, Badge, Dropdown, Stack, Spinner, Image } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Quote from '../../../classes/Quote';
import CreateAQuoteToggle from './components/CreateAQuoteToggle';
import TextInputDDS from '../../../library/components/TextInputDDS';
import User from '../../../classes/User';
import { FileEarmarkX, LockFill, QuestionCircle, Unlock, Window, WindowStack, XSquareFill } from 'react-bootstrap-icons';
import { getUSStates, is_object, is_string, objectCopy, parseStr, roundTo } from '../../../library/library';
import JobNotesIntSecQuote from './components/JobNotesIntSecQuote';
import { enUS } from 'date-fns/locale';
import useAdmin from '../../../library/useAdmin';
import useCustomAlert from '../../../library/useCustomAlert';
import ServiceItemsSectQuote from './components/ServiceItemsSectQuote';
import JobNotesPubSecQuote from './components/JobNotesPubSecQuote';
import Floppy from '../../../images/floppy.svg';
import EnvelopeArrowUp from '../../../images/envelope-arrow-up.svg'
import CopyButton from '../../../library/components/CopyButton';


/*
const dateTimeStamp = new MyDate();

const testJobNotes = {
    0: { date: dateTimeStamp.timeStamp, userId: "3fsfs23rwf", name: "Jim Smith", note: "The first note." },
    1: { date: dateTimeStamp.timeStamp, userId: "3fsfs23rwf", name: "Jim Smith", note: "Here's a second note." },
    2: { date: dateTimeStamp.timeStamp, userId: "sdf3sdf333", name: "Bob Smith", note: "This is a thrid longer note. It will have lots of text and say lots of things that are probably very important but this is a run on sentance and it's only a test so it dosen't really matter." }
};

const testItems = {
    0: {
        quantity: 2,
        name: "Wireless Microphones",
        description: "High-quality wireless microphones for clear audio capture.",
        cost: "99.99",
        notes: "Includes receiver and batteries."
    },
    1: {
        quantity: 1,
        name: "Projector",
        description: "4K HD projector for presentations and videos.",
        cost: "299.99",
        notes: "Comes with all necessary cables."
    },
    2: {
        quantity: 4,
        name: "LED Panels",
        description: "Bright and energy-efficient LED panels for lighting.",
        cost: "249.99",
        notes: "Easy setup with adjustable stands."
    },
    3: {
        quantity: 3,
        name: "Mixing Console",
        description: "12-channel audio mixer for sound control.",
        cost: "199.99",
        notes: "USB interface for direct computer recording."
    },
    4: {
        quantity: 5,
        name: "Standing Speakers",
        description: "Large standing speakers for event sound amplification.",
        cost: "150.00",
        notes: "Ensure high-quality sound distribution."
    }
}
*/


const EditAQuote = ({ show, handleSave, handleClose, quoteDataIn = new Quote(), currentUser = new User(), clearPreQuoteData }) => {
    const { fetchZipCodes, fetchCityStateCounty, fetchCityStateByZipcode, users, getUsers, clearUser, sendEmailAsAdmin } = useAdmin();
    const [toggleWindowFullScreen, setToggleWindowFullScreen] = useState(false);
    const [quote, setQuote] = useState(() => new Quote(quoteDataIn));
    const [preparerIsLocked, setPreparerIsLocked] = useState(true);
    const [customerIsLocked, setCustomerIsLocked] = useState(true);
    const [dateIsLocked, setDateIsLocked] = useState(true);
    const [placeIsLocked, setPlaceIsLocked] = useState(true);
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const [citiesList, setCitiesList] = useState([]);
    const [citiesLoading, setCitiesLoading] = useState(false);
    const [cityStateLoading, setCityStateLoading] = useState(false);
    const [zipcodeList, setZipcodeList] = useState([]);
    const [zipLoading, setZipLoading] = useState(false);
    const [addressIsValid, setAddressIsValid] = useState(true);
    const [saving, setSaving] = useState(false);
    //const saveButtonRef = useRef(null);




    // console.log("toggleClose", toggleClose, "user", user);
    console.log("quoteDataIn", quoteDataIn, "quote", quote);

    //On-Load set a refresh warning
    useEffect(() => {
        let mounted = true;
        if (mounted && show) {
            setRefreshWarning();
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [show]);

    //Load users list
    useEffect(() => {
        let mounted = true;
        if (mounted && show) {
            getUsers();
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [show]);


    //Set quote in local state from quoteDataIn
    useEffect(() => {
        let mounted = true;
        if (mounted && show && quoteDataIn) {
            setQuote(new Quote(quoteDataIn));
        }
        return () => mounted = false;
    }, [quoteDataIn, show]);

    //Lock all on load of quote
    useEffect(() => {
        let mounted = true;
        if (mounted && show && quote.isset) {
            setPreparerIsLocked(true);
            setCustomerIsLocked(true);
            setDateIsLocked(true);
            setPlaceIsLocked(true);
        }
        return () => mounted = false;
    }, [show, quote.isset]);


    //Clear cities, and zips when all empty
    useEffect(() => {
        let mounted = true;
        if (mounted && show && quoteDataIn && quote.isset && !quote.toState && !quote.toCity && !quote.toZip) {
            console.log("Clear cities, states, and zips when all empty", quote.isset, !quote.toState, !quote.toCity, !quote.toZip);
            setCitiesList([]);
            setZipcodeList([]);
        }
        return () => mounted = false;
    }, [show, quoteDataIn, quote.isset, quote.toState, quote.toCity, quote.toZip]);

    //Validate when city, state, and zip are all present
    useEffect(() => {
        let mounted = true;
        if (mounted && show && quoteDataIn && quote.isset && addressExists("local") && !cityStateLoading && !citiesList.length && !zipLoading && !zipcodeList.length) {
            console.log("Validate City state zip", quoteDataIn, addressExists("local"), !cityStateLoading, !citiesList.length, !zipLoading, !zipcodeList.length);
            validateCityStateZip(quote.toCity, quote.toState)
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [show, quoteDataIn, quote.isset]);

    //Find cities based on state when state changes
    useEffect(() => {
        let mounted = true;
        if (mounted && show && quote.toState === 2 && !citiesLoading) {
            console.log("Find cities based on state when state changes", quote, quote.toState === 2, !citiesLoading);
            loadCityListByState(quote.toState);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [show, quote.toState, citiesLoading]);


    //Find city and state when only zip entered
    useEffect(() => {
        let mounted = true;
        if (mounted && show && quote.toZip.length === 5 && !quote.toState && !quote.toCity && !cityStateLoading && !zipLoading) {
            console.log("Find city and state when only zip entered", quote.toZip.length === 5, !quote.toState, !quote.toCity, !cityStateLoading && !zipLoading);
            loadCityStateByZip(quote.toZip)
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [show, quote.toZip, quote.toState, quote.toCity, cityStateLoading, zipLoading]);


    //Find Zipcode when city and state are entered
    useEffect(() => {
        let mounted = true;

        if (mounted && quote.isset && quote.toCity.length && quote.toState.length === 2 && citiesList.length && !citiesLoading && !zipLoading) {
            console.log("Find Zipcode when city and state are entered", quote.toState, quote.toCity, !zipLoading);
            loadZipByCityState(quote.toCity, quote.toState);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [quote.toCity, quote.toState, citiesLoading]);


    //Ensure street address1 isn't empty
    useEffect(() => {
        let mounted = true;
        if (mounted && show && quote.toAddress1.length) {
            setAddressIsValid(true);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [show, quote.toAddress1]);




    const onConfirmRefresh = useCallback((event) => {
        event.preventDefault();
        return event.returnValue = "Are you sure you want to leave the page?";
    }, []);

    const setRefreshWarning = useCallback(() => {
        window.addEventListener("beforeunload", onConfirmRefresh, { capture: true });
    }, [onConfirmRefresh]);

    const removeRefreshWarning = useCallback(() => {
        window.removeEventListener("beforeunload", onConfirmRefresh, { capture: true });
    }, [onConfirmRefresh]);



    if (!show) return (<></>);



    const loadCityListByState = (state = "") => {
        setCitiesLoading(true);
        (
            async () => {
                const fetchedCityList = await fetchCityStateCounty("", state);
                setCitiesList(fetchedCityList);
                setCitiesLoading(false);
            }
        )();
    }

    const loadCityStateByZip = (zip = "") => { //TODO: this may need to be augmented to add the states also so it preloads them based on Zip
        setCityStateLoading(true);
        (
            async () => {
                const cityStateList = await fetchCityStateByZipcode(zip);
                if (cityStateList.length) {
                    setCitiesList([cityStateList[0][0]]);
                } else {
                    setCitiesList([]);
                }
                setCityStateLoading(false);
            }
        )();
    }

    const loadZipByCityState = (city = "", state = "") => {
        setZipLoading(true);
        (
            async () => {
                try {
                    const zipcodes = await fetchZipCodes(city, state);
                    if (zipcodes.length && Array.isArray(zipcodes)) {
                        const availableZips = zipcodes.flat(Infinity).map(zipCode => {
                            // Pad front leading zeros to 5 digits so it matches a quote.toZip code format
                            return zipCode.toString().padStart(5, '0');
                        });
                        setZipcodeList(availableZips);
                    }
                } catch (error) {
                    console.error('Error fetching quote.toZip codes:', error);
                } finally {
                    setZipLoading(false);
                }
            }
        )();
    }

    const validateCityStateZip = (city = "", state = "") => {
        setCitiesLoading(true);
        setZipLoading(true);
        (
            async () => {
                const fetchedCityList = await fetchCityStateCounty("", state);
                setCitiesList(fetchedCityList);
            }
        )();

        (
            async () => {
                try {
                    const zipcodes = await fetchZipCodes(city, state);
                    if (zipcodes.length && Array.isArray(zipcodes)) {
                        const availableZips = zipcodes.flat(Infinity).map(zipCode => {
                            // Pad front leading zeros to 5 digits so it matches a quote.toZip code format
                            return zipCode.toString().padStart(5, '0');
                        });
                        setZipcodeList(availableZips);
                    }
                } catch (error) {
                    console.error('Error fetching quote.toZip codes:', error);
                } finally {
                    setZipLoading(false);
                    setCitiesLoading(false);
                }
            }
        )();
    }

    const handleInputChange = (e) => {
        setQuote((prev) => {
            const newQuote = new Quote(objectCopy(prev));
            newQuote.isset = true;
            newQuote.ready = true;
            if (e?.target?.name && e?.target) newQuote[e.target.name] = e.target.value
            return newQuote;
        });
    };

    const handleDateChange = (field, date) => {
        if (!date) return setQuote((prev) => {
            const newQuote = new Quote(prev);
            newQuote.isset = true;
            newQuote.ready = true;
            newQuote[field] = null;
            return newQuote;
        });
        setQuote((prev) => {
            const newQuote = new Quote(prev);
            newQuote.isset = true;
            newQuote.ready = true;
            newQuote[field] = date.toISOString()
            return newQuote;
        });
    };

    const datesAreEqual = (dateOne, dateTwo) => {
        if (is_string(dateOne)) dateOne = new Date(dateOne);
        if (is_string(dateTwo)) dateTwo = new Date(dateTwo);
        if (dateOne instanceof Date !== true) throw new Error("dateOne 1st paramater is not a valid date")
        if (dateTwo instanceof Date !== true) throw new Error("dateTwo 1st paramater is not a valid date")
        return dateOne.toISOString() === dateTwo.toISOString();



    }


    const findZipCode = async (cityIn, stateIn) => {
        // setZipMustExist(true);
        const zipcodes = await fetchZipCodes(cityIn, stateIn);
        if (zipcodes.length && Array.isArray(zipcodes)) {
            const availableZips = zipcodes.flat(Infinity).map(zipCode => {
                // Pad front leading zeros to 5 digits so it matches a quote.toZip code format
                return zipCode.toString().padStart(5, '0');
            });
            setZipcodeList(availableZips);
        }
    }


    const refreshCityList = async (stateIn) => {
        setCitiesLoading(true);
        const citiesList = await fetchCityStateCounty("", stateIn);
        setCitiesList(citiesList);
        setCitiesLoading(false);

    }

    const clearCity = () => {
        //  setCity("");
        setCitiesList([]);
    }
    const clearState = () => {
        //  setState("");
        setCitiesLoading(false);
    }
    const clearZip = () => {
        setZipcodeList([]);
        //  setZip("");
    }



    const isSaved = () => {
        if (!quoteDataIn) return false; // No quoteDataIn means it's made from scratch
        const localPreQuote = new Quote(quote);
        //console.log(quoteDataIn, localPreQuote);
        const ignoreKeys = ["date", "ready", "isset", "items", "jobNotes", "jobNotesInternal"];
        //First level compare
        let passed = true;
        for (let index = 0; index < Object.entries(quoteDataIn).length; index++) {
            const entry = Object.entries(quoteDataIn)[index];
            if (ignoreKeys.includes(entry[0])) continue;
            if (entry[0] === "serviceStartDate" && is_string(entry[1]) && is_object(localPreQuote[entry[0]])) localPreQuote[entry[0]] = localPreQuote[entry[0]].toISOString();
            if (entry[0] === "serviceEndDate" && is_string(entry[1]) && is_object(localPreQuote[entry[0]])) localPreQuote[entry[0]] = localPreQuote[entry[0]].toISOString();
            if (entry[0] === "serviceStartDate" && is_object(localPreQuote[entry[0]])) localPreQuote[entry[0]] = localPreQuote[entry[0]].toISOString();
            if (entry[0] === "serviceEndDate" && is_object(localPreQuote[entry[0]])) localPreQuote[entry[0]] = localPreQuote[entry[0]].toISOString();
            if (entry[0] === "serviceStartDate" && is_object(entry[1])) entry[1] = entry[1].toISOString();
            if (entry[0] === "serviceEndDate" && is_object(entry[1])) entry[1] = entry[1].toISOString();
            if (localPreQuote[entry[0]] !== entry[1]) {
                // console.log(localPreQuote[entry[0]], entry[1], entry[0]);
                passed = false;
                break;
            }
        }
        if (!passed) return passed;
        //Compare object contents
        passed = isSavedObjectCheck(quoteDataIn, localPreQuote, "items", passed)
        if (!passed) return passed;
        passed = isSavedObjectCheck(quoteDataIn, localPreQuote, "jobNotes", passed)
        if (!passed) return passed;
        passed = isSavedObjectCheck(quoteDataIn, localPreQuote, "jobNotesInternal", passed)
        if (!passed) return passed;
        return passed;
    }


    const isSavedObjectCheck = (cloudData, localData, objectKeyName, passed) => {
        const cloudItems = objectCopy(cloudData[objectKeyName]);
        const localItems = objectCopy(localData[objectKeyName]);
        if (Object.entries(cloudItems).length !== Object.entries(localItems).length) return false;

        for (let index = 0; index < Object.entries(cloudItems).length; index++) {
            const entry = Object.entries(cloudItems)[index];
            if (localItems[entry[0]] !== entry[1]) {
                const localItemObject = localItems[entry[0]];
                const cloudItemObject = entry[1];
                //console.log(objectKeyName, localItemObject, cloudItemObject, entry[0]);

                for (let index = 0; index < Object.entries(localItemObject).length; index++) {
                    const itemEntry = Object.entries(localItemObject)[index];
                    // console.log(objectKeyName, "cloudItemObject[itemEntry[0]] !== itemEntry[1]", cloudItemObject[itemEntry[0]], itemEntry[1]);
                    if (cloudItemObject[itemEntry[0]] !== itemEntry[1]) passed = false
                    if (!passed) break;
                }
                if (!passed) break;
            }
        }
        return passed;
    }


    const localHandleClose = () => {
        removeRefreshWarning();
        if (!isSaved()) {
            //showAlert(message, header, onCloseCallback, showAccept, acceptVerbiage, isError, acceptCallback)
            showAlert("You have unsaved items.  Are you sure you want to close before saving?", "NOT SAVED!", () => {
                return;
            }, true, "Yes, close WITHOUT saving.", true, () => {
                closeCallStack();
            }, "Cancel");
        } else {
            closeCallStack();
        }
        return;
    }

    const closeCallStack = () => {
        removeRefreshWarning();
        handleClose();
        clearUser();
        clearPreQuoteData();
        setQuote(new Quote());
        setCitiesList([]);
        setZipcodeList([]);
        setPlaceIsLocked(false);
    }


    const localHandleSave = async (isUpdated) => {
        setSaving(true);
        if (!isUpdated) isUpdated = false;
        if (!quote.selectedService) {
            showAlert("You must select a service under 'Service Details' before saving.", "Missing required fields", undefined, undefined, undefined, true);
            setSaving(false);
            return;
        }

        if (!quote.serviceStartDate) {
            showAlert("You must add a service start date.", "Missing required fields", undefined, undefined, undefined, true);
            setSaving(false);
            return;
        }

        if (!quote.serviceEndDate) {
            showAlert("You must add a service end date", "Missing required fields", undefined, undefined, undefined, true);
            setSaving(false);
            return;
        }

        const quoteParam = objectCopy(quote)
        delete quoteParam.quoteRequestId;
        quoteParam.activity.isUpdated = isUpdated;
        quoteParam.activity.updateHistory.push(new Date().toISOString());
        try {
            const savedQuote = await handleSave(quoteParam);
            if (savedQuote) {
                if (!isUpdated) showAlert(`Quote ID: ${savedQuote.objectId} has been saved successfully.`, "Saved!");
                setQuote((prev) => {
                    const newQuote = new Quote(prev);
                    newQuote.isset = true;
                    newQuote.ready = true;
                    newQuote.objectId = savedQuote.objectId;
                    newQuote.createdAt = savedQuote.createdAt;
                    newQuote.updatedAt = savedQuote.updatedAt;
                    newQuote.isActive = savedQuote.isActive;
                    return newQuote;
                })
                setSaving(false);
                return true;
            }
        } catch (error) {
            console.error(error);
            showAlert(`An error occured while attempting to save Quote ID: ${quote.id}. The server replied with an error of: ${error.message}`, "Save Error!", () => {
                return;
            }, false, "", true);
            setSaving(false);
            return false;
        }
        showAlert("Unable to save at this time, please contact your site admin.", "Save Error!", undefined, undefined, undefined, true);
        setSaving(false);
        return false;
    }

    const localCancelQuote = (quoteId) => {
        setSaving(true);
        showAlert([`Are you sure you want to cancel Quote ID: ${quoteId}.`, ` This will remove the Quote from the customers view and mark it inactive.`], "Are you sure?", () => {
            setSaving(false);
            return;
        }, true, "Yes, cancel", false, async () => {
            try {
                const quoteParam = objectCopy(quote);
                quoteParam.isActive = false;
                if (await handleSave(quoteParam)) {
                    showAlert(`Quote ID: ${quoteId} canceled successfully. It has been marked as inactive internally, and will no longer appear for the customer.`, "Quote Canceled!", async () => {
                        setSaving(false);
                        return closeCallStack();
                    });
                } else {
                    showAlert("That Quote is not active.", "Quote already Canceled.", async () => {
                        setSaving(false);
                        return closeCallStack();
                    });
                }
            } catch (error) {
                showAlert(`An error occured while attempting to delete Quote ID: ${quoteId}. The server replied with an error of: ${error.message}`, "Save Error!", () => {
                    setSaving(false);
                    return;
                }, false, "", true);
            }
        }, "Cancel");
    }

    const localHandleNotifyCustomer = async (thisQuoteData) => {
        if (await localHandleSave(true)) {
            if (await sendEmailAsAdmin(thisQuoteData.toEmail, `Updated ${thisQuoteData.services[thisQuoteData.selectedService]} Quote`, `Hi ${thisQuoteData.toName}, We've updated your quote! You can view the quote here: http://127.0.0.1:3000/account/quote/${thisQuoteData.id}.`, true)) {
                showAlert(`Customer notification successful. ${thisQuoteData.toName} has recieved an email alert and their quote is marked as updated.`, "Notification successful.");
                return;
            }
        }
        showAlert(`An error occured while attempting to send the customer notification for Quote ID: ${thisQuoteData.id}. Please contact your site admin.`, "Notification Error!", () => { }, false, "", true);
    }




    const addressExists = (cloudORlocal = 'both') => {
        switch (cloudORlocal) {
            case "local":
                //console.log(quote.toCity, quote.toState, quote.toZip, quote.toAddress1);
                if (quote.toCity.length && quote.toState.length && quote.toZip.length && quote.toAddress1.length) return true
                break;
            case "cloud":
                if (quoteDataIn.toCity.length && quoteDataIn.toState.length && quoteDataIn.toZip.length && quoteDataIn.toAddress1.length) return true
                break;
            case "both":
                if ((quoteDataIn.toCity.length && quoteDataIn.toState.length && quoteDataIn.toZip.length && quoteDataIn.toAddress1.length)
                    && (quote.toCity.length && quote.toState.length && quote.toZip.length && quote.toAddress1.length)) return true
                break;
            case "either":
                if ((quoteDataIn.toCity.length && quoteDataIn.toState.length && quoteDataIn.toZip.length && quoteDataIn.toAddress1.length)
                    || (quote.toCity.length && quote.toState.length && quote.toZip.length && quote.toAddress1.length)) return true
                break;
            default:
                break;
        }
        return false;
    }


    function updateWasViewed(quoteIn) {
        // Ensure that the quoteIn object and the nested properties exist.
        if (!quoteIn || !quoteIn.activity || !quoteIn.activity.updateHistory || !quoteIn.activity.viewHistory) {
            console.error('Invalid quote object or missing activity history');
            return false;
        }

        // Grab the last update and view dates.
        const updateHistory = quoteIn.activity.updateHistory;
        const viewHistory = quoteIn.activity.viewHistory;

        // If there are no updates or views, return false as there's nothing to compare against.
        if (updateHistory.length === 0 || viewHistory.length === 0) return false;

        // Find the last update date.
        const lastUpdateDate = new Date(Math.max(...updateHistory.map(update => new Date(update))));

        // Check if there's any view date that is more recent than the last update date.
        return viewHistory.some(viewDate => new Date(viewDate) > lastUpdateDate);
    }




    const findUserName = () => {
        console.log(users, quote, users.find((thisUser) => thisUser.id === quote.userId));
        const foundUser = users.find((thisUser) => thisUser.id === quote.userId);
        return foundUser?.username || (<Spinner />);
    }




    const renderAddressTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <i>Street addresses are not validated.</i>
        </Tooltip>
    );

    const renderSaveToolTip = (props) =>
    (
        <Tooltip id="button-tooltip-2" {...props}>
            <i>Saving will apply the changes to the the quote instantly. If the customer views the quote, they will see the changes.  However, they will not be notified of the change untill you click, "Notify Customer".</i>
        </Tooltip>
    );




    // console.log(users);

    return (
        <Modal backdrop="static" show={show} onHide={localHandleClose} size={toggleWindowFullScreen ? "fullscreen" : "xl"}>
            <RenderAlertModal />
            <div className='rounded rounded-top-3 pb-0 mb-0' style={{ background: "white" }}>
                <Modal.Header className='d-block pb-3 mb-0' >
                    <div className='d-flex justify-content-between align-items-center'>
                        <Modal.Title>Edit Quote</Modal.Title>
                        <div className='text-white'>
                            {quote.objectId ? <><b>ID: </b><Badge className='me-3 p-1 py-0' bg="secondary">{quote.objectId}  <CopyButton textToCopy={`${window.location.origin}/account/quote/${quote.objectId}`} color="white" size="18" className="m-0 p-0 mb-1" /></Badge></> : <Badge className='me-3' bg="success">New Pre-Quote</Badge>}
                            {toggleWindowFullScreen ? <WindowStack className='myButton' size={20} onClick={() => { setToggleWindowFullScreen(false) }} /> :
                                <Window className='myButton' size={20} onClick={() => { setToggleWindowFullScreen(true) }} />}
                            <XSquareFill className='ms-3 myButton' size={20} onClick={localHandleClose} />
                        </div>
                    </div>
                </Modal.Header>
            </div>

            <Modal.Body className='pt-3'>
                <Stack direction="horizontal" gap={2} className='pb-0'>
                    <div className='text-white bold'>Customer Activity:</div>
                    {quote.activity.isNew ? <Badge bg="success">New Quote</Badge> : <></>}
                    {quote.activity.isUpdated ? <Badge bg="success">Updated Quote</Badge> : <></>}

                    {updateWasViewed(quote) ? <Badge bg="success" className='shine'>Update Viewed</Badge>
                        : quote.activity.viewHistory.length ? <Badge bg="warning" className='text-black'>Update Not Viewed</Badge> : <></>}


                    <Badge bg={quote.activity.viewHistory.length ? "secondary" : "warning"} className={quote.activity.viewHistory.length ? "" : "text-black"}>{quote.activity.viewHistory.length ? `Last View: ${new Date(objectCopy(quote).activity.viewHistory.pop()).toLocaleDateString()} - ${new Date(objectCopy(quote).activity.viewHistory.pop()).toLocaleTimeString()}` : "Never Viewed"}</Badge>

                    {quote.activity.viewHistory.length ? <Badge bg="secondary">Views: {parseStr(quote.activity.viewHistory.length)}</Badge> : <></>}

                    <Badge bg={quote.activity.updateHistory.length ? "dark" : "dark"}>{quote.activity.updateHistory.length ? `Last Update: ${new Date(objectCopy(quote).activity.updateHistory.pop()).toLocaleDateString()} - ${new Date(objectCopy(quote).activity.updateHistory.pop()).toLocaleTimeString()}` : "Never Updated"}</Badge>

                    {quote.activity.updateHistory.length ? <Badge bg="dark">Updates: {parseStr(quote.activity.updateHistory.length)}</Badge> : <></>}

                    {quote.activity.isUpdated && updateWasViewed(quote) ? <Badge bg="danger">Update Discrepancy Detected</Badge> : <></>}
                </Stack>
                <hr className='text-white' />
                <Form autoComplete="off">
                    <Accordion defaultActiveKey={['1']} alwaysOpen>

                        {/* COMPANY INFO SECTION ***** COMPANY INFO SECTION ***** COMPANY INFO SECTION ***** COMPANY INFO SECTION ***** COMPANY INFO SECTION *****  */}
                        <Card className='bg-light'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="0">Preparer Info</CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse className="" eventKey="0">
                                <Card.Body className='p-0'>
                                    <div className='d-flex w-100 justify-content-end p-2'>
                                        {preparerIsLocked ?
                                            <LockFill onClick={() => setPreparerIsLocked((lockedState) => !lockedState)} size={25} className='icon-button' /> :
                                            <Unlock onClick={() => setPreparerIsLocked((lockedState) => !lockedState)} size={25} className='icon-button' />
                                        }

                                    </div>
                                    <Row className='p-2 pb-4'>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Full Name</Form.Label>
                                                <FormControl
                                                    disabled={preparerIsLocked}
                                                    type="text"
                                                    name="fromName"
                                                    value={quote.fromName}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                                {quote.fromName && quote.fromName !== "" && quote.fromName !== quoteDataIn.fromName ?
                                                    <i className="mb-3"><small className='text-warning'>Not yet saved</small> </i> : <div className="mb-3">&nbsp;</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Company</Form.Label>
                                                <FormControl
                                                    disabled={preparerIsLocked}
                                                    type="text"
                                                    name="fromCompany"
                                                    value={quote.fromCompany}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                                {quote.fromCompany && quote.fromCompany !== "" && quote.fromCompany !== quoteDataIn.fromCompany ?
                                                    <i className="mb-3"><small className='text-warning'>Not yet saved</small> </i> : <div className="mb-3">&nbsp;</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Email</Form.Label>
                                                <FormControl
                                                    disabled={preparerIsLocked}
                                                    type="email"
                                                    name="fromEmail"
                                                    value={quote.fromEmail}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                                {quote.fromEmail && quote.fromEmail !== "" && quote.fromEmail !== quoteDataIn.fromEmail ?
                                                    <i className="mb-3"><small className='text-warning'>Not yet saved</small> </i> : <div className="mb-3">&nbsp;</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Mobile Number</Form.Label>
                                                <FormControl
                                                    disabled={preparerIsLocked}
                                                    type="tel"
                                                    name="fromMobileNumber"
                                                    value={quote.fromMobileNumber}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                                {quote.fromMobileNumber && quote.fromMobileNumber !== "" && quote.fromMobileNumber !== quoteDataIn.fromMobileNumber ?
                                                    <i className="mb-3"><small className='text-warning'>Not yet saved</small> </i> : <div className="mb-3">&nbsp;</div>}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        {/* CUSTOMER INFO SECTION ***** CUSTOMER INFO SECTION ***** CUSTOMER INFO SECTION ***** CUSTOMER INFO SECTION ***** CUSTOMER INFO SECTION ******/}
                        <Card className='bg-light'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="1">Customer Info</CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body className='p-0'>
                                    <div className='d-flex w-100 justify-content-between p-2'>
                                        <div><b>User: </b>{findUserName(quote)}</div>
                                        {customerIsLocked ?
                                            <LockFill onClick={() => setCustomerIsLocked((lockedState) => !lockedState)} size={25} className='icon-button' /> :
                                            <Unlock onClick={() => setCustomerIsLocked((lockedState) => !lockedState)} size={25} className='icon-button' />
                                        }

                                    </div>

                                    <Row className='p-2 pb-4'>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Full Name</Form.Label>
                                                <FormControl
                                                    disabled={customerIsLocked}
                                                    type="text"
                                                    name="toName"
                                                    value={quote.toName}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                                {quote.toName && quote.toName !== "" && quote.toName !== quoteDataIn.toName ?
                                                    <i className="mb-3"><small className='text-warning'>Not yet saved</small> </i> : <div className="mb-3">&nbsp;</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Company</Form.Label>
                                                <FormControl
                                                    disabled={customerIsLocked}
                                                    type="text"
                                                    name="toCompany"
                                                    value={quote.toCompany}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                                {quote.toCompany && quote.toCompany !== "" && quote.toCompany !== quoteDataIn.toCompany ?
                                                    <i className="mb-3"><small className='text-warning'>Not yet saved</small> </i> : <div className="mb-3">&nbsp;</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Email</Form.Label>
                                                <FormControl
                                                    disabled={customerIsLocked}
                                                    type="email"
                                                    name="toEmail"
                                                    value={quote.toEmail}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                                {quote.toEmail && quote.toEmail !== "" && quote.toEmail !== quoteDataIn.toEmail ?
                                                    <i className="mb-3"><small className='text-warning'>Not yet saved</small> </i> : <div className="mb-3">&nbsp;</div>}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Mobile Number</Form.Label>
                                                <FormControl
                                                    disabled={customerIsLocked}
                                                    type="tel"
                                                    name="toMobileNumber"
                                                    value={quote.toMobileNumber}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                                {quote.toMobileNumber && quote.toMobileNumber !== "" && quote.toMobileNumber !== quoteDataIn.toMobileNumber ?
                                                    <i className="mb-3"><small className='text-warning'>Not yet saved</small> </i> : <div className="mb-3">&nbsp;</div>}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        {/* TIME & PLACE SECTION ***** TIME & PLACE SECTION ***** TIME & PLACE SECTION ***** TIME & PLACE SECTION ***** TIME & PLACE SECTION ******/}
                        <Card className='bg-light'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="2">Time & Place</CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body className='p-0'>
                                    <div className='d-flex w-100 justify-content-end p-2'>
                                        {dateIsLocked ?
                                            <LockFill onClick={() => setDateIsLocked((lockedState) => !lockedState)} size={25} className='icon-button' /> :
                                            <Unlock onClick={() => setDateIsLocked((lockedState) => !lockedState)} size={25} className='icon-button' />
                                        }

                                    </div>
                                    <Row className=' m-0 pt-2 px-2'>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group className="">
                                                <Form.Label className='text-black'>Service Start Date</Form.Label>
                                                <DatePicker
                                                    disabled={dateIsLocked}
                                                    locale={enUS}
                                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                                    showIcon
                                                    minDate={new Date()}
                                                    selected={is_string(quote.serviceStartDate) ? new Date(quote.serviceStartDate) : quote.serviceStartDate}
                                                    onChange={(date) => handleDateChange('serviceStartDate', date)}
                                                    showTimeSelect
                                                    todayButton="Today"
                                                    isClearable={true}
                                                    placeholderText="Please select a start date."
                                                    style={{ zIndex: 1 }}
                                                />
                                            </Form.Group>
                                            {quote.serviceStartDate && quote.serviceStartDate !== "" && !datesAreEqual(quote.serviceStartDate, quoteDataIn.serviceStartDate) ?
                                                <i className="mb-3"><small className='text-warning'>Not yet saved</small> </i> : <div className="mb-3">&nbsp;</div>}
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group className="">
                                                <Form.Label className='text-black'>Service End Date</Form.Label>
                                                <DatePicker
                                                    disabled={dateIsLocked}
                                                    locale={enUS}
                                                    showIcon
                                                    minDate={new Date()}
                                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                                    selected={is_string(quote.serviceEndDate) ? new Date(quote.serviceEndDate) : quote.serviceEndDate}
                                                    showTimeSelect
                                                    onChange={(date) => handleDateChange('serviceEndDate', date)}
                                                    todayButton="Today"
                                                    isClearable={true}
                                                    placeholderText="Please select an end date."
                                                />
                                            </Form.Group>
                                            {quote.serviceEndDate && quote.serviceEndDate !== "" && !datesAreEqual(quote.serviceEndDate, quoteDataIn.serviceEndDate) ?
                                                <i className="mb-3"><small className='text-warning'>Not yet saved</small> </i> : <div className="mb-3">&nbsp;</div>}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <div className='d-flex w-100 justify-content-end p-2'>
                                        {placeIsLocked ?
                                            <LockFill onClick={() => setPlaceIsLocked((lockedState) => !lockedState)} size={25} className='icon-button' /> :
                                            <Unlock onClick={() => setPlaceIsLocked((lockedState) => !lockedState)} size={25} className='icon-button' />
                                        }

                                    </div>
                                    <Row className=' m-0 pt-2 px-2'>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group controlId="formGridAddress1">
                                                <Form.Label className='text-black d-flex'>Address
                                                    <OverlayTrigger overlay={renderAddressTooltip}>
                                                        <QuestionCircle size={12} className='ms-1 pointer text-secondary' />
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="toAddress1"
                                                    disabled={placeIsLocked}
                                                    value={quote.toAddress1}
                                                    onChange={(e) => handleInputChange(e)}
                                                    isInvalid={!addressIsValid}
                                                    autoComplete="off"

                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    Street address is required.
                                                </Form.Control.Feedback>
                                                {quote.toAddress1 && quote.toAddress1 !== "" && quote.toAddress1 !== quoteDataIn.toAddress1 ?
                                                    <i><small className='text-warning'>Not yet saved</small> </i> : <div>&nbsp;</div>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group controlId="formGridAddress2">
                                                <Form.Label className='text-black'>Apt, floor, etc...</Form.Label>
                                                <Form.Control
                                                    name="toAddress2"
                                                    type="text"
                                                    disabled={placeIsLocked}
                                                    value={quote.toAddress2}
                                                    onChange={(e) => handleInputChange(e)}
                                                />
                                                {quote.toAddress2 && quote.toAddress2 !== "" && quote.toAddress2 !== quoteDataIn.toAddress2 ?
                                                    <i><small className='text-warning'>Not yet saved</small> </i> : <div>&nbsp;</div>}
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row className='m-0 p-2 pb-4 align-items-center'>
                                        <Col md={6} lg={4} xl={3}>

                                            <TextInputDDS
                                                name="toCity"
                                                label="City"
                                                disabled={placeIsLocked}
                                                autoMenuOpen={true}
                                                options={citiesList}
                                                selectedValue={quote.toCity}
                                                onSelect={(value, e) => { handleInputChange(e); }}
                                                mustExist={quote.toState.length}
                                                onClear={clearCity}
                                                fixedFeedbackLocation={true}
                                                feedBackReplacement={quote.toCity && quote.toCity !== "" && quote.toCity !== quoteDataIn.toCity ?
                                                    <i><small className='text-warning'>Not yet saved</small> </i> : <div>&nbsp;</div>}
                                            />
                                            {quote.toState && !citiesList.length && !placeIsLocked ?
                                                <small className='text-primary link pointer icon-link icon-link-hover' onClick={() => refreshCityList(quote.toState || quote.toState || "")}>Lookup Cities</small> : <div></div>}

                                        </Col>

                                        <Col md={6} lg={4} xl={3}>
                                            <TextInputDDS
                                                name="toState"
                                                label="State"
                                                placeholder="Choose..."
                                                disabled={placeIsLocked}
                                                options={getUSStates(false)}
                                                selectedValue={quote.toState}
                                                onSelect={(value, e) => { handleInputChange(e); }}
                                                onClear={clearState}
                                                mustExist={true}
                                                fixedFeedbackLocation={true}
                                                feedBackReplacement={quote.toState && quote.toState !== "" && quote.toState !== quoteDataIn.toState ?
                                                    <i><small className='text-warning'>Not yet saved</small> </i> : <div>&nbsp;</div>}
                                            />

                                        </Col>

                                        <Col md={5} lg={3} xl={2}>

                                            <TextInputDDS
                                                name="toZip"
                                                label="Zip"
                                                disabled={placeIsLocked}
                                                options={zipcodeList}
                                                selectedValue={quote.toZip}
                                                onSelect={(value, e) => { handleInputChange(e); }}
                                                onClear={clearZip}
                                                mustExist={true}
                                                fixedFeedbackLocation={true}
                                                autoMenuOpen={true}
                                                feedBackReplacement={quote.toZip && quote.toZip !== "" && quote.toZip !== quoteDataIn.toZip ?
                                                    <i><small className='text-warning'>Not yet saved</small> </i> : <div>&nbsp;</div>}
                                            />

                                        </Col>
                                        <Col md={3} xl={2}>
                                            {quote.toCity && quote.toState && !zipcodeList.length && !placeIsLocked ? <Button variant="info" onClick={() => findZipCode(quote.toCity, quote.toState)}><i>Lookup Zip</i></Button> : <></>}


                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        {/* SERVICE DETAILS SECTION ***** SERVICE DETAILS SECTION ***** SERVICE DETAILS SECTION ***** SERVICE DETAILS SECTION ******/}
                        <Card className='bg-light-gray'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="3">Service Details</CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>

                                    <div className='d-flex align-items-end mb-4'>
                                        <h5 className='me-3'>Service Type: </h5>
                                        <Dropdown onSelect={(eventKey, event) => setQuote((prev) => {
                                            const newQuote = new Quote(prev);
                                            newQuote.isset = true;
                                            newQuote.ready = true;
                                            newQuote.selectedService = event.target.attributes.value.nodeValue
                                            return newQuote;
                                        })}
                                        >
                                            <Dropdown.Toggle variant={quote.selectedService ? "secondary" : "warning"}>
                                                {quote.selectedService && quote?.services ? quote.services[quote.selectedService] : <b>Select a service...</b>}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {quote?.services ? Object.entries(quote.services).map((entry, index) => {
                                                    return <Dropdown.Item key={entry[0]} aria-selected={entry[0]} eventKey={index + 1} value={entry[0]}>{entry[1]}</Dropdown.Item>
                                                }) : <></>}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <Stack direction="horizontal" className='justify-content-end'>
                                        <div className='px-2'><b>Amount:</b> ${roundTo(quote.amount, 2, true)}</div>
                                        <div className='px-2'><b>Tax:</b> ${roundTo(quote.tax, 2, true)}</div>
                                        <div className='ps-2'><b>Total:</b> ${roundTo(quote.total, 2, true)}</div>
                                    </Stack>
                                    <ServiceItemsSectQuote quoteDataIn={quote} setQuote={setQuote} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        {/* ******** JOB NOTES PUBLIC SECTION ***** JOB NOTES PUBLIC SECTION ***** JOB NOTES PUBLIC SECTION ***** JOB NOTES PUBLIC SECTION ***** */}
                        <Card className='bg-light-blue'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="4">Job Notes | <span className='text-white '>Public</span></CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    <JobNotesPubSecQuote jobNotesIn={quote.jobNotes} quote={quote} setQuote={setQuote} currentUser={currentUser} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        {/* Job Notes | Internal Section */}
                        <Card className='bg-warning'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="5">Job Notes | <span className='text-warning bold'>Internal</span></CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    {/* JOB NOTES INTERNAL SECTION ***** JOB NOTES INTERNAL SECTION ***** JOB NOTES INTERNAL SECTION ***** JOB NOTES INTERNAL SECTION ******/}
                                    <JobNotesIntSecQuote jobNotesIn={quote.jobNotesInternal} quote={quote} setQuote={setQuote} currentUser={currentUser} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>


                    </Accordion>

                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                <div>
                    <Button onClick={() => localCancelQuote(quote.id)} variant='warning' disabled={saving}>
                        {saving ? <Spinner /> : <>Cancel Quote <FileEarmarkX size={18} /> </>}
                    </Button>

                    <Button className='mx-4' variant="success" onClick={() => localHandleNotifyCustomer(quote)} disabled={saving}>
                        {saving ? <Spinner /> : <>Notify Customer <img alt="envelope-arrow-up" className='m-0 p-0 pb-1' src={EnvelopeArrowUp} /></>}
                    </Button>

                </div>

                <div className='d-flex'>
                    <OverlayTrigger placement="top" overlay={renderSaveToolTip} >
                        <Button variant="primary" className='m-0 mx-4' onClick={() => localHandleSave(false)} disabled={saving}>

                            {saving ? <Spinner /> : <>Save <img alt="save-floppy-icon" className='m-0 p-0 pb-1' src={Floppy} /></>}
                        </Button>
                    </OverlayTrigger>
                    <Button variant="secondary" className="m-0" onClick={localHandleClose}>
                        Close
                    </Button>
                </div>

            </Modal.Footer>
        </Modal>
    );
};

export default EditAQuote;
