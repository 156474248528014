import { isset, objectHasKey } from "../library/library"


class CountsAdminDash {
    isset = false;
    error = false;
    users = {
        total: 0,
        admins: 0,
        customers: 0,
    }
    quotes = {
        requests: 0,
        total: 0,
        open: 0,
        closed: 0
    }
    orders = {
        total: 0,
        open: 0,
        closed: 0
    }
    invoices = {
        total: 0,
        paid: 0,
        pending: 0
    }

    constructor(apiReturn) {
        if (!isset(apiReturn)) return this;
        if (!objectHasKey(apiReturn, "users") || !objectHasKey(apiReturn, "quotes") || !objectHasKey(apiReturn, "orders") || !objectHasKey(apiReturn, "invoices")) {
            this.error = true;
            return this;
        }
        this.users.total = apiReturn.users.total;
        this.users.admins = apiReturn.users.admins;
        this.users.customers = apiReturn.users.customers;
        this.quotes.total = apiReturn.quotes.total;
        this.quotes.requests = apiReturn.quotes.requests;
        this.quotes.open = apiReturn.quotes.open;
        this.quotes.closed = apiReturn.quotes.closed;
        this.orders.total = apiReturn.orders.total;
        this.orders.open = apiReturn.orders.open;
        this.orders.closed = apiReturn.orders.closed;
        this.invoices.total = apiReturn.invoices.total;
        this.invoices.paid = apiReturn.invoices.paid;
        this.invoices.pending = apiReturn.invoices.pending;
        this.isset = true;
        this.error = false;
    }

}

export default CountsAdminDash;