import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { is_object, isset, objectCopy, objectHasKey } from './library';




/**
 * Custom hook to  handle location, includes a setter and clear function. Requires react-router-dom.
 * @param {{}} defaultState - The default state for the location. Note: this won't overwrite existing state, only ensure the proper keys exist.
 * @returns {[myLocation:Location, setLocationState:Function, clearLocationState:Function]} An array containing the state value, a setter function, a delete function, and a function to check expiration.
 */
export default function useMyLocation(defaultState) {
    const location = useLocation();
    const [myLocation, setMyLocation] = useState({ ...location, state: location?.state ? { ...defaultState, ...location.state } : defaultState });

    useEffect(() => {
        let mounted = true;
        if (mounted && isset(defaultState)) {
            const currentLocation = Object.assign(objectCopy(location), location);
            Object.keys(defaultState).forEach((key) => {
                if (!objectHasKey(currentLocation.state, key)) {
                    if (!is_object(currentLocation.state)) currentLocation.state = {};
                    currentLocation.state[key] = "";
                }
            });
            window.history.replaceState({ ...currentLocation }, document.title);
        }
        return () => mounted = false;
    }, [defaultState, location]);


    const setLocationState = (newState) => {
        window.history.replaceState({ ...newState }, document.title);

        setMyLocation((currentLocation) => {
            return { ...currentLocation, state: newState }
        });
    }

    const clearLocationState = () => {
        window.history.replaceState({ ...defaultState }, document.title);
        setMyLocation((currentLocation) => {
            return { ...currentLocation, state: defaultState }
        });
    }

    return [myLocation, setLocationState, clearLocationState];
}

