import React, { useState, useEffect } from 'react'
import ReactiveTable from './ReactiveTable';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { getType, intval, objectCopy, strval } from '../../../../library/library';
import Quote from '../../../../classes/Quote';
import { ArrowCounterclockwise } from 'react-bootstrap-icons';

function ServiceItemsSectQuote({ quoteDataIn, setQuote }) {
    const [itemRow, setItemRow] = useState("");
    const [itemEditData, setItemEditData] = useState({ quantity: 0, name: "", description: "", cost: 0, notes: "" });
    const [itemHistory, setItemHistory] = useState([]); // History stack for undo functionality


    useEffect(() => {
        let mounted = true;
        if (mounted && intval(itemRow) < intval(Object.values(quoteDataIn.items).length) && itemRow !== "") {
            //console.log("Setting setItemEditData(quoteDataIn.items[itemRow])", itemRow, quoteDataIn.items[itemRow], { ...quoteDataIn.items[itemRow] });
            setItemEditData(quoteDataIn.items[itemRow]);
        }
        if (mounted && strval(itemRow) === "0" && intval(Object.values(quoteDataIn.items).length) === 1) {
            setItemEditData(quoteDataIn.items[itemRow]);
        }
        if (mounted && itemRow === "") {
            setItemEditData({ quantity: "", name: "", description: "", cost: "", notes: "" });
        }
        if (checkItemIDs(quoteDataIn.items)) {
            reKeyItemIDs(quoteDataIn);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [itemRow, quoteDataIn.items]);

    const checkItemIDs = (items) => {
        let test = false;
        let lastKey = 0;
        Object.keys(items).forEach((key) => {
            const intKey = intval(key);
            if (intKey > 0 && intKey !== lastKey + 1) {
                test = true;
            }
            lastKey = intval(key);
        });
        return test;
    }

    const reKeyItemIDs = (quoteDataIn) => {
        const newItems = {};
        delete quoteDataIn.getItemsTabulation;
        Object.values(quoteDataIn.items).forEach((value, index) => {
            if (!getType(value, "function")) newItems[index] = value;
        });
        const newPreQuote = new Quote(quoteDataIn);
        newPreQuote.items = newItems;
        // newPreQuote.items["getItemsTabulation"] = newPreQuote.getItemsTabulation;
        setQuote(newPreQuote);
    }




    //Service Items Functions
    const updateItemHistory = () => {
        setItemHistory(prevHistory => [...prevHistory, { ...quoteDataIn.items }]);
    }

    const addUpdateItem = () => {
        updateItemHistory();
        setQuote((prev) => {
            const newPreQuote = new Quote(objectCopy(prev));
            newPreQuote.items = { ...objectCopy(prev).items, [itemRow]: itemEditData }
            return newPreQuote;
        });
    };


    const removeItem = () => {
        updateItemHistory();
        setQuote((prev) => {
            const newPreQuote = new Quote(objectCopy(prev));
            delete newPreQuote.items[itemRow];
            return newPreQuote;
        });
        setItemRow("");
    };


    const handleUndo = () => {
        if (itemHistory.length === 0) {
            //console.log("No more actions to undo");
            return;
        }
        // Use slice to create a new array
        const newHistory = itemHistory.slice();
        const previousItems = newHistory.pop();

        setItemHistory(newHistory);
        setQuote(prev => ({ ...prev, items: previousItems }));
    };

    const handleItemTableClick = (table, rowNum, rowData, cellData, event) => {
        // console.log(table, rowNum, rowData, cellData, event);
        setItemRow(() => rowNum);
    }

    const emptyItemCheck = () => {
        if (itemEditData.quantity === "" || itemEditData.name === "" || itemEditData.description === "" || itemEditData.cost === "") {
            return true;
        }
        return false;
    }

    const newItemPrep = () => {
        setItemRow("");
        setTimeout(() => {
            setItemRow(intval(Object.values(quoteDataIn.items).length));
        }, 10);
    }

    //console.log("Object.values(quoteDataIn.items)", Object.values(quoteDataIn.items));
    // console.log("itemRow", itemRow);
    // console.log("itemEditData", itemEditData);
    return (
        <>
            <ReactiveTable
                data={Object.values(quoteDataIn.items)}
                headerOverride={["Quantity", "Name", "Description", "Cost", "Notes"]}
                clickHandler={handleItemTableClick}
                useRowNumbers={true}
                rowNumberHeader="ID"

            />

            <Row>
                <Col xs={6} lg={3}>
                    <Form.Group>
                        <Form.Label className='text-black'>Item ID {(intval(itemRow) === intval(Object.values(quoteDataIn.items).length) && itemRow !== "")
                            || itemRow === "" ?
                            <i className='text-success smaller shimmer text-button' onClick={newItemPrep}>New item</i> : <></>
                        }</Form.Label>
                        <Form.Control
                            type="number"
                            name="itemId"
                            value={itemRow}
                            onChange={(e) => setItemRow(() => {
                                if (e.target.value >= 1 && e.target.value <= intval(Object.values(quoteDataIn.items).length)) {
                                    return e.target.value;
                                }
                                if (e.target.value > intval(Object.values(quoteDataIn.items).length)) {
                                    return intval(Object.values(quoteDataIn.items).length);
                                }
                                if (e.target.value <= 0) {
                                    return 0;
                                }
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col xs={6} lg={3}>
                    <Form.Group>
                        <Form.Label className='text-black'>Quantity</Form.Label>
                        <Form.Control
                            type="number"
                            name="quantity"
                            value={itemEditData.quantity || ""}
                            onChange={(e) => setItemEditData((prev) => {
                                return { ...prev, [e.target.name]: intval(e.target.value) };
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} lg={4}>
                    <Form.Group>
                        <Form.Label className='text-black'>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={itemEditData.name || ""}
                            onChange={(e) => setItemEditData((prev) => {
                                return { ...prev, [e.target.name]: e.target.value };
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} lg={4}>
                    <Form.Group>
                        <Form.Label className='text-black'>Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            value={itemEditData.description || ""}
                            onChange={(e) => setItemEditData((prev) => {
                                return { ...prev, [e.target.name]: e.target.value };
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col xs={6} lg={2}>
                    <Form.Group>
                        <Form.Label className='text-black'>Cost</Form.Label>
                        <Form.Control
                            type="number"
                            name="cost"
                            value={itemEditData.cost || ""}
                            onChange={(e) => setItemEditData((prev) => {
                                return { ...prev, [e.target.name]: e.target.value };
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} lg={6}>
                    <Form.Group>
                        <Form.Label className='text-black'>Notes</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            name="notes"
                            value={itemEditData.notes || ""}
                            onChange={(e) => setItemEditData((prev) => {
                                return { ...prev, [e.target.name]: e.target.value };
                            })}
                        />
                    </Form.Group>
                </Col>

            </Row>
            <Row className='mt-3'>
                <Col>
                    {intval(itemRow) === intval(Object.values(quoteDataIn.items).length) ?
                        <Button disabled={emptyItemCheck()} className='m-2' variant='success' onClick={addUpdateItem}>Add Item</Button> : <Button disabled={itemRow === "" || emptyItemCheck() ? true : false} variant='success' onClick={addUpdateItem}>Update Item</Button>}

                    <Button className='m-2' variant="danger" onClick={removeItem}
                        disabled={intval(itemRow) >= intval(Object.values(quoteDataIn.items).length) || itemRow === "" ? true : false}>Remove Item</Button>
                    <Button className='m-2' variant="secondary" onClick={() => setItemRow("")}>Clear</Button>
                    <Button disabled={itemHistory.length === 0} className={`m-2 ${itemHistory.length === 0 ? "border-0" : ""}`} variant="secondary-outline" onClick={handleUndo}> <ArrowCounterclockwise size={25} /> Undo</Button>
                </Col>

            </Row>
        </>
    )
}

export default ServiceItemsSectQuote