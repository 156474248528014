import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import Parse from 'parse';

import './ContactPage.css';
import useCustomAlert from '../../library/useCustomAlert';

//const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

function ContactPage() {
    const recaptchaRef = React.useRef();
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [recaptchaReady, setRecaptchaReady] = useState(false);

    useEffect(() => {
        // Load reCAPTCHA script
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LdQbxQpAAAAAGYylRNs7JFZP3JLbzRmpQmmIKbP';
        script.addEventListener('load', () => setRecaptchaReady(true));
        document.body.appendChild(script);

    }, []);

    const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current.executeAsync();
        // apply to form data
        console.log("token", token);
        const newFormData = handleCaptcha(token);
        const ReCAPTCHAVerification = await requestReCAPTCHAAssessment(newFormData);
        newFormData.recaptchaValidation = ReCAPTCHAVerification;
        return newFormData;
    }

    const requestReCAPTCHAAssessment = async (newFormData) => {
        const requestBody = {
            event: {
                token: newFormData.recaptcha,
                siteKey: "6LdQbxQpAAAAAGYylRNs7JFZP3JLbzRmpQmmIKbP",
                expectedAction: 'contact-form-submit'
            }
        };

        try {
            const response = await fetch("https://recaptchaenterprise.googleapis.com/v1/projects/civil-hash-405620/assessments?key=AIzaSyD336a_Ucs_jzhGTvET9FSibjak1VdlIrw", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log('reCAPTCHA assessment:', responseData);
                return responseData;
                // You can check the assessment score here and decide whether to submit the form data
            } else {
                console.error('reCAPTCHA assessment failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during reCAPTCHA verification:', error);
            return error;
        }
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCaptcha = (value) => {
        const newFormData = { ...formData, recaptcha: value, recaptchaValidation: "" };
        setFormData(newFormData);
        return newFormData;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitFormData = await onSubmitWithReCAPTCHA();
        if (!submitFormData.recaptcha) {
            alert('Please verify that you are a human!');
            return;
        }
        console.log('Form data:', submitFormData);
        try {
            const result = await Parse.Cloud.run("submitContactForm", submitFormData);
            if (result) {
                showAlert("Form submitted successfully!", "Message Sent");
                setFormData({ name: '', email: '', phone: '', message: '' });
            } else {
                showAlert("Form submission failed with an unknown error.", "Unable To Send", () => setFormData({ name: '', email: '', phone: '', message: '' }), false, "", true);
                console.log('Form submission failed with an unknown error.', result);
            }
        } catch (error) {
            showAlert("We appologize, we're unable to send your message at this time.  If the problem persists, please contact customer support via the email address in the website footer.", "Send Error", () => setFormData({ name: '', email: '', phone: '', message: '' }), false, "", true);
            console.error('Error submitting form:', error);
        }
    };

    return (
        <Container className="contact-container">
            <RenderAlertModal />
            {recaptchaReady ? <Row className="justify-content-md-center">
                <Col md={6}>
                    <div className='form-header rounded-top-3 p-2'>
                        <h2 className='text-dark'>Contact Us</h2>
                    </div>
                    <Form onSubmit={handleSubmit} className='form-container mt-0 rounded-top-0 mx-auto'>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label className='form-label'>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                autoComplete="name"
                                className='form-control'
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className='form-label'>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter email"
                                autoComplete="email"
                                className='form-control'
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPhone">
                            <Form.Label className='form-label'>Phone (Optional)</Form.Label>
                            <Form.Control
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="Enter phone number"
                                autoComplete="tel"
                                className='form-control'
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicMessage">
                            <Form.Label className='form-label'>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows={3}
                                placeholder="Your message"
                                className='form-control'
                            />
                        </Form.Group>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LdQbxQpAAAAAGYylRNs7JFZP3JLbzRmpQmmIKbP"// Replace with your reCAPTCHA site key
                            size="invisible"
                            onChange={handleCaptcha}
                        />
                        <Button variant="primary" type="submit" className='btn-primary'>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row> : <div></div>}
        </Container>
    );
}

export default ContactPage;
