import { MyDate, isset } from "../library/library";


class User {
    id = null;
    ready = false;
    isset = false;
    username = null;
    email = "";
    emailVerified = false;
    recaptcha = null;
    recaptchaValidation = null;
    createdAt = null;
    updatedAt = null;
    role = null;
    error = "";
    firstname = "";
    lastname = "";
    company = "";
    mobileNumber = "";
    officeNumber = "";
    billingStreet = "";
    billingStreet2 = "";
    billingCity = "";
    billingState = "";
    billingZip = "";
    form = {
        username: { name: "Username" },
        email: { name: "Email" },
        password: { name: "Password" },
        firstname: { name: "First Name" },
        lastname: { name: "Last Name" },
        company: { name: "Company Name" },
        mobileNumber: { name: "Mobile Number" },
        officeNumber: { name: "Office Number" },
        billingStreet: { name: "Street Address" },
        billingStreet2: { name: "Apt, Box, etc." },
        billingCity: { name: "City" },
        billingState: { name: "State" },
        billingZip: { name: "Zipcode" },
    }

    constructor(currentUser) {
        if (currentUser === null || currentUser === undefined || currentUser === false || !currentUser.hasOwnProperty('id')) {
            this.id = null;
            this.username = null;
            this.email = null;
            this.recaptcha = null;
            this.recaptchaValidation = null;
            this.createdAt = null;
            this.updatedAt = null;
            this.error = "User class paramater currentUser not found or missing id";
            return this;
        }
        this.id = currentUser.id;
        this.isset = true;
        const { username, email, recaptcha, recaptchaValidation, createdAt, updatedAt, firstname, lastname, company, mobileNumber, officeNumber, billingStreet, billingStreet2, billingCity, billingState, billingZip, role, emailVerified } = currentUser.attributes;
        this.username = username;
        this.email = email;
        this.recaptcha = recaptcha;
        this.recaptchaValidation = recaptchaValidation;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        if (isset(emailVerified)) this.emailVerified = emailVerified;
        if (isset(firstname)) this.firstname = firstname;
        if (isset(lastname)) this.lastname = lastname;
        if (isset(company)) this.company = company;
        if (isset(mobileNumber)) this.mobileNumber = mobileNumber;
        if (isset(officeNumber)) this.officeNumber = officeNumber;
        if (isset(billingStreet)) this.billingStreet = billingStreet;
        if (isset(billingStreet2)) this.billingStreet2 = billingStreet2;
        if (isset(billingCity)) this.billingCity = billingCity;
        if (isset(billingState)) this.billingState = billingState;
        if (isset(billingZip)) this.billingZip = billingZip;
        if (isset(currentUser.role)) this.role = currentUser.role;
        if (isset(role)) this.role = role;
    };

    get name() {
        if (!isset(this.firstname) && !isset(this.lastname)) return "";
        return `${this.firstname} ${this.lastname}`;
    }

    get objectId() {
        return this.id;
    }

    getTabulation() {
        return {
            headers: ["Username", "Email Address", "Name", "Company", "City", "State", "Join Date"],
            keys: ["username", "email", "name", "company", "billingCity", "billingState", "createdAt"],
            values: [this.username,
            this.email,
            this.name,
            this.company,
            this.billingCity,
            this.billingState,
            new MyDate(this.createdAt).date
            ]
        }
    }

}

export default User