import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import useAdmin from '../../../library/useAdmin';
import ReactiveTable from './components/ReactiveTable';
import User from '../../../classes/User';
import { rehydrateObject } from '../../../library/library';
import { ArrowClockwise } from 'react-bootstrap-icons';

function Users() {
    const { getUsers, users } = useAdmin();
    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [administrators, setAdministrators] = useState([]);


    console.log("customers", customers);
    console.log("administrators", administrators);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getUsers()
        }
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let mounted = true;
        if (mounted) {

            if (users.length && users[0] instanceof User) {
                console.log("users", users);
                setCustomers(users.filter(user => user.role === 'Customer'));
                setAdministrators(users.filter(user => user.role === 'Admin'));
                setIsLoading(false);
            } else if (users.length && typeof users[0] === "object") {
                const rehydratedUsers = users.map((userObject) => {
                    userObject.attributes = { ...userObject };
                    return new User(userObject);
                });

                setCustomers(rehydratedUsers.filter(user => user.role === 'Customer'));
                setAdministrators(rehydratedUsers.filter(user => user.role === 'Admin'));
                setIsLoading(false);
            }
        }
        return () => mounted = false;
    }, [users]);


    const handleRowClick = (location, value, e) => {
        console.log("Row Click Callback", location, value, e.target.childNodes[0]);
        // Additional logic for handling row click
    };

    return (
        <div className='mt-2 p-md-4'>
            <div className='d-flex justify-content-end'>
                {/* You can add buttons or other controls here */}
            </div>
            <div className='d-flex'>
                <h1>Users Management</h1>
                <ArrowClockwise className='icon-as-button no-background ms-2 pointer'
                    onClick={() => {
                        return getUsers(true);
                    }}
                    size={30} />
            </div>

            {isLoading ? <Spinner animation="border" /> : (
                <>
                    <Row className='mt-2'>
                        <Col>
                            <h2>Customers</h2>
                            <ReactiveTable data={customers} title={"Customers"} clickHandler={handleRowClick} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <h2>Administrators</h2>
                            <ReactiveTable data={administrators} title={"Administrators"} clickHandler={handleRowClick} />
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
}

export default Users;
