import React, { useState, useEffect } from 'react'
import { MyDate, intval, objectCopy } from '../../../../library/library';
import { Button, Col, Form, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ReactiveTable from './ReactiveTable';
import { ArrowCounterclockwise } from 'react-bootstrap-icons';
import Quote from '../../../../classes/Quote';

function JobNotesInternalSection({ jobNotesIn, setQuote, currentUser, quote }) {
    const [jobNotesIntRow, setJobNotesIntRow] = useState("");
    const [jobNotesIntEditData, setJobNotesIntEditData] = useState({ date: "", userId: "", name: "", note: "" });
    const [jobNotesIntHistory, setJobNotesIntHistory] = useState([]);

    useEffect(() => {
        let mounted = true;
        if (mounted && intval(jobNotesIntRow) < intval(Object.values(jobNotesIn).length) && jobNotesIntRow !== "") {
            setJobNotesIntEditData(jobNotesIn[jobNotesIntRow] || { date: "", userId: "", name: "", note: "" });
        }
        if (mounted && jobNotesIntRow === "") {
            setJobNotesIntEditData({ date: "", userId: "", name: "", note: "" });
        }

        if (checkJobNotesIDs(jobNotesIn)) {
            reKeyJobNotes(jobNotesIn);
        }

        return () => mounted = false;
        // eslint-disable-next-line
    }, [jobNotesIntRow, jobNotesIn]);


    const checkJobNotesIDs = (jobNotes) => {
        let test = false;
        let lastKey = 0;
        Object.keys(jobNotes).forEach((key) => {
            const intKey = intval(key);
            if (intKey > 0 && intKey !== lastKey + 1) {
                test = true;
            }
            lastKey = intval(key);
        });
        return test;
    }

    const reKeyJobNotes = (jobNotesIn) => {
        const newJobNotes = {};
        Object.values(jobNotesIn).forEach((value, index) => {
            newJobNotes[index] = value;
        });
        const newPreQuote = new Quote(objectCopy(quote));
        newPreQuote.jobNotesInternal = newJobNotes;
        setQuote(newPreQuote);
    }


    // Update functions for Job Notes
    const updateJobNotesHistory = () => {
        setJobNotesIntHistory(prevHistory => [...prevHistory, { ...jobNotesIn }]);
    }

    // Add/Update, Remove, Clear, and Undo Functions for Job Notes
    const addUpdateJobNote = () => {
        updateJobNotesHistory();
        objectCopy(jobNotesIntEditData)
        const newJobNote = objectCopy(jobNotesIntEditData);
        newJobNote.name = currentUser.name;
        newJobNote.userId = currentUser.id;
        const date = new MyDate();
        newJobNote.date = date.timeStamp;
        const newPreQuote = new Quote(objectCopy(quote));
        newPreQuote.jobNotesInternal = objectCopy(jobNotesIn);
        newPreQuote.jobNotesInternal[jobNotesIntRow] = newJobNote;
        setQuote(newPreQuote);
    };


    const removeJobNote = () => {
        updateJobNotesHistory();
        setQuote(prev => {
            //console.log(prev, { ...prev });
            const modifiedPreQuote = new Quote(objectCopy(prev));
            //  console.log({ ...modifiedPreQuote });
            delete modifiedPreQuote.jobNotesInternal[jobNotesIntRow];
            // console.log(modifiedPreQuote);
            return modifiedPreQuote;
        });
        setJobNotesIntRow("");
    };

    const handleJobNoteTableClick = (table, rowNum, rowData, cellData, event) => {
        setJobNotesIntRow(rowNum);
        setJobNotesIntEditData(jobNotesIn[rowNum] || { date: "", userId: "", name: "", note: "" });
    };



    const handleJobNoteUndo = () => {
        if (jobNotesIntHistory.length === 0) return;

        const newHistory = jobNotesIntHistory.slice();
        const previousState = newHistory.pop();

        setJobNotesIntHistory(newHistory);
        setQuote((prev) => {
            const newPreQuote = new Quote({ ...prev });
            newPreQuote.jobNotesInternal = { ...previousState };
            return newPreQuote;
        });
        // setQuote(prev => (new Quote({ ...prev, jobNotes: previousState })));

    };

    const renderJobsInternalNameTooltip = (props) => {
        return (
            <Tooltip id="jobs-public-name-tooltip" {...props}>
                The name and ID of the posting are automatically updated based on your login information when you save or add a note.
            </Tooltip>
        )
    }

    const newItemPrep = () => {
        setJobNotesIntRow("");
        setTimeout(() => {
            setJobNotesIntRow(intval(Object.values(jobNotesIn).length));
        }, 10);
    }

    return (
        <>
            <ReactiveTable
                data={Object.values(jobNotesIn)}
                headerOverride={["Date", "User ID", "Name", "Note"]}
                clickHandler={handleJobNoteTableClick}
                useRowNumbers={true}
                rowNumberHeader="ID"
            />
            <Row>
                <Col xs={4} md={4} lg={2}>
                    <Form.Group>
                        <Form.Label className='text-black'>ID {(intval(jobNotesIntRow) === intval(Object.values(jobNotesIn).length) && jobNotesIntRow !== "")
                            || jobNotesIntRow === "" ?
                            <i className='text-success smaller shimmer text-button' onClick={newItemPrep}>New Note</i> : <></>
                        }</Form.Label>
                        <Form.Control
                            type="number"
                            name="jobId"
                            value={jobNotesIntRow}
                            onChange={(e) => setJobNotesIntRow(() => {
                                if (e.target.value >= 1 && e.target.value < intval(Object.values(jobNotesIn).length)) {
                                    return e.target.value;
                                }
                                if (e.target.value >= intval(Object.values(jobNotesIn).length)) {
                                    setJobNotesIntEditData((prev) => {
                                        return { ...prev, name: currentUser.name, note: "" };
                                    });
                                    return intval(Object.values(jobNotesIn).length);
                                }
                                if (e.target.value <= 0) {
                                    return 0;
                                }
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col xs={8} md={6} lg={3}>
                    <Form.Group>
                        <Form.Label className='text-black'>Name</Form.Label>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 300 }} overlay={renderJobsInternalNameTooltip}>
                            <Form.Control
                                readOnly
                                type="text"
                                name="name"
                                value={jobNotesIntEditData.name || ""}
                                onChange={(e) => setJobNotesIntEditData((prev) => {
                                    return { ...prev, [e.target.name]: currentUser.name };
                                })}
                            />
                        </OverlayTrigger>
                    </Form.Group>
                </Col>

                <Col md={12} lg={8}>
                    <Form.Group>
                        <Form.Label className='text-black'>Notes</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            name="note"
                            value={jobNotesIntEditData.note || ""}
                            onChange={(e) => setJobNotesIntEditData((prev) => {
                                return { ...prev, [e.target.name]: e.target.value };
                            })}
                        />
                    </Form.Group>
                </Col>

            </Row>
            <Row className='mt-3'>
                <Col>
                    {intval(jobNotesIntRow) === intval(Object.values(jobNotesIn).length) ?
                        <Button disabled={jobNotesIntEditData.note === ""} className='m-2' variant='success' onClick={addUpdateJobNote}>Add Note</Button> : <Button disabled={jobNotesIntRow === "" || jobNotesIntEditData.note === "" ? true : false} variant='success' onClick={addUpdateJobNote}>Update Note</Button>}

                    <Button className='m-2' variant="danger" onClick={removeJobNote}
                        disabled={intval(jobNotesIntRow) >= intval(Object.values(jobNotesIn).length) || jobNotesIntRow === "" ? true : false}>Delete Note</Button>
                    <Button className='m-2' variant="secondary" onClick={() => setJobNotesIntRow("")}>Clear</Button>
                    <Button disabled={jobNotesIntHistory.length === 0} className={`m-2 ${jobNotesIntHistory.length === 0 ? "border-0" : ""}`} variant="secondary-outline" onClick={handleJobNoteUndo}> <ArrowCounterclockwise size={25} /> Undo</Button>
                </Col>
            </Row>
        </>
    )
}

export default JobNotesInternalSection