import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import UserContext, { useUser } from "../../context/UserContext";
import AccountPageSideBar from './AccountPageSideBar';
import AccountPageContent from './AccountPageContent';

import './AccountPage.css';
import useCustomAlert from '../../library/useCustomAlert';
import User from '../../classes/User';

function AccountPage() {
    const { currentUser, setCurrentUser, siteInfo, loading, setLoading } = useUser(UserContext);
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const [activeItem, setActiveItem] = useState('home');
    const [showVerifyAlert, setShowVerifyAlert] = useState(false);

    //console.log("ACCOUNTPAGE-currentUser", currentUser, "ACCOUNTPAGE-siteInfo", siteInfo, "ACCOUNTPAGE-loading", loading);
    console.log("ACCOUNTPAGE-currentUser", currentUser);


    const logout = async () => {
        try {
            const parseLogout = await Parse.User.logOut();
            console.log(parseLogout);
            const user = new User(null);
            setCurrentUser(() => user);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);

    };

    useEffect(() => {
        let mounted = true;
        if (mounted && showVerifyAlert) {
            showAlert(`Hi ${currentUser.username}!  Welcome to your ${currentUser.role} portal.  Before proceeding, please valididate your email.  You should have recieved a link shortly after registering.  Please click on the link in the email before proceeding.  If you haven't received it, you may resend the link.`, "Welcome to X4!", () => {
                logout()
            }, true, "Resend Email", false, () => {
                (
                    async () => {
                        try {
                            await Parse.Cloud.run('sendEmailVerification', { email: currentUser.email });
                            showAlert(`Please check your email at ${currentUser.email} and click the verification link.  You will now be logged out.`, "Verification Email Sent!", () => {
                                logout();
                            });

                        } catch (error) {
                            if (error.code === 137) {
                                showAlert("You have already verified your email - please log back into the site.  If the you continue to experience issues accessing your portal, please contact customer support.", "Email Verified!");
                                return;
                            }
                            console.error(error.message);
                            showAlert("Oops - something went wrong.  Please contact customer service.", "Server Error", () => {
                                logout();
                            }, false, "", false);
                        }
                    }
                )();
            }, "Ok, I'll check");
        }

        return () => mounted = false;
        // eslint-disable-next-line
    }, [showVerifyAlert]);

    useEffect(() => {
        let mounted = true;
        if (mounted && currentUser && !currentUser.emailVerified) {
            setShowVerifyAlert(true);
        }

        if (mounted && currentUser && currentUser.emailVerified && showVerifyAlert === true) {
            setShowVerifyAlert(false);
        }

        return () => mounted = false;
        // eslint-disable-next-line
    }, [currentUser]);


    const handleActiveItemChange = (newActiveItem) => {
        setActiveItem(newActiveItem);
    };



    return (
        <div className="account-page">
            {
                currentUser.emailVerified ?
                    loading ? <></> :
                        <>
                            <AccountPageSideBar activeItem={activeItem} onActiveItemChange={handleActiveItemChange} currentUser={currentUser} siteInfo={siteInfo} />
                            <AccountPageContent activeItem={activeItem} currentUser={currentUser} siteInfo={siteInfo} />
                        </> :
                    <>
                        <RenderAlertModal />
                    </>
            }
        </div>
    );
}

export default AccountPage;