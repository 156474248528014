import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Dropdown } from 'react-bootstrap';
import { ArrowClockwise, XSquare } from 'react-bootstrap-icons';

import './AdminDashboard.css';
import useAdmin from '../../../library/useAdmin';
import useCustomAlert from '../../../library/useCustomAlert';
import ChangeUserRoleModal from './ChangeUserRoleModal';
import useEmailTemplate from '../../../library/useEmailTemplate';
import { MyDate } from '../../../library/library';
import { useUser } from '../../../context/UserContext';



function AdminDashboard() {
    const { users, error, roles, counts, getUsers, resetUserPasswordByAdmin, getRoles, changeUserRoleByAdmin, sendEmailAsAdmin, getAdminDashboardCounts } = useAdmin();
    const { clearDirectOpenQO } = useUser();
    const [selectedUser, setSelectedUser] = useState(null);
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);


    const handleSelectUser = (userId) => {
        const user = users.find(u => u.id === userId);
        setSelectedUser(user);

    }

    const handleSaveNewRole = async (newRole) => {
        console.log(`Save new role ${newRole} for user ${selectedUser.id}`);
        const success = await changeUserRoleByAdmin(selectedUser.id, newRole);
        if (success) showAlert(`New role '${newRole}' for user '${selectedUser.email}' has been successfully assigned.`, "New Role Assigned");
        if (!success) showAlert(`New role '${newRole}' for user '${selectedUser.email}' has was not able to be assigned.`, "Error: Assignment Failed");
    };



    useEffect(() => {
        let mounted = true;
        (
            async () => {
                if (mounted) {
                    getUsers();
                    getRoles();
                    getAdminDashboardCounts();
                }
            }
        )();
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);

    console.log(users);


    const handleResetUserPasswordByAdmin = () => {
        showAlert(`Are you sure you want to reset the password for the user with email: ${selectedUser.email}. This CAN NOT be undone!`, "Manual Password Reset", true, "Yes, Proceed", resetUserPassword);
    }

    const resetUserPassword = async () => {
        const newUserPassword = await resetUserPasswordByAdmin(selectedUser.id);
        showAlert(`User password reset to: ${newUserPassword}. Please give this directly to the user AND instruct them to change their password after login.`, "Manual Password Reset | SUCCESS", false);
    }

    const emailHtml = useEmailTemplate({
        title: "Welcome to X4 International",
        header: "Welcome Aboard!",
        body: "Thank you for choosing our services. We are excited to work with you.",
        footer: "Best Regards, X4 International Team"
    });



    return (
        <Container className='dashboard-container' fluid>

            <div className='d-flex'>
                <h1>Admin Dashboard</h1>
                <ArrowClockwise className='icon-as-button no-background ms-2'
                    onClick={() => {
                        getUsers(true);
                        getAdminDashboardCounts(true);
                        clearDirectOpenQO();
                    }}
                    size={30} />
                {/* 
                <Button onClick={() => sendEmailAsAdmin("mattjperrine@gmail.com", "Test Email from app", "This is a test.")}>Send Test Email</Button>
                <Button className="ms-2" onClick={() => sendEmailAsAdmin("mattjperrine@gmail.com", "This is a test HTML email", emailHtml, true)}>Send Test HTML Email</Button>
                    */}
            </div>

            {/* Widgets Section */}
            <Row className="widgets my-3">
                {/* Widget for Number of Users */}
                <Col xl={2} lg={3} md={4} sm={5} xs={6}>
                    <Card className='widget'>
                        <Card.Body>
                            <Card.Title className='px-2 py-1'>Users</Card.Title>
                            <Card.Text className='p-2'>

                                Admins: {counts.users.admins} <br />
                                Customers: {counts.users.customers}  <br />
                                Total: {counts.users.total}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Widget for Number of Quotes */}
                <Col xl={2} lg={3} md={4} sm={5} xs={6}>
                    <Card className='widget'>
                        <Card.Body>
                            <Card.Title className='px-2 py-1'>Quotes</Card.Title>
                            <Card.Text className='p-2'>
                                Requests: {counts.quotes.requests} <br />
                                Open: {counts.quotes.open} <br />
                                Closed: {counts.quotes.closed}<br />
                                Total: {counts.quotes.total}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Widget for Number of Orders */}
                <Col xl={2} lg={3} md={4} sm={5} xs={6}>
                    <Card className='widget'>
                        <Card.Body>
                            <Card.Title className='px-2 py-1'>Orders</Card.Title>
                            <Card.Text className='p-2'>

                                Open: {counts.orders.open} <br />
                                Closed: {counts.orders.closed}<br />
                                Total: {counts.orders.total}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Widget for Number of Invoices */}
                <Col xl={2} lg={3} md={4} sm={5} xs={6}>
                    <Card className='widget'>
                        <Card.Body>
                            <Card.Title className='px-2 py-1'>Invoices</Card.Title>
                            <Card.Text className='p-2'>
                                Paid: {counts.invoices.paid} <br />
                                Pending: {counts.invoices.pending}<br />
                                Total: {counts.invoices.total}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Administrative Features Section */}
            <RenderAlertModal />
            <Row className='admin-features'>
                <Col>
                    <div className='admin-feature'>
                        <h2>Administrative Features</h2>

                        <div className='d-flex mb-2'>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Select User
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {users.map(user => (
                                        <Dropdown.Item key={user.id} onClick={() => handleSelectUser(user.id)}>
                                            {`${user.email} - (${user.username})`}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            {selectedUser ? <XSquare className="icon-as-button ms-2" type="button" fill='dark' size={30} onClick={() => setSelectedUser(null)} /> : <></>}

                        </div>


                        {selectedUser && (
                            <Card>
                                <Card.Body>
                                    <Card.Title>User Details</Card.Title>
                                    <Card.Text as="div">
                                        <Row>
                                            <Col>
                                                <strong>ID:</strong> {selectedUser.id}<br />
                                                <strong>Email:</strong> {selectedUser.email}<br />
                                                <strong>Username:</strong> {selectedUser.username}
                                            </Col>
                                            <Col>
                                                <strong>Name:</strong> {selectedUser.name}<br />
                                                <strong>Phone:</strong> {selectedUser.mobileNumber}<br />
                                                <strong>Joined:</strong> {new MyDate(selectedUser.createdAt).readable}
                                            </Col>
                                        </Row>


                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )}

                        {/* Example buttons for administrative tasks */}
                        <Button variant="primary" className="m-2" onClick={() => setShowChangeRoleModal(true)} disabled={!selectedUser}>Change User's Role</Button>
                        <ChangeUserRoleModal
                            show={showChangeRoleModal}
                            onHide={() => setShowChangeRoleModal(false)}
                            selectedUser={selectedUser || { role: "" }}
                            onSave={handleSaveNewRole}
                            roles={roles} // Replace with actual roles
                        />
                        <Button variant="primary" className="m-2" disabled={!selectedUser} onClick={handleResetUserPasswordByAdmin}>Reset User's Password</Button>
                        <Button variant="primary" className="m-2" disabled={!selectedUser}>Change User's Email</Button>
                        <Button onClick={() => alert("This feature is not yet available.")} variant="warning" className="m-2" disabled={!selectedUser}>Lock User</Button>
                        <Button onClick={() => alert("This feature is not yet available.")} variant="warning" className="m-2" disabled={!selectedUser}>Unlock User</Button>
                        <Button onClick={() => alert("This feature is not yet available.")} variant="danger" className="m-2" disabled={!selectedUser}>Delete User</Button>
                        {/* Add more buttons or forms for other tasks //TODO: add the above features */}
                    </div>
                </Col>
            </Row>

            {/* Example Modal for Changing User Role */}
            {/* Repeat similar structure for other administrative tasks */}
            {/* <Modal>...</Modal> */}
        </Container>
    );
}

export default AdminDashboard;
