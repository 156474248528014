import { MyDate, formatNumberToCurrency, isset } from "../library/library";
import Quote from "./Quote";
import Parse from 'parse';

class Order extends Quote {
    is = "ORDER";
    orderId = null;
    invoiceId = "";


    constructor(orderData, ready = true) {
        super(orderData);
        this.ready = ready;
        if (!isset(orderData)) {
            this.isset = false;
            return this;
        };
        this.isset = true;
        this.orderId = this.objectId;
        this.serviceLocation = `${this.toAddress1} ${this.toAddress2}, ${this.toCity}, ${this.toState} ${this.toZip}`;
    }

    getTabulation() {
        return {
            headers: ["Service", "Start Date", "End Date", "To", "Company", "Email", "Mobile Number", "Amount", "Tax", "Total"],
            keys: ["selectedService", "serviceStartDate", "serviceEndDate", "toName", "toCompany", "toEmail", "toMobileNumber", "amount", "tax", "total"],
            values: [this.services[this.selectedService],
            this.serviceStartDate ? new MyDate(this.serviceStartDate).toString(false) : "",
            this.serviceEndDate ? new MyDate(this.serviceEndDate).toString(false) : "",
            this.toName,
            this.toCompany,
            this.toEmail,
            this.toMobileNumber,
            formatNumberToCurrency(this.amount),
            formatNumberToCurrency(this.tax),
            formatNumberToCurrency(this.total),
            ]
        }
    }

    exportForSave() {
        return {
            orderId: this.objectId,
            jobNotes: this.getJobNotes(),
            jobNotesInternal: this.getInternalJobNotes()
        }
    }

    getJobNotes() {
        return this.jobNotes;
    }

    getInternalJobNotes() {
        return this.jobNotesInternal;
    }

    async destroy() {
        try {
            await Parse.Cloud.run('deleteOrder', { objectId: this.objectId });
            this.isset = false;
            return true;
        } catch (error) {
            console.error('Failed to delete order:', error);
            throw new Error(error);
        }
    }

}
export default Order;