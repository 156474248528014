import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Parse from 'parse';

import ManagementImage from "../../images/management.png";
import AVProductionImage from "../../images/avproduction.png";
import ConsultingImage from "../../images/consulting.png";
import CaseStudyImage from "../../images/casestudys.png";

import "./ServicesPage.css";
import ServicesModal from './ServicesModal';
import { trimChar } from '../../library/library';
import UserContext, { useUser } from '../../context/UserContext';
import QuoteRequest from '../../classes/QuoteRequest';
import Messages from '../../classes/Messages';


function ServicesPage() {
    const [showModal, setShowModal] = useState(false);
    const [selectedService, setSelectedService] = useState("");
    const [modalData, setModalData] = useState({ acceptTexts: true });
    const { currentUser, fetchUserQuotes } = useUser(UserContext);
    const [messages, setMessages] = useState(new Messages());
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        let mounted = true;

        if (mounted && location.state?.from === "/newquoterequest" && location.state?.selectedService) {
            console.log(location);
            setSelectedService(location.state.selectedService);
            setShowModal(true);
        }

        return () => mounted = false;
    }, [location]);


    const handleOpenModal = (service) => {
        if (false) {
            alert("You must be logged in to submit a quote request.")
            navigate("/login", { state: { from: location } });
            return;
        }
        setSelectedService(service);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedService("");
        setModalData({ acceptTexts: true });
    };

    const processFormData = (modalData) => {
        const formData = new QuoteRequest(currentUser, selectedService);
        try {
            formData.saveFormData(modalData);
        } catch (error) {
            console.error(error);
        }
        return formData;
    }

    const postForm = async (quoteRequest = new QuoteRequest()) => {

        const formData = {
            selectedService: quoteRequest.selectedService,
            name: quoteRequest.name,
            email: quoteRequest.email,
            company: quoteRequest.company,
            serviceMonth: quoteRequest.serviceMonth,
            notes: quoteRequest.notes,
            mobileNumber: quoteRequest.mobileNumber,
            acceptTexts: quoteRequest.acceptTexts,
            questionOne: quoteRequest.questions[selectedService][1].question,
            questionTwo: quoteRequest.questions[selectedService][2].question,
            questionThree: quoteRequest.questions[selectedService][3].question,
            answerOne: quoteRequest.answers[1],
            answerTwo: quoteRequest.answers[2],
            answerThree: quoteRequest.answers[3],
        };

        // Create a new instance of the QuoteRequest class
        const QuoteRequestDatabase = Parse.Object.extend('QuoteRequest');
        const quoteRequestDatabase = new QuoteRequestDatabase();

        // Set the form data
        for (let field in formData) {
            quoteRequestDatabase.set(field, formData[field]);
        }
        // If the user is logged in, link the quote request to the user
        if (currentUser.id) {
            quoteRequestDatabase.set("userId", Parse.User.current()); // Link to the user
        }
        // Save the quoteRequestDatabase object to Back4App
        try {
            console.log(quoteRequestDatabase);
            await quoteRequestDatabase.save();
            console.log('New object created with objectId: ' + quoteRequestDatabase.id);
            const message = new Messages("form", "Quote request sent successfully!")
            message.success = true;
            setMessages(message);
            return quoteRequestDatabase.id;
        } catch (error) {
            console.error('Failed to create new object, with error code: ' + error.message);
            const message = new Messages("form", "Quote Request failed. Please contact customer support.")
            message.success = false;
            setMessages(message);
            return false;
        }

    }

    const handleModalSubmit = async (e) => {
        e.preventDefault();
        setMessages(new Messages("form", "Submitted, Please wait..."));
        const formData = processFormData(modalData);
        if (await postForm(formData)) {
            setSelectedService("");
            setShowModal(false);
            setMessages(new Messages());
            if (currentUser && currentUser.id) fetchUserQuotes("request", true);
            //Handle navigation
            if (!currentUser.id) {
                navigate('/register', { state: { from: '/services', email: formData.email } });
            }
            if (location.state.hasOwnProperty("from") && location.state.from === "/newquoterequest") {
                navigate('/quotes', { state: { from: '/servicesQuoteRequest', email: formData.email, selectedService: formData.selectedService } });
            }
        }
    };



    const handleModalOnChange = (e) => {
        let value = ""
        switch (e.target.type) {
            case "checkbox":
                value = e.target.checked;
                break;

            default:
                value = trimChar(e.target.value, " .%");
                break;
        }
        //console.log(`${e.target.name} = |${value}|`, `| Type is ${e.target.type}`);
        setModalData((prev) => {
            return { ...prev, [e.target.name]: value }
        }
        );
    }

    return (
        <>
            <Container className=" services-container my-5 px-5"  >
                {/* Service Categories */}
                <Row className="service-category mx-auto">
                    <Col md={6} className=''>
                        <Image src={AVProductionImage} fluid />
                    </Col>
                    <Col md={6}>
                        <h3>AV Production</h3>
                        <p>Elevate your events with our state-of-the-art AV Production services. At X4 International, we blend creativity with cutting-edge technology to bring your vision to life. From immersive soundscapes to stunning visual displays, our team ensures every aspect of AV production is seamless and impactful. Whether it's a corporate conference, a live concert, or an intimate gathering, we deliver tailor-made solutions that resonate with your audience. Experience the power of advanced audiovisuals with X4 International – where your event's success is our masterpiece.</p>
                        <Button onClick={() => handleOpenModal('AVProduction')}>Request a quote</Button>
                    </Col>
                </Row>

                <Row className="service-category flex-sm-row-reverse mx-auto">
                    <Col md={6}>
                        <Image src={ConsultingImage} fluid />
                    </Col>
                    <Col md={6} >
                        <h3>Consulting</h3>
                        <p>Navigate the complex world of event technology with X4 International's expert consulting services. Our seasoned professionals offer invaluable insights and tailored strategies to optimize your event's audiovisual impact. We stay ahead of the curve, advising on the latest AV trends and innovations best suited for your needs. From initial concept to final execution, our consulting services are designed to streamline your event planning process, ensuring a flawless and memorable experience every time.</p>
                        <Button onClick={() => handleOpenModal('Consulting')}>Request a quote</Button>
                    </Col>

                </Row>

                <Row className="service-category mx-auto">
                    <Col md={6}>
                        <Image src={ManagementImage} fluid />
                    </Col>
                    <Col md={6}>
                        <h3>On-Site Management</h3>
                        <p>At X4 International, we understand that successful events hinge on impeccable management. Our AV management services are the backbone of your event's success, overseeing every detail with precision and expertise. From coordinating technical requirements to ensuring smooth operation on the day, our dedicated team is there every step of the way. We handle the complexities, so you can focus on what truly matters – connecting with your audience and delivering an extraordinary event experience.</p>
                        <Button onClick={() => handleOpenModal('OnSiteManagement')}>Request a quote</Button>
                    </Col>
                </Row>

                {/* Case Studies/Testimonials */}
                <Row className="service-category my-5 flex-sm-row-reverse mx-auto">
                    <Col md={6}>
                        <Image src={CaseStudyImage} fluid />
                    </Col>
                    <Col>
                        <h3>Case Studies, White Papers, and Testimonials</h3>
                        <p>Client feedback and success stories...</p>
                        {/* Include more detailed case studies or testimonials as needed */}
                    </Col>
                </Row>

                {/* Call-to-Action */}
                <Row className="service-category my-5 mx-auto">
                    <Col className='text-center'>
                        <h2>Contact Us for Your AV Needs</h2>
                        <Button variant="primary" href="/contact">Get In Touch</Button>
                        {/* Alternatively, include a contact form here */}
                    </Col>
                </Row>
            </Container>
            <ServicesModal selectedService={selectedService} showModal={showModal} modalData={modalData} setModalData={setModalData} handleModalOnChange={handleModalOnChange} handleCloseModal={handleCloseModal} handleModalSubmit={handleModalSubmit} messages={messages} currentUser={currentUser} />
        </>
    );
}

export default ServicesPage;
