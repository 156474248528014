import React, { useState } from 'react';
import Parse from 'parse';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { ExclamationOctagonFill } from 'react-bootstrap-icons';
import useCustomAlert from '../../../library/useCustomAlert';

/**
 * A custom modal for user password reset
 * @param {{currentUser:User,logout:Function}} props Requires both the current user and a logout callback function
 * @returns {Button} A button component that calls a modal for password reset.
 */
const PasswordResetModal = ({ currentUser, logout }) => {
    const [showModal, setShowModal] = useState(false);
    const { RenderAlertModal, showAlert } = useCustomAlert();

    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const [error, setError] = useState('');


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPasswords((prevPasswords) => ({
            ...prevPasswords,
            [name]: value,
        }));

    };

    const resetPassword = async (currentPassword, newPassword) => {
        try {
            // Call the cloud function 'changeUserPassword' with the current and new passwords
            if (await Parse.Cloud.run('changeUserPassword', { currentPassword, newPassword })) {
                showAlert(`You will now be logged out and must log back in for security reasons.`, "Password changed successfully");
                return true;
            }

        } catch (error) {
            // If the cloud function throws an error, log or alert the error message
            setError(`Error while changing password: ${error}`);
            console.error('Error while changing password:', error);
            return false;
        }
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*\d)(?=.*[!@#*-_%])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
        return regex.test(password);
    };

    const handleClose = () => {
        setPasswords({
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        });
        setError('');
        setShowModal(false);
    }

    const handleSave = async () => {
        if (!passwords.currentPassword) {
            setError('Current password is required.');
            return;
        }

        if (!passwords.newPassword) {
            setError('You must enter a new password.');
            return;
        }

        if (!validatePassword(passwords.newPassword)) {
            setError('Password must be at least 10 characters long, include one uppercase letter, one lowercase letter, one number, and one special character (!@#*-_%).');
            return;
        }

        if (passwords.newPassword !== passwords.confirmNewPassword) {
            setError('New passwords do not match.');
            return;
        }
        if (await resetPassword(passwords.currentPassword, passwords.newPassword)) {
            setTimeout(() => {
                logout();
            }, 5000);
            handleClose();

        }
    };

    return (
        <>
            <Button className='m-0' variant="primary" onClick={() => setShowModal(true)}>
                Reset Password
            </Button>
            <RenderAlertModal />
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title >Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {error && <p className="text-danger bold font-larger bg-black rounded rounded-2 p-2"> <ExclamationOctagonFill size={40} className='me-2' /> {error}</p>}
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group controlId="formCurrentUsername">
                                    <Form.Label>User Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        placeholder='Enter current password'
                                        value={currentUser.username}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="formCurrentPassword">
                                    <Form.Label>Current Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="currentPassword"
                                        placeholder='Enter current password'
                                        value={passwords.currentPassword}
                                        onChange={handleInputChange}

                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                        <Form.Group controlId="formNewPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="newPassword"
                                value={passwords.newPassword}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formConfirmNewPassword">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="confirmNewPassword"
                                value={passwords.confirmNewPassword}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PasswordResetModal;
