import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ChangeUserRoleModal = ({ show, onHide, selectedUser, onSave, roles }) => {
    const [selectedRole, setSelectedRole] = useState(selectedUser.role);

    useEffect(() => {
        setSelectedRole(selectedUser.role);
    }, [selectedUser]);

    const handleSave = () => {
        onSave(selectedRole);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} style={{ zIndex: 1500 }} centered>
            <Modal.Header closeButton>
                <Modal.Title>Change User Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group style={{ zIndex: 1600 }}>
                        <div className='d-flex flex-column'>
                            <Form.Label className='m-0'>Available Roles</Form.Label>
                            <small className='m-0 mb-3'><i className='text-light'>The user's current role has been preselected.</i></small>
                        </div>

                        <Form.Control
                            as="select"
                            value={selectedRole}
                            onChange={(e) => setSelectedRole(e.target.value)}
                            style={{ zIndex: 1600 }}
                        >
                            {roles.map((role, index) => (
                                <option key={index} value={role}>
                                    {role}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangeUserRoleModal;
