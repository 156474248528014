import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from "../../pages/auth/useAuth";
import { Spinner } from 'react-bootstrap';


const ProtectedRoute = ({ element }) => {
    const { currentUser, loading } = useAuth();

    //console.log("currentUser-protectedroute", currentUser, "loading", loading);
    //console.log("location in protected route", location);

    if (loading) {
        return <div className='p-5 m-5'><div> <Spinner className='me-3' /> Loading, Please wait... </div></div>;
    }

    if (!currentUser?.id) {
        return <Navigate replace to="/login" state={{ from: window.location.pathname }} />;
        //  navigate('/login', { state: { from: window.location.pathname } })
    }

    return element;
};

export default ProtectedRoute;
