import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'

import Order from '../../../classes/Order';
import ReactiveTable from '../adminData/components/ReactiveTable';
import { useNavigate } from 'react-router-dom';
import useCustomAlert from '../../../library/useCustomAlert';
import useMyLocation from '../../../library/useMyLocation';
import OrderDisplay from '../OrderDisplay';
import { ArrowClockwise } from 'react-bootstrap-icons';
import useAdmin from '../../../library/useAdmin';
import { useUser } from '../../../context/UserContext';
import { MyDate, objectCopy } from '../../../library/library';


function Orders() {
    const { orders, saveOrder, getOpenOrdersByAdmin, ordersClosed, getClosedOrdersByAdmin, sendEmailAsAdmin, convertOrderToQuote, getAdminDashboardCounts, getAllOpenQuotesAsAdmin } = useAdmin();
    const { currentUser } = useUser();
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const navigate = useNavigate();
    const [location, setLocation] = useMyLocation({ from: "" });
    const [fromLocation, setFromLocation] = useState(location.state?.from || "/account");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showOrder, setShowOrder] = useState(false);




    useEffect(() => {
        let mounted = true;
        if (mounted) {
            (
                async () => {
                    try {
                        await handleGetOpenOrders();
                        await handleGetClosedOrders();
                    } catch (error) {
                        console.error(error);
                    }
                }
            )();

        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);



    useEffect(() => {
        let mounted = true;
        if (mounted && location.state?.from) {
            setFromLocation(location.state.from)
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);


    const refreshOrders = async () => {
        await handleGetOpenOrders(true);
        await handleGetClosedOrders(true);
        //  await handleGetAllOrders(true);
    }



    const handleGetOpenOrders = async (forceRefresh = false) => {
        await getOpenOrdersByAdmin(forceRefresh);
    }

    const handleGetClosedOrders = async (forceRefresh = false) => {
        await getClosedOrdersByAdmin(forceRefresh);
    }


    const orderRowClick = (location, value, e) => {
        console.log("orderRowClick Callback", location, value, e.target.childNodes[0]);
        // Find the order in the orders array using the order ID
        const selectedOrder = orders.find(order => order.objectId === value);
        if (selectedOrder) {
            // Logic to display the selected order
            // You can set the selected order to a state and then render the orderDisplay component with this state
            setSelectedOrder(selectedOrder); // Assuming you have a state called `setSelectedorder`
            setShowOrder(true);
        } else {
            console.error("Order not found");
            showAlert(`That order has been closed`, "Closed Order");
            // Handle the scenario where the order is not found
        }
    }

    const orderClosedRowClick = (location, value, e) => {
        console.log("orderRowClick Callback", location, value, e.target.childNodes[0]);
        // Find the order in the ordersClosed array using the order ID
        const selectedOrder = ordersClosed.find(order => order.objectId === value);
        if (selectedOrder) {
            // Logic to display the selected order
            // You can set the selected order to a state and then render the orderDisplay component with this state
            setSelectedOrder(selectedOrder); // Assuming you have a state called `setSelectedorder`
            setShowOrder(true);
        } else {
            console.error("Order not found");
            showAlert(`That order has been deleted or otherwise does not exist.`, "Deleted Order");
            // Handle the scenario where the order is not found
        }
    }


    const handleAccept = (orderIn, action) => {
        switch (action) {
            case "DELETE":
                handleDeleteOrder(orderIn);
                break;
            case "CANCEL":
                handleCancelOrder(orderIn);
                break;
            case "PAY":
                handleCloseOrder();
                break;
            case "NOTIFY":
                localHandleNotifyCustomer(orderIn);
                break;
            case "TOQUOTE":
                handleConvertOrderToQuote(orderIn); //TODO:  Re-test entire workflow, then time for Payments!
                break;

            default:
                handleCloseOrder();
                break;
        }
    }


    const handleCloseOrder = () => {
        setShowOrder(false);
    }

    const handleConvertOrderToQuote = (orderIn) => {
        showAlert([`Are you sure you want to convert Order with ID: ${orderIn.orderId} back to a quote?`, ` The process is irrversible and the customer must accept the Quote again to return it to Order status.`], "Order Reversion Confirmation", () => {
            return;
        }, true, "Convert To Quote", false, async () => {
            try {
                const now = new MyDate();
                const conversionOrder = new Order(orderIn);
                conversionOrder.jobNotes[Object.values(orderIn.jobNotes).length] = { date: now.timeStamp, userId: currentUser.id, name: "System Admin", note: "Order converted back to quote for editing and reacceptance." };
                conversionOrder.jobNotesInternal[Object.values(orderIn.jobNotesInternal).length] = { date: now.timeStamp, userId: currentUser.id, name: currentUser.name, note: "Order conversion back to quote." };
                conversionOrder.activity.updateHistory.push(new Date().toISOString());
                conversionOrder.activity.isUpdated = false;
                await convertOrderToQuote(conversionOrder);
                refreshOrders();
                showAlert(`Order ID ${orderIn.id} has been converted back to a quote.`, "Conversion Successful!", () => {
                    setShowOrder(false);
                    getAdminDashboardCounts(true);
                    getAllOpenQuotesAsAdmin(true);
                });
            } catch (error) {
                showAlert(`Order conversion failed - please contact your system admin.`, "Server Error!");
            }
        }, "Cancel");
    }

    const localHandleNotifyCustomer = async (thisOrderData) => {
        if (await sendEmailAsAdmin(thisOrderData.toEmail, `Updated ${thisOrderData.services[thisOrderData.selectedService]} Order Notes`, `Hi ${thisOrderData.toName}, We've updated your order notes! You can view the order here: http://127.0.0.1:3000/account/order/${thisOrderData.id}.`, true)) {
            showAlert(`Customer notification successful. ${thisOrderData.toName} has recieved an email alert that their order notes have been updated.`, "Notification successful.");
            return;
        }
        showAlert(`An error occured while attempting to send the customer notification for Order ID: ${thisOrderData.id}. Please contact your site admin.`, "Notification Error!", () => { }, false, "", true);
    }

    const handleDeleteOrder = async (orderIn) => {
        showAlert([`Are you sure you want to delete Order with ID: ${orderIn.orderId}?`, ` The is permanent and the order cannot be recovered.`], "Order Deletion Confirmation", () => {
            return;
        }, true, "Yes, Unleash Destruction!", false, async () => {
            try {
                await orderIn.destroy({ id: currentUser.id, name: currentUser.name });
                refreshOrders();
                showAlert(`Order ID ${orderIn.id} has been deleted.`, "Order Destroyed.", () => {
                    setShowOrder(false);
                });
            } catch (error) {
                showAlert(`Order deletion failed - please contact your system admin.`, "Server Error!");
            }

        }, "Don't dissemble Johnny 5.");

    }

    const handleCancelOrder = async (orderIn) => {
        showAlert([`Are you sure you want to cancel Order with ID: ${orderIn.orderId}?`, ` The order will become inactive and can no longer be changed or utilized.`], "Order Cancel Confirmation", () => {
            return;
        }, true, "Yes, Sayonara!", false, async () => {
            try {
                await orderIn.cancel({ id: currentUser.id, name: currentUser.name });
                refreshOrders();
                showAlert(`Order ID ${orderIn.id} has been canceled.`, "Order Canceled!", () => {
                    setShowOrder(false);
                });
            } catch (error) {
                showAlert(`Order cancellation failed - please contact your system admin.`, "Server Error!");
            }

        }, "Just Kidding, Don't Cancel.");

    }


    const handleSaveOrder = async (request) => {
        try {
            const updatedOrder = await saveOrder(request);
            const newOrder = new Order(updatedOrder.attributes);
            newOrder.ready = true;
            newOrder.objectId = updatedOrder.id;
            newOrder.orderId = updatedOrder.id;
            setSelectedOrder(newOrder);
            getOpenOrdersByAdmin(true);
            return true;
        } catch (error) {
            showAlert(`An error occured while attempting to save Order with ID: ${request.orderId}. The server replied with an error of: ${error.message}`, "Save Error!", () => {
                return false;
            }, false, "", true);
        }
        return false;
    }



    const handleAddJobNotes = async (type = "", updatedOrder = new Order()) => {
        console.log("updatedOrder IN", updatedOrder);
        if (!updatedOrder?.exportForSave) updatedOrder = new Order(updatedOrder);
        showAlert([`Are you sure you want to save Order with ID: ${updatedOrder.orderId}.`, ` The changes will be immediately visible to both you and the customer. They will not be notified of the update unless you use the notification button at the bottom of the order.`], "Order Save Confirmation", () => {
            return;
        }, true, "Save!", false, async () => {
            type = type.toUpperCase();
            switch (type) {
                case "PUBLIC":
                    console.log(updatedOrder, updatedOrder.exportForSave());
                    if (await handleSaveOrder(updatedOrder.exportForSave())) {
                        showAlert(`Public Job Notes for order with ID: ${updatedOrder.orderId} saved successfully!`, "Note Saved");
                    }
                    break;
                case "INTERNAL":
                    if (await handleSaveOrder(updatedOrder.exportForSave())) {
                        showAlert(`Internal Job Notes for order with ID: ${updatedOrder.orderId} saved successfully!`, "Internal Note Saved");
                    }
                    break;

                default:
                    showAlert(`Something went wrong - that was not a valid save option. Please contact your system admin.`, "Save Error!", () => {
                        return;
                    }, false, "", true);
                    break;
            }
        }, "Nevermind.");

    }

    console.log("orders", orders);
    console.log("ordersClosed", ordersClosed);
    //console.log("allOrders", allOrders);


    return (
        <div className='p-md-4 pt-md-0'>
            <RenderAlertModal />
            <div className='d-flex justify-content-end'>

            </div>
            <div className='d-flex mt-2'>
                <h1>Orders Home</h1>
                <ArrowClockwise className='icon-as-button no-background ms-2'
                    onClick={() => {
                        return refreshOrders(true);
                    }}
                    size={30} />
            </div>
            {selectedOrder && <OrderDisplay order={selectedOrder} show={showOrder} onClose={handleCloseOrder} onAccept={handleAccept} addJobNotes={handleAddJobNotes} />}
            <Row className='mt-3'>
                <Col>
                    {orders[0].ready ? (orders[0].objectId ? <ReactiveTable data={orders} title={"Open"} clickHandler={orderRowClick} /> :
                        <div className='data-label-container color-black bold rounded-bottom-3'>No Open Orders</div>) : <div className='d-flex align-items-center'><Spinner className='me-3' /> Loading, please wait...</div>}
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    {ordersClosed[0].ready ? (ordersClosed[0].objectId ? <ReactiveTable data={ordersClosed} title={"Closed"} clickHandler={orderClosedRowClick} /> :
                        <div className='data-label-container color-black bold rounded-bottom-3'>No Closed Orders</div>) : <div className='d-flex align-items-center'><Spinner className='me-3' /> Loading, please wait...</div>}
                </Col>
            </Row>


        </div>

    );
}

export default Orders



