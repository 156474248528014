import { useEffect, useRef } from "react";
import { isset } from "./library";


const useEventListener = (eventType, callback, element = window) => {
    const callbackRef = useRef(callback);

    useEffect(() => {
        if (isset(callbackRef) && callbackRef.hasOwnProperty("current")) {
            callbackRef.current = callback;
        }
    }, [callback]);

    useEffect(() => {
        if (element) {
            const handler = e => callbackRef.current(e);
            element.addEventListener(eventType, handler);

            return () => element.removeEventListener(eventType, handler);
        }

    }, [eventType, element]);
}

export default useEventListener