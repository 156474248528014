import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import useAuth from './useAuth';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, useLocation } from 'react-router-dom';

import './CreateAccountPage.css'; // CSS file for styling
import useCustomAlert from '../../library/useCustomAlert';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

function CreateAccountPage() {
    const { register } = useAuth();
    const recaptchaRef = React.useRef();
    const [userData, setUserData] = useState({
        username: '',
        password: '',
        email: '',
        recaptcha: '',
        recaptchaValidation: ''
    });
    const [recaptchaReady, setRecaptchaReady] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const defaultRedirect = '/account';
    const [fromPage, setFromPage] = useState(location.state?.from || defaultRedirect);
    const { RenderAlertModal, showAlert } = useCustomAlert();

    useEffect(() => {
        // Load reCAPTCHA script
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LeIZRQpAAAAAF6YEaWqZEuY3Q9AJBkriOrPkaQq';
        script.addEventListener('load', () => setRecaptchaReady(true));
        document.body.appendChild(script);

    }, []);

    useEffect(() => {
        // Check if navigated from services page with an email
        if (location.state?.from === '/services' && location.state?.email) {
            console.log({ target: { email: location.state.email } });
            handleChange({ target: { name: "email", value: location.state.email } })
            setFromPage("/quotes");
            // Trigger popup if coming from services page
            showAlert(`Thanks for submitting a quote request ${location.state.email} !Please create an account view your quote when it's ready.`, "Quote Request Success");
        }
        // eslint-disable-next-line
    }, [location.state]);

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    //TODO: Impliment assessment check and reject bad actors.
    const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current.executeAsync();
        // apply to form data
        console.log("token", token);
        const newFormData = handleCaptcha(token);
        const ReCAPTCHAVerification = await requestReCAPTCHAAssessment(newFormData);
        newFormData.recaptchaValidation = JSON.stringify({
            expectedAction: ReCAPTCHAVerification.event.expectedAction,
            userAgent: ReCAPTCHAVerification.event.userAgent,
            userIpAddress: ReCAPTCHAVerification.event.userIpAddress,
            riskAnalysisScore: ReCAPTCHAVerification.riskAnalysis.score,
            riskAnalysisReasons: ReCAPTCHAVerification.riskAnalysis.reasons,
        });
        console.log(newFormData);
        return newFormData;
    }

    const handleCaptcha = (value) => {
        const newUserData = { ...userData, recaptcha: value, recaptchaValidation: "" };
        setUserData(newUserData);
        return newUserData;
    }

    const requestReCAPTCHAAssessment = async (newUserData) => {
        const requestBody = {
            event: {
                token: newUserData.recaptcha,
                siteKey: "6LeIZRQpAAAAAF6YEaWqZEuY3Q9AJBkriOrPkaQq",
                expectedAction: 'register-form-submit'
            }
        };

        try {
            const response = await fetch("https://recaptchaenterprise.googleapis.com/v1/projects/civil-hash-405620/assessments?key=AIzaSyD336a_Ucs_jzhGTvET9FSibjak1VdlIrw", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log('reCAPTCHA assessment:', responseData);
                return responseData;
                // You can check the assessment score here and decide whether to submit the form data
            } else {
                console.error('reCAPTCHA assessment failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during reCAPTCHA verification:', error);
            return error;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitFormData = await onSubmitWithReCAPTCHA();
        if (!submitFormData.recaptcha) {
            alert('Please verify that you are a human!');
            return;
        }
        // Submit logic goes here. For example, sending data to an API endpoint.
        console.log('Form data:', submitFormData);
        const success = await register(userData.username, userData.password, userData.email, userData.recaptcha, userData.recaptchaValidation);
        if (success) {
            navigate(fromPage);
            // Redirect or additional actions after successful registration
        } else {
            alert("Registration error - please contact customer support.")
        }
    };

    return (
        <>
            {recaptchaReady ?
                <Container className="create-account-container">
                    <RenderAlertModal />
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            <div className='form-header-register rounded-top-3 p-2 mx-auto'>
                                <h2 className='text-dark'>Create Account</h2>
                            </div>
                            <Form onSubmit={handleSubmit} className='form-container-register mt-0 rounded-top-0 mx-auto'>
                                <Form.Group className="mb-3" controlId="formBasicUsername">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        value={userData.username}
                                        onChange={handleChange}
                                        placeholder="Choose a username"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={userData.email}
                                        onChange={handleChange}
                                        placeholder="Enter your email"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <div className='d-flex align-items-center'>
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            value={userData.password}
                                            onChange={handleChange}
                                            placeholder="Create a password"
                                        />
                                        {showPassword ? <EyeSlashFill className='ms-1 icon-button' size={30} onClick={() => setShowPassword(!showPassword)} />
                                            :
                                            <EyeFill className='ms-1 icon-button' size={30} onClick={() => setShowPassword(!showPassword)} />
                                        }
                                    </div>

                                </Form.Group>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LeIZRQpAAAAAF6YEaWqZEuY3Q9AJBkriOrPkaQq"// reCAPTCHA site key
                                    size="invisible"
                                    onChange={handleCaptcha}
                                />
                                <Button variant="primary" type="submit">
                                    Create Account
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container> :
                <div>Loading, please wait...</div>

            }
        </>

    );
}

export default CreateAccountPage;
