import { useMemo } from 'react';

/**
 * A hook to generate an HTML email template.
 * 
 * @param {Object} params - Parameters to customize the email content.
 * @param {string} params.title - The title of the email.
 * @param {string} params.header - The header text.
 * @param {string} params.body - The main content of the email.
 * @param {string} params.footer - The footer text.
 * @returns {string} - The HTML email template as a string.
 */
const useEmailTemplate = ({ title, header, body, footer }) => {
    const htmlTemplate = useMemo(() => {
        return `
            <!DOCTYPE html>
            <html>
            <head>
                <title>${title}</title>
                <style>
                    body { font-family: Arial, sans-serif; }
                    .header { background-color: #f2f2f2; padding: 10px; text-align: center; }
                    .body { padding: 20px; }
                    .footer { background-color: #f2f2f2; padding: 10px; text-align: center; }
                </style>
            </head>
            <body>
                <div class="header">
                    <h1>${header}</h1>
                </div>
                <div class="body">
                    <p>${body}</p>
                </div>
                <div class="footer">
                    <p>${footer}</p>
                </div>
            </body>
            </html>
        `;
    }, [title, header, body, footer]);

    return htmlTemplate;
};

export default useEmailTemplate;
