import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'

import QuoteRequest from '../../../classes/QuoteRequest';
import ReactiveTable from '../adminData/components/ReactiveTable';
import { useUser } from '../../../context/UserContext';
import { isset } from '../../../library/library';
import { useNavigate } from 'react-router-dom';
import ServiceSelectionModal from './components/ServiceSelectionModal';
import useCustomAlert from '../../../library/useCustomAlert';
import useMyLocation from '../../../library/useMyLocation';
import QuoteDisplay from '../QuoteDisplay';
import { ArrowClockwise } from 'react-bootstrap-icons';
import QuoteRequestView from './components/QuoteRequestView';


function UserQuotes() {
    const { fetchUserQuotes, openQuotes, closedQuotes, quoteRequests, updateQuoteViewHistory, fetchUserOrders } = useUser();
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const navigate = useNavigate();
    const [location, setLocation] = useMyLocation({ from: "" });
    const [fromLocation, setFromLocation] = useState(location.state?.from || "/account");
    const [selectedQuote, setSelectedQuote] = useState(null);
    const [selectedQuoteRequest, setSelectedQuoteRequest] = useState(null);
    const [showQuote, setShowQuote] = useState(false);
    const [showQuoteRequest, setShowQuoteRequest] = useState(false);


    //console.log(location);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            (
                async () => {
                    try {
                        await fetchUserQuotes('request');
                        await fetchUserQuotes('open');
                        await fetchUserQuotes('closed');
                    } catch (error) {
                        console.error(error);
                    }
                }
            )();
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);



    useEffect(() => {
        let mounted = true;
        if (mounted && location.state?.from) {
            setFromLocation(location.state.from)
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let mounted = true;
        if (mounted && fromLocation === "/servicesQuoteRequest" && location.state?.selectedService) {
            const qr = new QuoteRequest();
            showAlert(`New ${qr.services[location.state.selectedService]} quote request has been submitted.`, "Success!");
            setLocation({ from: "/services" });
            //window.history.replaceState({ from: "/services" }, document.title);
            setFromLocation("/services");
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [fromLocation]);


    const refreshQuotes = async () => {
        await handleGetQuoteRequests(true);
        await handleGetOpenQuotes(true);
        await handleGetClosedQuotes(true);
    }


    const handleGetQuoteRequests = async (forceRefresh = false) => {
        await fetchUserQuotes('request', forceRefresh);
    }

    const handleGetOpenQuotes = async (forceRefresh = false) => {
        await fetchUserQuotes('open', forceRefresh);
    }

    const handleGetClosedQuotes = async (forceRefresh = false) => {
        await fetchUserQuotes('closed', forceRefresh);
    }


    const handleSelectService = (selected) => {
        navigate('/services', { state: { from: '/newquoterequest', selectedService: selected } });
    }


    const quoteRowClick = (location, value, e) => {
        // console.log("quoteRowClick Callback", location, value, e.target.childNodes[0]);
        // Find the quote in the openQuotes array using the quote ID
        const selectedQuote = openQuotes.find(quote => quote.objectId === value);
        if (selectedQuote) {
            // Logic to display the selected quote
            // You can set the selected quote to a state and then render the QuoteDisplay component with this state
            if (selectedQuote.activity.isNew || selectedQuote.activity.isUpdated) updateQuoteViewHistory(selectedQuote.objectId);
            setSelectedQuote(selectedQuote); // Assuming you have a state called `setSelectedQuote`
            setShowQuote(true);
        } else {
            showAlert("This quote is closed.  If you feel this is in error, please contact customer support.", "Quote Closed.");
            console.error("Quote not found");
            // Handle the scenario where the quote is not found
        }
    }

    const quoteRequestRowClick = (location, value, e) => {
        console.log("quoteRowClick Callback", location, value, e.target.childNodes[0]);
        const selectedQuote = quoteRequests.find(quoteRequest => quoteRequest.objectId === value);
        if (selectedQuote) {
            // Logic to display the selected quote
            // You can set the selected quote to a state and then render the QuoteDisplay component with this state
            setSelectedQuoteRequest(selectedQuote); // Assuming you have a state called `setSelectedQuote`
            setShowQuoteRequest(true);
        } else {
            console.error("Quote Request not found");
            // Handle the scenario where the quote is not found
        }
    }


    const handleCloseQuote = () => {
        setShowQuote(false);
        setShowQuoteRequest(false);
    }

    const handleAcceptQuote = async (quote) => {
        setShowQuote(false);
        try {
            await quote.accept();
            refreshQuotes();
            fetchUserOrders('open', true);
            showAlert(`Quote ID ${quote.id} has been digitally signed and can now be found in your orders tab.  We look forward to working with you!`, "Quote Accepted!");
        } catch (error) {
            if (error.message === "ParseError: 137 This quote has already been accepted.") {
                showAlert(`That quote has already been accepted. You can not accept twice.`, "Invalid Signing Attempt");
            } else {
                showAlert(`A signing error has occured - please contact customer support!  We look forward to working with you!`, "Server Error!");
            }
        }

    }

    console.log("openQuotes", openQuotes);
    //console.log("closedQuotes", closedQuotes);
    console.log("quoteRequests IN USERQUOTES", quoteRequests);


    return (
        <div className='p-md-4 pt-md-0'>
            <div className='d-flex justify-content-end'>
                <ServiceSelectionModal handleSelectService={handleSelectService} buttonText={"New Quote Request"} title={"Please select a service to continue"} />
                <RenderAlertModal />
                <QuoteRequestView quoteRequest={selectedQuoteRequest} show={showQuoteRequest} handleCloseQuote={handleCloseQuote} />
            </div>
            <div className='d-flex mt-2'>
                <h1>Quotes Home</h1>
                <ArrowClockwise className='icon-as-button no-background ms-2'
                    onClick={() => {
                        return refreshQuotes(true);
                    }}
                    size={30} />
            </div>

            <Row className='mt-2'>
                <Col>
                    {quoteRequests[0].ready ? (quoteRequests[0].objectId ?
                        <ReactiveTable data={quoteRequests} title={"Requests"} clickHandler={quoteRequestRowClick}
                            headerOverride={["In Review", "Service", "Timeframe", "Name", "Company", "Email", "Mobile Number", "Request Date"]} /> :
                        <div className='data-label-container color-black bold rounded-bottom-3'>No Quote Requests</div>) : <div className='d-flex align-items-center'><Spinner className='me-3' /> Loading, please wait...</div>}

                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>

                    {selectedQuote && <QuoteDisplay quote={selectedQuote} show={showQuote} onClose={handleCloseQuote} onAccept={handleAcceptQuote} />}
                    {isset(openQuotes) && openQuotes[0].ready ? (openQuotes[0].objectId ? <ReactiveTable data={openQuotes} title={"Open"} clickHandler={quoteRowClick} /> :
                        <div className='data-label-container color-black bold rounded-bottom-3'>No Open Quotes</div>) : <div className='d-flex align-items-center'><Spinner className='me-3' /> Loading, please wait...</div>}
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    {closedQuotes[0].ready ? (closedQuotes[0].objectId ? <ReactiveTable data={closedQuotes} title={"Closed"} clickHandler={quoteRowClick} /> :
                        <div className='data-label-container color-black bold rounded-bottom-3'>No Closed Quotes</div>) : <div className='d-flex align-items-center'><Spinner className='me-3' /> Loading, please wait...</div>}
                </Col>
            </Row>


        </div>

    );
}

export default UserQuotes



