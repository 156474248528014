import React from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

import "../../../services/ServicesModal.css"
import QuoteRequest from '../../../../classes/QuoteRequest';


function QuoteRequestView({ quoteRequest, show, handleCloseQuote }) {
    const services = new QuoteRequest();

    if (!quoteRequest) {
        return (
            <></>
        );
    }


    const renderServiceSpecificQuestions = () => {
        switch (quoteRequest.selectedService) {
            case 'AVProduction':
            case 'Consulting':
            case 'OnSiteManagement':
                return (
                    <>
                        <Row>
                            <Col sm={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-larger pb-0 mb-0'>{services.getServiceDetails(quoteRequest.selectedService, 1).question}</Form.Label>
                                    <p className=''>{quoteRequest.answerOne}</p>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-larger pb-0 mb-0'>{services.getServiceDetails(quoteRequest.selectedService, 2).question}</Form.Label>
                                    <p className=''>{quoteRequest.answerTwo}</p>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3">
                            <Form.Label className='text-larger pb-0 mb-0'>{services.getServiceDetails(quoteRequest.selectedService, 3).question}</Form.Label>
                            <p className=''>{quoteRequest.answerThree}</p>
                        </Form.Group>
                    </>
                );

            default:
                return (
                    <></>
                );
        }

    };

    return (
        <Modal size="xl" className='services-modal-container' show={show} onHide={handleCloseQuote}>
            <Modal.Header className='modal-header align-items-start' closeVariant='white' closeButton>
                <div className='d-inline mx-auto w-100'>
                    <Modal.Title>Request a Quote</Modal.Title>
                    <h5 className=''>{services.services[quoteRequest.selectedService]}</h5>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate >
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className='mb-0'>First & Last Name</Form.Label>
                                <p className='mt-0'>{quoteRequest.name}</p>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className='mb-0'>Company Name</Form.Label>
                                <p className='mt-0'>{quoteRequest.company}</p>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className='mb-0'>Mobile Contact Number</Form.Label>
                                <p className='mt-0'>{quoteRequest.mobileNumber}</p>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='align-items-end'>
                        <Col sm={6} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className='mb-0'>Email Address</Form.Label>
                                <p className='mt-0'>{quoteRequest.email}</p>
                            </Form.Group>
                        </Col>
                        <Col sm={6} md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Anticipated Month and Year of Service</Form.Label>
                                <p className='mt-0'>{quoteRequest.serviceMonth}</p>
                            </Form.Group>
                        </Col>
                    </Row>

                    {renderServiceSpecificQuestions()}
                    <Modal.Footer className='modal-footer mx-auto justify-content-between'>
                        {quoteRequest.isNew ? <span className='text-success bold'>This quote request has been accepted and is currently under review. Please check back soon for the quote!</span>
                            : <span className='text-primary bold'>Thank you for your patience, we're working as fast as we can and will be in touch soon!</span>}
                        <Button variant="primary" onClick={handleCloseQuote}>
                            Close
                        </Button>
                    </Modal.Footer>

                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default QuoteRequestView;
