import React, { useState, useCallback } from 'react';
import { is_array } from './library';


/**
 * Custom alert modal component.
 * 
 * @param {Object} props - Component props.
 * @param {boolean} props.show - Whether the modal is visible.
 * @param {string} props.header - The header text for the modal.
 * @param {string} props.message - The message text for the modal.
 * @param {Function} props.onClose - Callback to hide the modal.
 * @param {boolean} props.showAccept - Whether to show an accept button.
 * @param {boolean} props.isError - True if the alert is an error
 * @param {Function} props.onClose - Callback to execute the acceptance.
 * @param {string} props.acceptVerbiage - The text for the accept button. Default: "Accept"
 * @param {string} props.closeVerbiage - The text for the accept button. Default: "Accept"
 * @returns {React.ReactNode} - The custom alert modal JSX or null.
 */
const CustomAlertModal = ({ show, header, message, onClose, showAccept, isError = false, onAccept, acceptVerbiage, closeVerbiage = "Close" }) => {
    if (!show) return null;

    return (
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1500,
            padding: '0px',
            borderRadius: '10px',
            boxShadow: '7px 7px 8px rgba(0, 0, 0, 0.8)',
            width: '80%',
            maxWidth: '400px',
            color: '#fff',
            backgroundColor: 'rgba(32, 32, 32, 0.0)'
        }}>
            <div style={{
                color: isError ? "red" : "black",
                background: isError ? 'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 0, 1))' : 'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(0, 55, 171, 1))',
                borderRadius: '10px 10px 0 0',
                padding: '10px',
            }}>
                <h1 style={{ marginBottom: '0px' }}>{header}</h1>
            </div>
            <div style={{ backgroundColor: 'rgba(32, 32, 32, 1)', borderRadius: '0 0 10px 10px', }}>
                <div style={{ padding: "20px", fontSize: "1em" }}>{is_array(message) ?
                    message.map((messagePart, index) => {
                        return (<p key={`messagePart_${index}`}>{messagePart}</p>);
                    }) : message
                }</div>
                <button onClick={onClose} style={{
                    margin: '20px',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    backgroundColor: '#0d6efd',
                    color: '#fff',
                    cursor: 'pointer'
                }}>{closeVerbiage}</button>
                {showAccept ?
                    <button onClick={onAccept} style={{
                        margin: '20px',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        backgroundColor: '#0d6efd',
                        color: '#fff',
                        cursor: 'pointer'
                    }}>{acceptVerbiage}</button> : <></>}
            </div>

        </div>
    );
};

/**
* - RenderAlertModal: Place this element inside your component return.
* - showAlert(message, header, onCloseCallback, showAccept, acceptVerbiage, isError, acceptCallback, closeVerbiage) : Creates a custom alert modal
 * @param {string} showAlert.message - The message text for the modal.
 * @param {string} showAlert.header - The header text for the modal, defaults to "Alert".
 * @param {Function} showAlert.onCloseCallback - Callback function to execute on closing the modal or hitting close, defaults to an empty function.
 * @param {boolean} showAlert.showAccept= - Flag to show accept button in the modal.
 * @param {boolean} showAlert.isError= - True if the alert is an error. Default: false
 * @param {string} showAlert.acceptVerbiage - Text for the accept button, defaults to "Accept".
 * @param {string} showAlert.closeVerbiage - Text for the default close button, defaults to "Close".
 * @param {Function} showAlert.acceptCallback - Callback function to execute on accept button click, defaults to an empty function.
 * @returns {{
*  RenderAlertModal: Function,
*  showAlert: Function,
*  hideAlert: Function,
* }}
 */
const useCustomAlert = () => {
    const [alert, setAlert] = useState({
        show: false,
        message: '',
        header: '',
        showAccept: false,
        acceptVerbiage: "Accept",
        closeVerbiage: "Close",
        onCloseCallback: () => { },
        acceptCallback: () => { }
    });



    /**
     * Shows an alert modal with a custom message and header. Optionally includes an accept button with customizable text and callback.
     * 
     * @param {string} message - The message text for the modal.
     * @param {string} [header="Alert"] - The header text for the modal, defaults to "Alert".
     * @param {boolean} [showAccept=false] - Flag to show accept button in the modal.
     * @param {string} [acceptVerbiage="Accept"] - Text for the accept button, defaults to "Accept".
     * @param {boolean} [isError=flase] - True if the alert should be an error message.
     * @param {Function} [onCloseCallback=() => {}] - Callback function to execute on closing the modal or hitting close, defaults to an empty function.
     * @param {Function} [acceptCallback=() => {}] - Callback function to execute on accept button click, defaults to an empty function.
     * @param {string} [closeVerbiage="Close"] - Text for the close button, defaults to "Close".
     */
    const showAlert = useCallback((message, header = "Alert", onCloseCallback = () => { }, showAccept = false, acceptVerbiage = "Accept", isError = false, acceptCallback = () => { }, closeVerbiage = "Close",) => {
        setAlert({ showAccept, acceptVerbiage, closeVerbiage, isError, onCloseCallback, acceptCallback, show: true, message, header });
    }, []);


    const hideAlert = useCallback(() => {
        alert.onCloseCallback();
        setAlert({
            showAccept: false,
            acceptVerbiage: "Accept",
            closeVerbiage: "Close",
            isError: false,
            onCloseCallback: () => { },
            acceptCallback: () => { },
            show: false,
            message: '',
            header: ''
        });
        // eslint-disable-next-line
    }, [alert.onCloseCallback]);

    const acceptAlert = useCallback(() => {
        alert.acceptCallback();
        setAlert({
            showAccept: false,
            acceptVerbiage: "Accept",
            closeVerbiage: "Close",
            isError: false,
            onCloseCallback: () => { },
            acceptCallback: () => { },
            show: false,
            message: '',
            header: ''
        });
        // eslint-disable-next-line
    }, [alert.acceptCallback]);

    const RenderAlertModal = () => (
        <CustomAlertModal
            show={alert.show}
            header={alert.header}
            message={alert.message}
            onClose={hideAlert}
            showAccept={alert.showAccept}
            onAccept={acceptAlert}
            acceptVerbiage={alert.acceptVerbiage}
            closeVerbiage={alert.closeVerbiage}
            isError={alert.isError}
        />
    );

    return { RenderAlertModal, showAlert, hideAlert };
};

export default useCustomAlert;
