import React, { useState, useEffect } from 'react'
import { MyDate, intval, objectCopy } from '../../../../library/library';
import PreQuote from '../../../../classes/PreQuote';
import { Button, Col, Form, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ReactiveTable from './ReactiveTable';
import { ArrowCounterclockwise } from 'react-bootstrap-icons';

function JobNotesPublicSection({ preQuote, setPreQuote, currentUser }) {
    const [jobNotesRow, setJobNotesRow] = useState(Object.values(preQuote.jobNotes).length);
    const [jobNotesEditData, setJobNotesEditData] = useState({ date: "", userId: "", name: "", note: "" });
    const [jobNotesHistory, setJobNotesHistory] = useState([]);

    useEffect(() => {
        let mounted = true;
        if (mounted && intval(jobNotesRow) < intval(Object.values(preQuote.jobNotes).length) && jobNotesRow !== "") {
            setJobNotesEditData(preQuote.jobNotes[jobNotesRow] || { date: "", userId: "", name: "", note: "" });
        }
        if (mounted && jobNotesRow === "") {
            setJobNotesEditData({ date: "", userId: "", name: "", note: "" });
        }

        if (checkJobNotesIDs(preQuote.jobNotes)) {
            reKeyJobNotes(preQuote);
        }

        return () => mounted = false;
        // eslint-disable-next-line
    }, [jobNotesRow, preQuote.jobNotes]);


    const checkJobNotesIDs = (jobNotes) => {
        let test = false;
        let lastKey = 0;
        Object.keys(jobNotes).forEach((key) => {
            const intKey = intval(key);
            if (intKey > 0 && intKey !== lastKey + 1) {
                test = true;
            }
            lastKey = intval(key);
        });
        return test;
    }

    const reKeyJobNotes = (preQuote) => {
        const newJobNotes = {};
        Object.values(preQuote.jobNotes).forEach((value, index) => {
            newJobNotes[index] = value;
        });
        const newPreQuote = new PreQuote(objectCopy(preQuote));
        newPreQuote.jobNotes = objectCopy(newJobNotes);
        setPreQuote(newPreQuote);
    }


    // Update functions for Job Notes
    const updateJobNotesHistory = () => {
        setJobNotesHistory(prevHistory => [...prevHistory, { ...preQuote.jobNotes }]);
    }

    // Add/Update, Remove, Clear, and Undo Functions for Job Notes
    const addUpdateJobNote = () => {
        updateJobNotesHistory();
        const newJobNote = objectCopy(jobNotesEditData);
        newJobNote.name = currentUser.name;
        newJobNote.userId = currentUser.id;
        const date = new MyDate();
        newJobNote.date = date.timeStamp;
        const newPreQuote = new PreQuote(objectCopy(preQuote));
        newPreQuote.jobNotes = objectCopy(preQuote.jobNotes);
        newPreQuote.jobNotes[jobNotesRow] = newJobNote;
        setPreQuote(newPreQuote);
    };


    const removeJobNote = () => {
        updateJobNotesHistory();
        setPreQuote(prev => {
            //console.log(prev, { ...prev });
            const modifiedPreQuote = new PreQuote(objectCopy(prev));
            // console.log({ ...modifiedPreQuote });
            delete modifiedPreQuote.jobNotes[jobNotesRow];
            // console.log(modifiedPreQuote);
            return modifiedPreQuote;
        });
        setJobNotesRow("");
    };

    const handleJobNoteTableClick = (table, rowNum, rowData, cellData, event) => {
        setJobNotesRow(rowNum);
        setJobNotesEditData(preQuote.jobNotes[rowNum] || { date: "", userId: "", name: "", note: "" });
    };



    const handleJobNoteUndo = () => {
        if (jobNotesHistory.length === 0) return;

        const newHistory = jobNotesHistory.slice();
        const previousState = newHistory.pop();

        setJobNotesHistory(newHistory);
        setPreQuote((prev) => {
            const newPreQuote = new PreQuote({ ...prev });
            newPreQuote.jobNotes = { ...previousState };
            return newPreQuote;
        });
        // setPreQuote(prev => (new PreQuote({ ...prev, jobNotes: previousState })));

    };

    const renderJobsPublicNameTooltip = (props) => {
        return (
            <Tooltip id="jobs-public-name-tooltip" {...props}>
                The name and ID of the posting are automatically updated based on your login information when you save or add a note.
            </Tooltip>
        )
    }

    return (
        <>
            <ReactiveTable
                data={Object.values(preQuote.jobNotes)}
                headerOverride={["Date", "User ID", "Name", "Note"]}
                clickHandler={handleJobNoteTableClick}
                useRowNumbers={true}
                rowNumberHeader="ID"
            />
            <Row>
                <Col xs={4} md={4} lg={1}>
                    <Form.Group>
                        <Form.Label className='text-black'>ID {intval(jobNotesRow) === intval(Object.values(preQuote.jobNotes).length) ?
                            <i className='text-success smaller'>New</i> : <></>
                        }</Form.Label>
                        <Form.Control
                            type="number"
                            name="jobId"
                            value={jobNotesRow}
                            onChange={(e) => setJobNotesRow(() => {
                                if (e.target.value >= 1 && e.target.value < intval(Object.values(preQuote.jobNotes).length)) {
                                    return e.target.value;
                                }
                                if (e.target.value >= intval(Object.values(preQuote.jobNotes).length)) {
                                    setJobNotesEditData((prev) => {
                                        return { ...prev, name: currentUser.name, note: "" };
                                    });
                                    return intval(Object.values(preQuote.jobNotes).length);
                                }
                                if (e.target.value <= 0) {
                                    return 0;
                                }
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col xs={8} md={6} lg={3}>
                    <Form.Group>
                        <Form.Label className='text-black'>Name</Form.Label>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 300 }} overlay={renderJobsPublicNameTooltip}>
                            <Form.Control
                                readOnly
                                type="text"
                                name="name"
                                value={jobNotesEditData.name || ""}
                                onChange={(e) => setJobNotesEditData((prev) => {
                                    return { ...prev, [e.target.name]: currentUser.name };
                                })}
                            />
                        </OverlayTrigger>
                    </Form.Group>
                </Col>

                <Col md={12} lg={8}>
                    <Form.Group>
                        <Form.Label className='text-black'>Notes</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            name="note"
                            value={jobNotesEditData.note || ""}
                            onChange={(e) => setJobNotesEditData((prev) => {
                                return { ...prev, [e.target.name]: e.target.value };
                            })}
                        />
                    </Form.Group>
                </Col>

            </Row>
            <Row className='mt-3'>
                <Col>
                    {intval(jobNotesRow) === intval(Object.values(preQuote.jobNotes).length) ?
                        <Button disabled={jobNotesEditData.note === ""} className='m-2' variant='success' onClick={addUpdateJobNote}>Add Note</Button> : <Button disabled={jobNotesRow === "" || jobNotesEditData.note === "" ? true : false} variant='success' onClick={addUpdateJobNote}>Update Note</Button>}

                    <Button className='m-2' variant="danger" onClick={removeJobNote}
                        disabled={intval(jobNotesRow) >= intval(Object.values(preQuote.jobNotes).length) || jobNotesRow === "" ? true : false}>Delete Note</Button>
                    <Button className='m-2' variant="secondary" onClick={() => setJobNotesRow("")}>Clear</Button>
                    <Button disabled={jobNotesHistory.length === 0} className={`m-2 ${jobNotesHistory.length === 0 ? "border-0" : ""}`} variant="secondary-outline" onClick={handleJobNoteUndo}> <ArrowCounterclockwise size={25} /> Undo</Button>
                </Col>
            </Row>
        </>
    )
}

export default JobNotesPublicSection