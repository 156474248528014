import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

function ButtonToolTip({ id, children, toolTipText, className, variant, placement, delayShow, delayHide, onClick, type, value, size, disabled, }) {

    const ToolTipButton = () => (
        <OverlayTrigger delay={{ show: delayShow ? delayShow : 250, hide: delayHide ? delayHide : 400 }} placement={placement ? placement : "top"} overlay={<Tooltip id={id}>{toolTipText}</Tooltip>}>
            <Button type={type ? type : "button"} value={value ? value : ""} size={size ? size : ""} onClick={onClick ? onClick : () => { }} variant={variant ? variant : "primary"} className={className ? className : ""} disabled={disabled} >{children}</Button>
        </OverlayTrigger>
    );


    return (<ToolTipButton />)
}

export default ButtonToolTip