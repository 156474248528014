import { useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

const PINK = '#6c757d';
const BLUE = '#0d6efd';

function CreateAQuoteToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button
            type="button"
            style={{ backgroundColor: isCurrentEventKey ? PINK : BLUE }}
            onClick={decoratedOnClick}
            className='rounded-2 text-white'
        >
            {children}
        </button>
    );
}

export default CreateAQuoteToggle