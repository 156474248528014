import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import Parse from 'parse';
import useAuth from '../../auth/useAuth';
import useCustomAlert from '../../../library/useCustomAlert';
import PasswordResetModal from './PasswordResetModal';
import { objectCopy } from '../../../library/library';
import Floppy from '../../../images/floppy.svg';

import './Profile.css';

const Profile = () => {
    const { currentUser } = useAuth();
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const { logout, setLoading } = useAuth();
    const [saving, setSaving] = useState(false);
    const [profile, setProfile] = useState({
        username: currentUser.username,
        password: "render button",
        email: currentUser.email,
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        company: currentUser.company,
        mobileNumber: currentUser.mobileNumber,
        officeNumber: currentUser.officeNumber,
        billingStreet: currentUser.billingStreet,
        billingCity: currentUser.billingCity,
        billingState: currentUser.billingState,
        billingZip: currentUser.billingZip,
    });


    useEffect(() => {
        let mounted = true;
        if (mounted) setLoading(false);
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        setSaving(true);
        e.preventDefault();

        try {
            const submitProfile = objectCopy(profile);
            delete submitProfile.password;
            // Update user profile
            const user = Parse.User.current();
            for (let key in submitProfile) {
                user.set(key, submitProfile[key]);
            }
            await user.save();
            setSaving(false);
            showAlert("Profile updated successfully.", "Save Successful");
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('Error updating profile.');
            setSaving(false);
        }
    };

    return (
        <div className="profile-container my-5 p-0">
            <RenderAlertModal />
            <h2 className='rounded-bottom-0 text-left'>Update Profile</h2>
            <Form onSubmit={handleSubmit} className='m-3'>
                <Row>
                    {/* Add form fields for each profile attribute */}
                    {Object.keys(profile).map((key) => (
                        ["username", "email", "firstname", "lastname", "company", "mobileNumber", "officeNumber", "billingCity", "billingState", "billingZip"].includes(key) ?
                            <Col xs={10} md={6} lg={6} xl={5} key={key}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>{currentUser.form[key].name}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={key}
                                        value={profile[key]}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            : key === "password" ? <Col md={4} key={key}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>{currentUser.form[key].name}</Form.Label>
                                    <PasswordResetModal currentUser={currentUser} logout={logout} />
                                </Form.Group>
                            </Col> :
                                <Col md={8} key={key}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>{currentUser.form[key].name}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={key}
                                            value={profile[key]}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                    ))}
                </Row>
                <Button className="mb-3" variant="primary" type="submit" disabled={saving}>
                    {saving ? <Spinner /> : <>Save <img alt="save-floppy-icon" className='m-0 p-0 pb-1' src={Floppy} /></>}
                </Button>
            </Form>
        </div>
    );
};

export default Profile;
