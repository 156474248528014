import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Spinner } from 'react-bootstrap'
import useAdmin from '../../../library/useAdmin'
import ReactiveTable from './components/ReactiveTable';
import { ArrowClockwise } from 'react-bootstrap-icons';
import QuoteRequestAdminView from './QuoteRequestAdminView';
import { useUser } from '../../../context/UserContext';
import useCustomAlert from '../../../library/useCustomAlert';
import CreateAQuote from './CreateAQuote';
import { MyDate, is_string } from '../../../library/library';
import EditAQuote from './EditAQuote';



function Quotes() {
    const { currentUser } = useUser();
    const { quotes, getAllOpenQuotesAsAdmin, quoteRequests, getAllOpenQuoteRequestsAsAdmin, savePreQuote, getAllPreQuotes, preQuotes, convertPreQuoteToQuote, saveQuote } = useAdmin();
    const [isLoading, setIsLoading] = useState(true);
    const [showQuoteRequest, setShowQuoteRequest] = useState(false);
    const [selectedQuoteRequest, setSelectedQuoteRequest] = useState(null);
    const [selectedPreQuoteRequest, setSelectedPreQuoteRequest] = useState(null);
    const [showQuote, setShowQuote] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const [showCreateQuote, setShowCreateQuote] = useState(false);

    const preQuoteData = {
        preQuoteId: '', // Use this if updating an existing PreQuote
        userId: '', // Set this to the customer's User objectId
        quoteRequestId: '',
        selectedService: '',
        toName: '',
        toCompany: '',
        toEmail: '',
        toMobileNumber: '',
        fromName: '',
        fromCompany: '',
        fromEmail: '',
        fromMobileNumber: '',
        serviceStartDate: null,
        serviceEndDate: null,
        items: {},
        jobNotes: {},
        jobNotesInternal: { 0: { date: new Date(), userId: currentUser.id, note: 'Initial quote request conversion.' } },
        isActive: false,
    };

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            (
                async () => {
                    await getAllOpenQuotesAsAdmin();
                    await getAllOpenQuoteRequestsAsAdmin();
                    await getAllPreQuotes();
                    setIsLoading(false);
                }
            )();

        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);


    const refreshQuotes = async () => {
        await getAllOpenQuotesAsAdmin(true);
        await getAllOpenQuoteRequestsAsAdmin(true);
        await getAllPreQuotes(true);
    }

    const createNewQuote = async () => {
        setShowCreateQuote(true);
    }

    const quoteRowClick = (location, value, e) => {
        console.log("quoteRowClick CallBack", location, value, e.target.childNodes[0]);
        const clickedQuote = quotes.find(quote => quote.objectId === value);
        console.log("clickedQuote", clickedQuote);
        if (clickedQuote) {
            setSelectedQuote(clickedQuote);
            setShowQuote(true);
        } else {
            console.error("Quote not found");
            // Handle the scenario where the quote is not found

        }
    }

    const quoteRequestRowClick = (location, value, e) => {
        console.log("quoteRowClick Callback", location, value, e.target.childNodes[0]);
        const clickedQuote = quoteRequests.find(quoteRequest => quoteRequest.objectId === value);
        if (clickedQuote) {
            setSelectedQuoteRequest(clickedQuote);
            setShowQuoteRequest(true);
        } else {
            console.error("Quote Request not found");
            // Handle the scenario where the quote is not found
        }

    }

    const preQuoteRowClick = (location, value, e) => {
        console.log("preQuoteRowClick CallBack", location, value, e.target.childNodes[0]);
        const clickedQuote = Object.values(preQuotes).find(preQuote => preQuote.objectId === value);
        if (clickedQuote) {
            setSelectedPreQuoteRequest(clickedQuote);
            setShowCreateQuote(true);
        } else {
            console.error("Quote Request not found");
            // Handle the scenario where the quote is not found
        }
    }

    const handleCloseQuoteRequest = () => {
        setSelectedQuoteRequest(null);
        setShowQuoteRequest(false);
    }

    const handleDeclineQuoteRequest = () => {
        //TODO: Impliment Decline QuoteRequest
        setShowQuoteRequest(false);
    }

    const handleAcceptQuoteRequest = async () => {
        preQuoteData.preQuoteId = ""; // Use this if updating an existing PreQuote
        preQuoteData.userId = selectedQuoteRequest.userId // Set this to the customer's User objectId
        preQuoteData.quoteRequestId = selectedQuoteRequest.objectId;
        preQuoteData.selectedService = selectedQuoteRequest.selectedService;
        preQuoteData.toName = selectedQuoteRequest.name;
        preQuoteData.toCompany = selectedQuoteRequest.company;
        preQuoteData.toEmail = selectedQuoteRequest.email;
        preQuoteData.toMobileNumber = selectedQuoteRequest.mobileNumber;
        preQuoteData.fromName = currentUser.name;
        preQuoteData.fromCompany = currentUser.company;
        preQuoteData.fromEmail = currentUser.email;
        preQuoteData.fromMobileNumber = currentUser.mobileNumber;
        preQuoteData.isActive = true;
        const dateTimeStamp = new MyDate();
        preQuoteData.jobNotesInternal = {
            0: { date: dateTimeStamp.timeStamp, userId: currentUser.id, name: "System Admin", note: '* Initial quote request conversion.' },
            1: { date: dateTimeStamp.timeStamp, userId: currentUser.id, name: "System Admin", note: `* Anticipated Year/Month of service is ${selectedQuoteRequest.serviceMonth}` },
            2: { date: dateTimeStamp.timeStamp, userId: currentUser.id, name: "System Admin", note: `* ${selectedQuoteRequest.questionOne}: ${selectedQuoteRequest.answerOne}` },
            3: { date: dateTimeStamp.timeStamp, userId: currentUser.id, name: "System Admin", note: `* ${selectedQuoteRequest.questionTwo}: ${selectedQuoteRequest.answerTwo}` },
            4: { date: dateTimeStamp.timeStamp, userId: currentUser.id, name: "System Admin", note: `* ${selectedQuoteRequest.questionThree}: ${selectedQuoteRequest.answerThree}` },
            5: { date: dateTimeStamp.timeStamp, userId: currentUser.id, name: "System Admin", note: `* Email notes: ${selectedQuoteRequest.notes}` },
            6: { date: dateTimeStamp.timeStamp, userId: currentUser.id, name: "System Admin", note: `* Mobile Number Notes: ${selectedQuoteRequest.acceptTexts ? "Customer accepts texts." : "This customer does not accept texts!"}` },
        };
        if (await savePreQuote(preQuoteData)) {
            showAlert(`Conversion successful. The quote request was converted to a pre-quote and now can be found in your Pre-Quote inbox.`, "Pre-Quote Created!", () => { setShowQuoteRequest(false); refreshQuotes(); })
        } else {
            showAlert(`The quote request failed to convert. Please contact your site admin.  Hint: That's Matt.`, "Conversion Error", () => setShowQuoteRequest(false));
        }
    }

    //console.log("preQuotes", preQuotes);
    //console.log("currentUser", currentUser);
    console.log("quotes", quotes);
    //console.log("quoteRequests", quoteRequests);``

    const handleCloseCreateQuote = () => {
        setShowCreateQuote(false);
        setSelectedPreQuoteRequest(null);
    }
    const handleCloseQuote = () => {
        setSelectedQuote(null);
        setShowQuote(false);
    }

    const handleSaveQuote = async (quoteIn) => {
        if (is_string(quoteIn.serviceStartDate)) quoteIn.serviceStartDate = new Date(quoteIn.serviceStartDate);
        if (is_string(quoteIn.serviceEndDate)) quoteIn.serviceEndDate = new Date(quoteIn.serviceEndDate);
        try {
            const savedQuote = await saveQuote(quoteIn);
            const fetchedQuotes = await getAllOpenQuotesAsAdmin(true);
            const clickedQuote = Object.values(fetchedQuotes).find(quote => quote.objectId === savedQuote.objectId);
            if (clickedQuote) {
                setSelectedQuote(clickedQuote);
            }
            return savedQuote;
        } catch (error) {
            throw new Error(error);
        }
    }

    const handleSaveCreateQuote = async (preQuote) => {
        if (is_string(preQuote.serviceStartDate)) preQuote.serviceStartDate = new Date(preQuote.serviceStartDate);
        if (is_string(preQuote.serviceEndDate)) preQuote.serviceEndDate = new Date(preQuote.serviceEndDate);
        preQuote.isActive = true;
        const savedPreQuote = await savePreQuote(preQuote);
        if (savedPreQuote && savedPreQuote.objectId) {
            const fetchedPreQuotes = await getAllPreQuotes(true); //Force api refresh
            const clickedQuote = Object.values(fetchedPreQuotes).find(preQuote => preQuote.objectId === savedPreQuote.objectId);
            if (clickedQuote) {
                setSelectedPreQuoteRequest(clickedQuote);
            }
            return savedPreQuote;
        }
        return false;
    }

    const handleDeletePreQuote = async () => {
        await getAllPreQuotes(true); //Force api refresh
        await getAllOpenQuoteRequestsAsAdmin(true);

    }

    const handleFinalizeCreateQuote = async (preQuoteId) => {
        if (!preQuoteId) {
            throw new Error("You must pass a pre-quote ID to convert a pre-quote.")
        }
        try {
            const newQuoteId = await convertPreQuoteToQuote(preQuoteId);
            await getAllOpenQuotesAsAdmin(true);
            await getAllOpenQuoteRequestsAsAdmin(true);
            await getAllPreQuotes(true);
            return newQuoteId
        } catch (error) {
            throw new Error(`Pre-Quote conversion failed with an error of: ${error.message}`);
        }
    }

    return (
        <div className='mt-2 p-md-4'>
            <div className='d-flex justify-content-end'>
                <Button onClick={createNewQuote}>Create New Quote</Button>
                <CreateAQuote show={showCreateQuote} handleClose={handleCloseCreateQuote} preQuoteData={selectedPreQuoteRequest} clearPreQuoteData={() => setSelectedPreQuoteRequest(null)} handleSave={handleSaveCreateQuote} handleFinalize={handleFinalizeCreateQuote} currentUser={currentUser} parentDeletePreQuote={handleDeletePreQuote}
                />
            </div>

            <QuoteRequestAdminView quoteRequest={selectedQuoteRequest} show={showQuoteRequest} handleClose={handleCloseQuoteRequest} handleAccept={handleAcceptQuoteRequest} handleDecline={handleDeclineQuoteRequest} preQuotes={preQuotes} />
            <RenderAlertModal />
            <div className='d-flex'>
                <h1>Quotes Home</h1>
                <ArrowClockwise className='icon-as-button no-background ms-2'
                    onClick={() => {
                        return refreshQuotes();
                    }}
                    size={30} />
            </div>

            {isLoading ? <div> <Spinner /> Loading, Please wait... </div> : <>
                <EditAQuote show={showQuote} handleClose={handleCloseQuote} quoteDataIn={selectedQuote} clearPreQuoteData={() => setSelectedQuote(null)} handleSave={handleSaveQuote} handleFinalize={handleFinalizeCreateQuote} currentUser={currentUser} parentDeletePreQuote={handleDeletePreQuote}

                />
                <Row className='mt-2'>
                    <Col>
                        {quoteRequests[0].ready ? (quoteRequests[0].objectId ? <ReactiveTable data={quoteRequests} title={"Requests"} clickHandler={quoteRequestRowClick} /> :
                            <div className='data-label-container color-black bold rounded-bottom-3'>No Quote Requests</div>) : <div className='d-flex align-items-center'><Spinner className='me-3' /> Loading, please wait...</div>}

                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        {preQuotes[0].ready ? (preQuotes[0].objectId ? <ReactiveTable data={preQuotes} title={"Pre-Quote"} clickHandler={preQuoteRowClick} /> :
                            <div className='data-label-container color-black bold rounded-bottom-3'>No Pre-Quotes</div>) : <div className='d-flex align-items-center'><Spinner className='me-3' /> Loading, please wait...</div>}
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        {quotes[0].ready ? (quotes[0].objectId ? <ReactiveTable data={quotes} title={"Open"} clickHandler={quoteRowClick} /> :
                            <div className='data-label-container color-black bold rounded-bottom-3'>No Open Quotes</div>) : <div className='d-flex align-items-center'><Spinner className='me-3' /> Loading, please wait...</div>}
                    </Col>
                </Row>
            </>}

        </div>

    );
}

export default Quotes