import React, { useState } from 'react';
import { Modal, Table, Button, Row, Col, Container, Image, Offcanvas, Form, CloseButton } from 'react-bootstrap';
import './OrderDisplay.css'; // Your CSS file for additional styling
import { useUser } from '../../context/UserContext';
import X4Logo from "../../images/Logo Concept NEW - Small - BLUE Satin.png"
import { MyDate, roundTo } from '../../library/library';
import { XSquare } from 'react-bootstrap-icons';
import JobNotesInternalOrder from './adminData/components/JobNotesInternalOrder';
import JobNotesPublicOrder from './adminData/components/JobNotesPublicOrder';


const OrderDisplay = ({ order, show, onClose, onAccept, addJobNotes, noClickAway }) => {
    const { currentUser, siteInfo } = useUser();
    const [showJobNotes, setShowJobNotes] = useState(true);
    const [showAddJobNotes, setShowAddJobNotes] = useState(false);
    const [showAddInternalJobNotes, setShowAddInternalJobNotes] = useState(false);
    const [showInternalJobNotes, setShowInternalJobNotes] = useState(true);
    const [showTerms, setShowTerms] = useState(false);
    const handleCloseTerms = () => setShowTerms(false);
    const handleShowTerms = () => setShowTerms(true);


    if (!order || !order.isset) {
        return null;
    }

    console.log("show Order", order);
    // Function to render table rows for items
    const renderTableRows = () => {
        return Object.values(order.items).map((item, index) => (
            <tr key={index}>
                <td>{item.quantity}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>${item.cost}</td>
                <td>{item.notes}</td>
            </tr>
        ));
    };

    function printContent() {
        setShowJobNotes(true);
        setTimeout(() => {
            const content = document.querySelector('.order-display-container').innerHTML;
            const printWindow = window.open('', '_blank');
            printWindow.document.write(`
            <html>
                <head>
                    <title>Print</title>
                    <style>
                        /* General Styles */
                        body { font-family: Arial, sans-serif; }
                        .page-title {font-size: 2em; display: flex; justify-content: space-between;}
                        .order-display-body { margin-top: 50px;}
                        .order-display-items-title {margin-top: 50px; padding-bottom: 5px;}
                        .order-info { display: flex; justify-content: space-between; }
                        .bill-to, .remit-to { width: 49%; text-align: left; }
                        .company-logo-image {max-width: 100px !important;}
                        h4 {font-size: 1.5em; margin-bottom: 0px !important;}
    
                        .bill-to div, .remit-to div { white-space: nowrap;}
                        .company-info div, .company-logo div { white-space: nowrap; }

                        .bill-to, .remit-to { align-self: flex-start; } 
                        .bill-to .underline, .remit-to .underline { text-decoration: underline;} 
                        .company-info, .company-logo { align-self: flex-start; } 
                        .company-info h2 {margin-bottom: 5px;}
                        .company-info p {margin: 0px;}
                        .time-place-section-one {margin-top: 10px;}
                        .time-place-section-one h6, time-place-section-two h6 {margin-top: 5px;}
                        .totals {display: flex; justify-content: space-between; margin-top: 20px }
                        .totals-row {display: flex; justify-content: space-around;}
                        .col-a { }
                        .col-b { }
                        .col-b-1 {margin-end: 10px !important;}
                        .col-b-2 {margin-start: 10px !important;}
                        .totals-label { margin-right: 10px; }
    
                        .terms {
                            margin-top: 1rem !important; 
                            padding-right: 5rem !important; 
                            font-size: smaller !important; 
                        }
                        .terms p {
                            margin-bottom: 5px !important; 
                        }
                        .terms .row {
                            display: flex !important;
                            flex-direction: row;
                        }
                        .terms .col-1 {
                            flex: 0 0 6% !important; 
                            text-align: right !important; 
                            padding-right: 1rem !important; 
                        }
                        .terms .col-11 {
                            flex: 0 0 94% !important; 
                            text-align: left !important; 
                        }
                        .acceptance {margin-top: 50px}
                        .acceptance h4 {margin-bottom: 0px}
                        .signature-section { display: flex; justify-content: space-around;}
                        .signature-date { margin-top: 30px; text-align: left; }
                        .signature-line, .date-line { padding-right: 10px; }
                        .w-50 {width: 50% !important;}
    
                        /* Table Styles */
                        table { width: 100%; border-collapse: collapse; margin-top: 0px; }
                        th, td { border: 1px solid black; padding: 5px; }
                        @media print {
                            .no-print {
                                display: none;
                            }
                
                        }
                        
                    </style>
                </head>
                <body>
                    ${content}
                </body>
            </html>
        `);
            printWindow.document.close();
            printWindow.focus();
            setTimeout(() => {
                printWindow.print();
                printWindow.close();
            }, 500);
        }, 500);

    }



    const handleAddJobNote = async (note) => {
        await addJobNotes("PUBLIC", note);
    }

    const handleAddInternalJobNote = async (note) => {
        await addJobNotes("INTERNAL", note);
    }

    return (
        <Modal backdrop={noClickAway ? "static" : true} className="order-display-modal" show={show} onHide={onClose} size="xl" centered>
            <Container fluid className="order-display-container section-to-print">
                {/* Header with company info and logo */}
                <Modal.Header className="header rounded-0 order-display-header d-flex flex-column align-items-end">
                    <div className='no-print'>
                        <Button className='mt-0 me-4' onClick={printContent}>Print</Button>
                        <XSquare type="button" size={40} onClick={onClose} />
                    </div>
                    <Modal.Title className='page-title d-flex w-100 justify-content-between'>
                        <div className='font-larger'>{order && order.isset && !order.isActive ? <b className='text-danger'>CLOSED </b> : <></>}Order</div>
                        <div>{order.services[order.selectedService]}</div> </Modal.Title>
                    <hr />
                </Modal.Header>
                <div className='order-display-subheader'>
                    <Row className='subheader-row no-print'>
                        <Col className="company-info" >
                            <h2>{siteInfo.settings.companyName}</h2>
                            <p>{'<123 Street Address, City, State, Zip/Post>'}</p>
                            <p>{'webmaster@x4int.com'}</p>
                            <p>{'<Phone Number>'}</p>
                        </Col>
                        <Col className="company-logo">
                            <Image src={X4Logo} fluid className='company-logo-image' />
                        </Col>
                    </Row>

                    <Row className='order-info justify-content-between no-screen'>
                        <Col xs={5} className="text-start">
                            <div className='company-info'>
                                <h2>{siteInfo.settings.companyName}</h2>
                                <p>{'<123 Street Address, City, State, Zip/Post>'}</p>
                                <p>{'webmaster@x4int.com'}</p>
                                <p>{'<Phone Number>'}</p>
                            </div>

                        </Col>
                        <Col xs={7} lg={5} className="text-start">
                            <div className='company-logo ms-3'>
                                <Image src={X4Logo} fluid className='company-logo-image' />
                            </div>
                        </Col>
                    </Row>
                </div>


                {/* Order Information */}
                <Modal.Body className="order-display-body">
                    <Row className='order-info justify-content-between'>
                        <Col xs={5} className="text-start">
                            <div className='bill-to'>
                                <strong className='underline'>BILL TO</strong>
                                <div>
                                    <strong>Name:</strong>
                                    <span className='ps-2'>{order.toName}</span>
                                </div>
                                <div>
                                    <strong>Company:</strong>
                                    <span className='ps-2'>{order.toCompany}</span>
                                </div>
                                <div>
                                    <strong>Email:</strong>
                                    <span className='ps-2'>{order.toEmail}</span>
                                </div>
                                <div>
                                    <strong>Phone:</strong>
                                    <span className='ps-2'>{order.toMobileNumber}</span>
                                </div>
                            </div>

                        </Col>
                        <Col xs={7} lg={5} className="text-start">
                            <div className='remit-to ms-3'>
                                <strong className='underline'>REMIT TO</strong>
                                <div>
                                    <strong>Order ID:</strong>
                                    <span className='ps-2'>{order.id}</span>
                                </div>
                                <div>
                                    <strong>Date:</strong>
                                    <span className='ps-2'>{order.date}</span>
                                </div>
                                <div>
                                    <strong>Prepared by:</strong>
                                    <span className='ps-2'>{order.fromName}</span>
                                </div>
                                <div>
                                    <strong>Payment ID:</strong>
                                    <span className='ps-2'>{order.invoiceId}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <h4 className='order-display-items-title mt-5'>Time & Place</h4>
                        <Col>
                            <div className='order-service-datestime-place-section-one'>
                                <Form className='d-flex justify-content-start '>
                                    <Form.Group className="me-4">
                                        <Form.Label className='text-black mb-1' >Service Begin:</Form.Label>
                                        <h6>{new MyDate(order.serviceStartDate).readable}</h6>
                                    </Form.Group>
                                    <Form.Group className="">
                                        <Form.Label className='text-black mb-1' >Service End:</Form.Label>
                                        <h6>{new MyDate(order.serviceEndDate).readable}</h6>
                                    </Form.Group>
                                </Form>
                            </div>
                            <div className='order-service-dates mt-2 time-place-section-two'>
                                <Form className='d-flex justify-content-start'>
                                    <Form.Group className="me-4">
                                        <Form.Label className='text-black mb-1' >Service Location:</Form.Label>
                                        <h6>{order.serviceLocation}</h6>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Col>
                    </Row>


                    {/* Table for Order Details */}
                    <h4 className='order-display-items-title'>Service Details</h4>
                    <Table striped bordered hover className='order-display-items'>
                        <thead>
                            <tr>
                                <th>Quantity</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Cost</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableRows()}
                        </tbody>
                    </Table>

                    {/* Totals and Terms */}
                    <Row className="totals justify-content-between">
                        <Col xs={5} className='col-a'>
                            <p className='font-larger'><strong>Thank you for your business!</strong></p>
                            {/* ... Terms & Instructions */}
                        </Col>

                        <Col xs={6} lg={4} className="text-start justify-content-end col-b">
                            <Row className='totals-row'>
                                <Col className='col-b-1'>
                                    <div className='ms-xl-5'>
                                        <div>
                                            <strong>Subtotal: &nbsp;</strong>
                                        </div>
                                        <div>
                                            <strong>Tax: &nbsp;</strong>
                                        </div>
                                        <div>
                                            <strong>Total: &nbsp;</strong>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-b-2'>
                                    <div className='text-right pe-md-5 me-xl-5'>
                                        <div>
                                            <span className=''>${roundTo(order.amount, 2, true)}</span>
                                        </div>
                                        <div>
                                            <span className=''>${roundTo(order.tax, 2, true)}</span>
                                        </div>
                                        <div>
                                            <span className=''><b>${roundTo(order.total, 2, true)}</b></span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    {/* JOB NOTES SECTION */}
                    <h4>Job Notes</h4>
                    {showJobNotes ?
                        <div className='terms font-smaller pe-5 ms-0 ps-0'>
                            {!Object.values(order.jobNotes).length ? <i>No job notes yet.</i> : <></>}
                            {Object.values(order.jobNotes).map(({ date, note, name }, jobNumber) => {
                                return (
                                    <Row key={jobNumber}>
                                        <Col xs={1} className='text-end'>
                                            <p>{jobNumber}.</p>
                                        </Col>
                                        <Col xs={11} className='mb-1'>
                                            <p>
                                                <strong>{date}: </strong> {note} <i><small><b className='color-darkblue'>By: {name}</b></small></i>
                                            </p>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                        : <Button onClick={() => setShowJobNotes(true)}>Show Job Notes</Button>}
                    {showAddJobNotes && currentUser.role === "Admin" ?
                        <div>
                            <div className='d-flex w-100 justify-content-end icon-link-hover pointer bg-dark'><CloseButton onClick={() => setShowAddJobNotes(false)} as={Button} variant='white' /></div>
                            <JobNotesPublicOrder jobNotesIn={order.jobNotes} order={order} setOrder={handleAddJobNote} currentUser={currentUser} />
                        </div>

                        : <></>}

                    <hr />

                    {/* INTERNAL JOB NOTES SECTION */}
                    {currentUser && currentUser.id && currentUser.role === "Admin" ? <>
                        <div className={`no-print ${showInternalJobNotes ? "bg-warning" : "bg-light"} p-2`}>
                            <h4>Internal Job Notes</h4>
                            {showInternalJobNotes ?
                                <div className='terms font-smaller pe-5 ms-0 ps-0'>
                                    {!Object.values(order.jobNotesInternal).length ? <i>No internal job notes yet.</i> : <></>}
                                    {Object.values(order.jobNotesInternal).map(({ date, note, name }, jobNumber) => {
                                        return (
                                            <Row key={jobNumber}>
                                                <Col xs={1} className='text-end'>
                                                    <p>{jobNumber}.</p>
                                                </Col>
                                                <Col xs={11} className='mb-1'>
                                                    <p>
                                                        <strong>{date}: </strong> {note} <i><small><b className='color-darkblue'>By: {name}</b></small></i>
                                                    </p>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </div>
                                : <Button variant='primary' onClick={() => setShowInternalJobNotes(true)}>Show Internal Job Notes</Button>}
                            {showAddInternalJobNotes && currentUser.role === "Admin" ?
                                <div>
                                    <div className='d-flex w-100 justify-content-end icon-link-hover pointer bg-warning'><CloseButton onClick={() => setShowAddInternalJobNotes(false)} as={Button} className='text-black' /></div>
                                    <JobNotesInternalOrder jobNotesIn={order.jobNotesInternal} order={order} setOrder={handleAddInternalJobNote} currentUser={currentUser} />
                                </div>

                                : <></>}
                            <hr />
                        </div>
                    </> : <></>}


                    <div className='mt-3'>
                        <Button className='no-print' variant="warning" onClick={handleShowTerms}>
                            Terms & Conditions
                        </Button>
                        {!showJobNotes ? <></> : <Button variant='outline-primary' className='mt-0 ms-3 no-print' onClick={() => setShowJobNotes(false)}>Hide Job Notes</Button>}
                        { }
                        {currentUser.role === "Admin" ?
                            <>
                                {!showInternalJobNotes ? <></> : <Button variant='outline-primary' className='mt-0 ms-3 no-print' onClick={() => setShowInternalJobNotes(false)}>Hide Internal Job Notes</Button>}
                                {showJobNotes && !showAddJobNotes && order.isActive ? <Button className='mt-0 ms-3 no-print' variant='info' onClick={() => setShowAddJobNotes(true)}>Add Job Notes</Button> : <></>}
                                {showJobNotes && showAddJobNotes && order.isActive ? <Button className='mt-0 ms-3 no-print' variant='secondary' onClick={() => setShowAddJobNotes(false)}>Close Add Job Notes</Button> : <></>}

                                {showInternalJobNotes && !showAddInternalJobNotes && order.isActive ? <Button onClick={() => setShowAddInternalJobNotes(true)} className='mt-0 ms-3 no-print' variant='info'>Add Internal Job Notes</Button> : <></>}
                                {showInternalJobNotes && showAddInternalJobNotes && order.isActive ? <Button onClick={() => setShowAddInternalJobNotes(false)} className='mt-0 ms-3 no-print' variant='secondary'>Close Add Internal Job Notes</Button> : <></>}
                            </>
                            : <></>}
                    </div>


                    <Offcanvas show={showTerms} onHide={handleCloseTerms} className="offcanvas-above-modal">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title><h4>Terms & Conditions</h4></Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className='pt-0'>
                            <div className='terms font-smaller pe-5 ms-0 ps-0'>
                                <Row>
                                    <Col xs={1} className='text-end'>
                                        <p>1.</p>
                                    </Col>
                                    <Col xs={11} className='mb-2'>
                                        <p>
                                            <strong>Scope of Work: </strong> {order.services[order.selectedService]} services as detailed above. Any changes to the scope of work must be agreed upon in writing by both parties.
                                        </p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={1} className='text-end'>
                                        <p>2.</p>
                                    </Col>
                                    <Col xs={11} className='mb-2'>
                                        <p>
                                            <strong>Payment Terms: </strong> A deposit of 50% is required to secure the services for the event date(s). The remaining balance is due 10 days prior to the event execution. Late payments are subject to interest at the rate of 1.5% per month on the outstanding balance. A 4% credit card processing fee will be assessed for all card payments. Checks accepted, contact us for information.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1} className='text-end'>
                                        <p>3.</p>
                                    </Col>
                                    <Col xs={11} className='mb-2'>
                                        <p>
                                            <strong>Cancellation Policy: </strong> Cancellations must be made in writing. If cancellation occurs 30 days after acceptance of this agreement, the deposit is forfeited. Cancellations made more than 30 days prior to the acceptance of this agreement will result in a full refund of the deposit.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1} className='text-end'>
                                        <p>4.</p>
                                    </Col>
                                    <Col xs={11} className='mb-2'>
                                        <p>
                                            <strong>Equipment: </strong> All audiovisual equipment provided remains the property of {siteInfo.settings.companyName}. The client is responsible for any damage or loss of equipment due to negligence or misuse while in the client's possession.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1} className='text-end'>
                                        <p>5.</p>
                                    </Col>
                                    <Col xs={11} className='mb-2'>
                                        <p>
                                            <strong>Subcontractors: </strong> {siteInfo.settings.companyName} reserves the right to employ subcontractors for any portion of the work. All subcontractors will be bound by the terms of this agreement.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1} className='text-end'>
                                        <p>6.</p>
                                    </Col>
                                    <Col xs={11} className='mb-2'>
                                        <p>
                                            <strong>Intellectual Property: </strong> Any intellectual property provided by the client remains the property of the client. Likewise, any intellectual property, including but not limited to designs, concepts, and presentations, developed by {siteInfo.settings.companyName} in the provision of services is the property of {siteInfo.settings.companyName} unless otherwise agreed upon.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1} className='text-end'>
                                        <p>7.</p>
                                    </Col>
                                    <Col xs={11} className='mb-2'>
                                        <p>
                                            <strong>Liability: </strong> {siteInfo.settings.companyName} shall not be liable for any indirect, special, or consequential damages arising out of this agreement. The client agrees to indemnify and hold {siteInfo.settings.companyName} harmless from any claims, damages, or expenses arising from the client's activities.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1} className='text-end'>
                                        <p>8.</p>
                                    </Col>
                                    <Col xs={11} className='mb-2'>
                                        <p>
                                            <strong>Force Majeure: </strong> Neither party shall be liable for delays or non-performance resulting from circumstances or causes beyond its reasonable control, including, but not limited to, acts of God, war, government action, terrorism, natural disaster, or utility outages.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1} className='text-end'>
                                        <p>9.</p>
                                    </Col>
                                    <Col xs={11} className='mb-2'>
                                        <p>
                                            <strong>Dispute Resolution: </strong> Any disputes arising out of this agreement shall first be addressed through good-faith negotiations. If a resolution cannot be reached, disputes shall be settled by arbitration in accordance with the rules of the American Arbitration Association.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1} className='text-end'>
                                        <p>10.</p>
                                    </Col>
                                    <Col xs={11} className='mb-2'>
                                        <p>
                                            <strong>Acceptance: </strong>Acceptance of the order, electronically or otherwise, signifies acceptance of these terms and conditions, which form a part of the agreement between the client and {siteInfo.settings.companyName}.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <div className='terms font-smaller pe-5 ms-0 ps-0 print-only'>
                        <h4>Terms & Conditions</h4>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>1.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Scope of Work: </strong> {order.services[order.selectedService]} services as detailed above. Any changes to the scope of work must be agreed upon in writing by both parties.
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>2.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Payment Terms: </strong> A deposit of 50% is required to secure the services for the event date(s). The remaining balance is due 10 days prior to the event execution. Late payments are subject to interest at the rate of 1.5% per month on the outstanding balance. A 4% credit card processing fee will be assessed for all card payments. Checks accepted, contact us for information.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>3.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Cancellation Policy: </strong> Cancellations must be made in writing. If cancellation occurs 30 days after acceptance of this agreement, the deposit is forfeited. Cancellations made more than 30 days prior to the acceptance of this agreement will result in a full refund of the deposit.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>4.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Equipment: </strong> All audiovisual equipment provided remains the property of {siteInfo.settings.companyName}. The client is responsible for any damage or loss of equipment due to negligence or misuse while in the client's possession.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>5.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Subcontractors: </strong> {siteInfo.settings.companyName} reserves the right to employ subcontractors for any portion of the work. All subcontractors will be bound by the terms of this agreement.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>6.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Intellectual Property: </strong> Any intellectual property provided by the client remains the property of the client. Likewise, any intellectual property, including but not limited to designs, concepts, and presentations, developed by {siteInfo.settings.companyName} in the provision of services is the property of {siteInfo.settings.companyName} unless otherwise agreed upon.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>7.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Liability: </strong> {siteInfo.settings.companyName} shall not be liable for any indirect, special, or consequential damages arising out of this agreement. The client agrees to indemnify and hold {siteInfo.settings.companyName} harmless from any claims, damages, or expenses arising from the client's activities.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>8.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Force Majeure: </strong> Neither party shall be liable for delays or non-performance resulting from circumstances or causes beyond its reasonable control, including, but not limited to, acts of God, war, government action, terrorism, natural disaster, or utility outages.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>9.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Dispute Resolution: </strong> Any disputes arising out of this agreement shall first be addressed through good-faith negotiations. If a resolution cannot be reached, disputes shall be settled by arbitration in accordance with the rules of the American Arbitration Association.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>10.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Acceptance: </strong>Acceptance of the order, electronically or otherwise, signifies acceptance of these terms and conditions, which form a part of the agreement between the client and {siteInfo.settings.companyName}.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Container>

            {/* Footer */}
            <Modal.Footer className="order-display-footer rounded-0 mb-5 pb-4 pt-4 justify-content-between">
                <div className=''>
                    {!order.isActive && currentUser.role === "Admin" ? <Button className='mt-0 mx-2' variant='outline-danger' onClick={() => onAccept(order, "DELETE")}>
                        Delete
                    </Button> : <></>}
                    {order.isActive ?
                        <>
                            <Button className='mt-0 mx-2' variant="danger" onClick={() => onAccept(order, "CANCEL")}>
                                Cancel Order
                            </Button>
                            {currentUser.role === "Admin" ?
                                <Button className='mt-0 mx-2' variant="warning" onClick={() => onAccept(order, "TOQUOTE")}>
                                    Convert To Quote
                                </Button>
                                : <></>}
                        </>
                        : <></>}

                </div>
                <div className=''>
                    {currentUser.role === "Admin" && order.isActive ?
                        <Button className='mt-0 mx-2' variant="primary" onClick={() => onAccept(order, "NOTIFY")}>
                            Notify Customer
                        </Button>
                        : <></>}

                    <Button className='mt-0 mx-2' variant="secondary" onClick={onClose}>
                        Close
                    </Button>

                    {currentUser.role === "Customer" && order.isActive ?
                        <Button className='mt-0 mx-2' variant="primary" onClick={() => onAccept(order, "PAY")}>
                            Pay
                        </Button>
                        : <></>}
                </div>




            </Modal.Footer>
            <div>&nbsp;</div>
        </Modal>
    );
};

export default OrderDisplay;


