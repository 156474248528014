import { useState, useEffect } from 'react';

function getSavedValue(key, initialValue) {
    const savedItem = localStorage.getItem(key);
    // console.log("savedItem", JSON.parse(savedItem), "KEY", key);
    if (savedItem) {
        const parsedItem = JSON.parse(savedItem);
        const now = new Date();
        if (!parsedItem.expiry || now.getTime() <= parsedItem.expiry) {
            // console.log(`Found and using saved value for ${key}`, "savedItem is:", JSON.parse(savedItem));
            return parsedItem.value;
        }
    }
    // console.log(`Using initial value for ${key}`);
    // Only create a new instance if there's no valid saved item
    return typeof initialValue === 'function' ? initialValue() : initialValue;
}

function getSavedExpiry(key, duration) {
    const savedItem = localStorage.getItem(key);
    if (savedItem) {
        const parsedItem = JSON.parse(savedItem);
        if (parsedItem.expiry) {
            return parsedItem.expiry;
        }
    }
    // Only create a new instance if there's no valid saved item
    return new Date().getTime() + duration;
}


/**
 * Custom hook that persists state in localStorage with an expiration time.
 * @param {string} key - The key to use in localStorage.
 * @param {any} initialValue - The initial value or a function that returns it.
 * @param {number} duration - Duration in milliseconds for the cache to expire. Default: 24 hours
 * @returns {[value, setValue, deleteValue, isExpired]} An array containing the state value, a setter function, a delete function, and a function to check expiration (non destructive).
 */
export default function useLocalStorage(key, initialValue, duration = 24 * 60 * 60 * 1000) { // default duration: 24 hours
    const [value, setValue] = useState(() => {
        return getSavedValue(key, initialValue);
    });


    useEffect(() => {
        const storedItem = JSON.parse(localStorage.getItem(key));
        let storedItemValue = null;
        if (storedItem && storedItem.hasOwnProperty(value)) storedItemValue = storedItem.value;
        // const isDifferent = storedItem !== JSON.stringify({ value, expiry: new Date().getTime() + duration });
        const isDifferent = storedItemValue !== JSON.stringify(value?.value);

        if (value !== undefined && (isDifferent || isExpired()) && value.toCache) {
            delete value.toCache;
            delete value.expiry;
            const expiry = new Date().getTime() + duration;
            // console.log(`Writing to local storage: ${key}: `, { value: value.value, expiry });
            localStorage.setItem(key, JSON.stringify({ value: value.value, expiry }));
        }
        // eslint-disable-next-line
    }, [value, key, duration]);


    function customSetValue(value, toCache = true) {
        const expiry = getSavedExpiry(key, duration);
        setValue({ value: value, expiry, toCache });
    }

    function deleteValue() {
        localStorage.removeItem(key);
        setValue(() => {
            return getSavedValue(key, initialValue);
        });
    }

    function isExpired() {
        const savedItem = localStorage.getItem(key);
        if (savedItem) {
            const parsedItem = JSON.parse(savedItem);
            const now = new Date();
            if (parsedItem.expiry && now.getTime() > parsedItem.expiry) {
                // localStorage.removeItem(key); // Delete the expired item
                return true;
            }
            return false;
        }
        return true;
    }

    const returnValue = value !== null && value !== undefined && value.hasOwnProperty("value") ? value.value : value;
    return [returnValue, customSetValue, deleteValue, isExpired];
}
