import React, { useState } from 'react';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import QuoteRequest from '../../../../classes/QuoteRequest';


function ServiceSelectionModal({ handleSelectService, buttonText, title, }) {
    const [show, setShow] = useState(false);
    const [selectedService, setSelectedService] = useState('');
    const servicesInstance = new QuoteRequest();
    const services = servicesInstance.services;

    const handleClose = () => {
        setSelectedService("");
        setShow(false)
    };
    const handleShow = () => {
        setShow(true)
    };

    const handleSelect = (key) => {
        setSelectedService(key);
        handleSelectService(key);
        handleClose();
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                {buttonText}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {selectedService || "Select a Service"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {Object.keys(services).map(serviceKey => (
                                <Dropdown.Item key={serviceKey} eventKey={serviceKey}>
                                    {services[serviceKey]}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ServiceSelectionModal;
