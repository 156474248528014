import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import Parse from 'parse';
import User from '../classes/User';
import useLocalStorage from '../library/useLocalStorage';
import Quote from '../classes/Quote';
import QuoteRequest from '../classes/QuoteRequest';
import { FIVE_MINUTES, getType, isset, objectCopy } from '../library/library';
import Order from '../classes/Order';

const UserContext = createContext();

const newQuoteRequest = new QuoteRequest();
newQuoteRequest.error = "";
newQuoteRequest.ready = false;

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(new User());
    const [userIsValid, setUserIsValid] = useState(true);
    const [siteInfo, setSiteInfo] = useState({ settings: { companyName: "X4 International" } })
    const [loading, setLoading] = useState(true);
    const [dashboardCounts, setDashboardCounts, clearDashboardCounts, dashboardCountsExpired] = useLocalStorage("userDashboardCounts", {
        quotes: {}, orders: {}, invoices: {}, isset: false
    }, FIVE_MINUTES);
    const [openQuotes, setOpenQuotes, clearOpenQuotes, openQuotesAreExpired] = useLocalStorage(`openUser_Quotes`, () => [new Quote(null, false)], FIVE_MINUTES);
    const [quoteRequests, setQuoteRequests, clearQuoteRequests, quoteRequestsAreExpired] = useLocalStorage(`user_QuotesRequests`, () => [newQuoteRequest], FIVE_MINUTES);
    const [closedQuotes, setClosedQuotes, clearClosedQuotes, closedQuotesAreExpired] = useLocalStorage(`closedUser_Quotes`, () => [new Quote(null, false)], FIVE_MINUTES);
    const [allQuotes, setAllQuotes, clearAllUserQuotes, allQuotesAreExpired] = useLocalStorage(`allUser_Quotes`, () => [new Quote(null, false)], FIVE_MINUTES);
    const [openOrders, setOpenOrders, clearOpenOrders, openOrdersAreExpired] = useLocalStorage(`openUser_Orders`, () => [new Order(null, false)], FIVE_MINUTES);
    const [closedOrders, setClosedOrders, clearClosedOrders, closedOrdersAreExpired] = useLocalStorage(`closedUser_Orders`, () => [new Order(null, false)], FIVE_MINUTES);
    const [allOrders, setAllOrders, clearAllUserOrders, allOrdersAreExpired] = useLocalStorage(`allUser_Orders`, () => [new Order(null, false)], FIVE_MINUTES);
    const [directOpenQO, setDirectOpenQO, clearDirectOpenQO, directOpenQOisExpired] = useLocalStorage(`directOpenQO`, () => [new Quote(null, false)], FIVE_MINUTES);


    const fetchingOrdersRef = useRef(false);
    const fetchingUserDashboardCounts = useRef(false);
    const fetchingQuoteOrOrder = useRef(false);


    useEffect(() => {
        let mounted = true;
        if (mounted && currentUser.isset) {
            setLoading(false);
        }
        return () => mounted = false;
    }, [currentUser]);

    const clearCache = () => {
        clearOpenQuotes();
        clearQuoteRequests();
        clearClosedQuotes();
        clearAllUserQuotes();
        clearOpenOrders();
        clearClosedOrders();
        clearAllUserOrders();
        clearDashboardCounts();
        clearDirectOpenQO();
    }

    /**
     * Returns all the user's dashboard counts
     * @param {*} forceRefresh 
     * @returns {object}
     */
    const getDashboardCounts = async (forceRefresh = false) => {
        // console.log(dashboardCountsExpired());
        if ((dashboardCountsExpired() || forceRefresh || !dashboardCounts.isset) && currentUser.role !== "Admin" && !fetchingUserDashboardCounts.current) {
            clearDashboardCounts();
            try {
                fetchingUserDashboardCounts.current = true;
                const counts = await Parse.Cloud.run('getUserDashboardCounts')
                if (counts) {
                    counts.isset = true;
                    setDashboardCounts(counts);
                }
            } catch (error) {
                console.error('Error fetching dashboard counts:', error);
                return { quotes: {}, orders: {}, invoices: {}, isset: false };
            } finally {
                fetchingUserDashboardCounts.current = false;
            }
        }
    };



    /**
     * Fetches user quotes based on the specified type.
     * 
     * @param {string} quoteType - Type of the quotes to fetch ('request', 'open', 'closed', 'total').
     * @param {boolean} forceNew - Forces a refreshed value from the API, rather than the unexpired cache value.
     * @returns {Promise<Array>} - A promise that resolves to an array of quote objects.
     */
    const fetchUserQuotes = async (quoteType, forceNew = false) => {
        let functionName;
        switch (quoteType.toLocaleLowerCase()) {
            case 'request':
                functionName = 'getUserQuoteRequests';
                if (forceNew) break;
                if (!quoteRequestsAreExpired() && quoteRequests[0].ready) {
                    if (getType(quoteRequests[0].getTabulation)) {
                        const reHydratedQuoteRequest = Object.values(quoteRequests).map((quote) => {
                            return Object.assign(new QuoteRequest(), quote);
                        });
                        setQuoteRequests(reHydratedQuoteRequest);
                        return reHydratedQuoteRequest;
                    }
                }
                break;
            case 'open':
                functionName = 'getOpenUserQuotes';
                if (forceNew) break;
                if (!openQuotesAreExpired() && openQuotes[0].ready) {
                    const reHydratedQuotes = Object.values(openQuotes).map((quote) => {
                        return Object.assign(new Quote(), quote);
                    });
                    setOpenQuotes(reHydratedQuotes);
                    return reHydratedQuotes;
                }
                break;
            case 'closed':
                functionName = 'getClosedUserQuotes';
                if (forceNew) break;
                if (!closedQuotesAreExpired() && closedQuotes[0].ready) {
                    const reHydratedQuotes = Object.values(closedQuotes).map((quote) => {
                        return Object.assign(new Quote(), quote);
                    });
                    setClosedQuotes(reHydratedQuotes);
                    return reHydratedQuotes;
                }
                break;
            case 'total':
                functionName = 'getTotalUserQuotes';
                if (forceNew) break;
                if (!allQuotesAreExpired() && allQuotes[0].ready) {
                    const reHydratedQuotes = Object.values(allQuotes).map((quote) => {
                        return Object.assign(new Quote(), quote);
                    });
                    setAllQuotes(reHydratedQuotes);
                    return reHydratedQuotes;
                }
                break;
            default:
                throw new Error('Invalid quote type');
        }

        try {
            const response = await Parse.Cloud.run(functionName);
            let returnResponse = response;
            switch (quoteType) {
                case 'request':
                    if (!isset(response)) {
                        const thisRequest = new QuoteRequest();
                        thisRequest.error = "";
                        thisRequest.ready = true;
                        returnResponse = [thisRequest];
                        setQuoteRequests(returnResponse);
                        break;
                    }
                    returnResponse = response.map((request) => {
                        const thisRequest = new QuoteRequest();
                        thisRequest.error = "";
                        return thisRequest.saveQuoteRequest(request);
                    });
                    setQuoteRequests(returnResponse);
                    break;
                case 'open':
                    if (!isset(response)) {
                        returnResponse = [new Quote("", true)];
                        setOpenQuotes(returnResponse);
                        break;
                    } else {
                        returnResponse = response.map((open) => {
                            return new Quote(open);
                        });
                        setOpenQuotes(returnResponse);
                    }
                    break;
                case 'closed':
                    if (!isset(response)) {
                        returnResponse = [new Quote("", true)];
                        setClosedQuotes(returnResponse);
                        break;
                    } else {
                        returnResponse = response.map((closed) => {
                            return new Quote(closed);
                        });
                        setClosedQuotes(returnResponse);
                    }
                    break;
                case 'total':
                    if (!isset(response)) {
                        returnResponse = [new Quote("", true)]
                        setAllQuotes(returnResponse);
                        break;
                    } else {
                        returnResponse = response.map((total) => {
                            return new Quote(total);
                        })
                        setAllQuotes(returnResponse);
                    }
                    break;

                default:
                    break;
            }
            return returnResponse;
        } catch (error) {
            console.error('Error fetching quotes:', error);
            throw error;
        }
    };



    const updateQuoteViewHistory = async (quoteId) => {
        try {
            // Get current user
            const currentUser = Parse.User.current();

            // Check if there's a logged-in user
            if (!currentUser) {
                throw new Error('No user logged in.');
            }

            // Fetch the Quote object
            const Quote = Parse.Object.extend('Quotes');
            const query = new Parse.Query(Quote);
            const quote = await query.get(quoteId);

            // Verify that the current user matches the userId of the quote
            if (quote.get('userId').id !== currentUser.id) {
                throw new Error('You do not have permission to update this quote.');
            }

            // Get the current activity object
            const activity = quote.get('activity') || {};

            // Update viewHistory array
            const viewHistory = activity.viewHistory || [];
            viewHistory.push(new Date().toISOString());

            // Save the updated activity object
            quote.set('activity', { ...activity, viewHistory, isNew: false, isUpdated: false });
            await quote.save(null, { sessionToken: currentUser.getSessionToken() });
            return true;
            //console.log('Quote view history updated successfully.');
        } catch (error) {
            console.error('Failed to update quote view history:', error.message);
            return false;
        }
    };


    /**
    * Fetches user orders based on the specified type.
    * 
    * @param {string} orderType - Type of the orders to fetch ('request', 'open', 'closed', 'total').
    * @param {boolean} forceNew - Forces a refreshed value from the API, rather than the unexpired cache value.
    * @returns {Promise<Array>} - A promise that resolves to an array of order objects.
    */
    const fetchUserOrders = async (orderType, forceNew = false) => {
        let functionName;
        switch (orderType.toLocaleLowerCase()) {
            case 'open':
                functionName = 'getOpenUserOrders';
                if (forceNew) break;
                if (!openOrdersAreExpired() && openOrders[0].ready) {
                    const reHydratedOrders = Object.values(openOrders).map((order) => {
                        return Object.assign(new Order(), new Order(objectCopy(order)));
                    });
                    setOpenOrders(reHydratedOrders);
                    return reHydratedOrders;
                }
                break;
            case 'closed':
                functionName = 'getClosedUserOrders';
                if (forceNew) break;
                if (!closedOrdersAreExpired() && closedOrders[0].ready) {
                    const reHydratedOrders = Object.values(closedOrders).map((order) => {
                        return Object.assign(new Order(), order);
                    });
                    setClosedOrders(reHydratedOrders);
                    return reHydratedOrders;
                }
                break;
            case 'total':
                functionName = 'getTotalUserOrders';
                if (forceNew) break;
                if (!allOrdersAreExpired() && allOrders[0].ready) {
                    const reHydratedOrders = Object.values(allOrders).map((order) => {
                        return Object.assign(new Order(), order);
                    });
                    setAllOrders(reHydratedOrders);
                    return reHydratedOrders;
                }
                break;
            default:
                throw new Error('Invalid order type');
        }

        try {
            if (fetchingOrdersRef.current) return;
            fetchingOrdersRef.current = true;
            const response = await Parse.Cloud.run(functionName);
            let returnResponse = response;
            switch (orderType) {
                case 'open':
                    if (!isset(response)) {
                        returnResponse = [new Order("", true)];
                        setOpenOrders(returnResponse);
                        break;
                    } else {
                        returnResponse = response.map((open) => {
                            return new Order(open);
                        });
                        setOpenOrders(returnResponse);
                    }
                    break;
                case 'closed':
                    if (!isset(response)) {
                        returnResponse = [new Order("", true)];
                        setClosedOrders(returnResponse);
                        break;
                    } else {
                        returnResponse = response.map((closed) => {
                            return new Order(closed);
                        });
                        setClosedOrders(returnResponse);
                    }
                    break;
                case 'total':
                    if (!isset(response)) {
                        returnResponse = [new Order("", true)]
                        setAllOrders(returnResponse);
                        break;
                    } else {
                        returnResponse = response.map((total) => {
                            return new Order(total);
                        })
                        setAllOrders(returnResponse);
                    }
                    break;

                default:
                    break;
            }
            return returnResponse;
        } catch (error) {
            console.error('Error fetching orders:', error);
            throw error;
        } finally {
            fetchingOrdersRef.current = false;
        }
    };


    const cancelUserOrder = async (quoteId) => {
        try {
            await Parse.Cloud.run('cancelUserOrder', { quoteId });
            return true;
        } catch (error) {
            console.error('Failed to cancel order:', error);
            return false;
        }
    }

    const getUserQuoteOrOrder = async (objectId, forceNew = false) => {
        if (forceNew) clearDirectOpenQO();

        if ((!directOpenQO.isset || directOpenQOisExpired() || forceNew || directOpenQO.objectId !== objectId) && !fetchingQuoteOrOrder.current) {
            fetchingQuoteOrOrder.current = true;
            try {
                console.log("Fetching Order or Quote", directOpenQO.objectId !== objectId, directOpenQO, objectId);
                const result = await Parse.Cloud.run('getQuoteOrOrderById', {
                    objectId: objectId
                });
                console.log("Fetched:", result);
                const parsedResult = { ...result, ...result.attributes };
                let qtOrObj = new Quote(parsedResult, true);
                if (parsedResult?.acceptance) qtOrObj = new Order(parsedResult, true);
                setDirectOpenQO(qtOrObj);
                fetchingQuoteOrOrder.current = false;
                console.log("Returning qtOrObj:", qtOrObj);
                return qtOrObj;
            } catch (error) {
                if (error.code === 119) setUserIsValid(false);
                console.error('Failed to fetch Quote or Order', error);
                clearDirectOpenQO();
                fetchingQuoteOrOrder.current = false;
                const errorQuote = new Quote();
                errorQuote.error = "We appologize, however we locate that quote or order using the ID provided.  Please contact customer support.";
                return errorQuote;
            }
        }

        if (!directOpenQOisExpired() && directOpenQO.isset && (directOpenQO instanceof Quote !== true || directOpenQO instanceof Order !== true)) {
            console.log("Rehydrating Order or Quote");
            let rehydrated = new Quote(directOpenQO, true);
            if (directOpenQO?.acceptance) rehydrated = new Order(directOpenQO, true);
            setDirectOpenQO(rehydrated);
            return rehydrated;
        }
        console.log("Returning Order or Quote");
        return directOpenQO;
    }





    const contextValue = { currentUser, setCurrentUser, userIsValid, setUserIsValid, siteInfo, setSiteInfo, loading, setLoading, clearCache, fetchUserQuotes, openQuotes, quoteRequests, closedQuotes, allQuotes, fetchUserOrders, openOrders, closedOrders, allOrders, cancelUserOrder, getDashboardCounts, dashboardCounts, updateQuoteViewHistory, getUserQuoteOrOrder, directOpenQO, clearDirectOpenQO };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

/**
 * @typedef {Object} UserContextType
 * @property {User} currentUser - The current user, an instance of User class.
 * @property {Function} setCurrentUser - Function to set the current user.
 * @property {Boolean} userIsValid - Function to set the current user.
 * @property {Function} setUserIsValid - Function to set the current user.
 * @property {Object} siteInfo - Site information.
 * @property {Function} setSiteInfo - Function to set site information.
 * @property {boolean} loading - Loading state.
 * @property {Function} setLoading - Function to set loading state.
 */

/**
 * Custom hook to access UserContext.
 * @returns {UserContextType: {currentUser: User, setCurrentUser: Function, userIsValid: Boolean, setUserIsValid: Function, siteInfo: any, setSiteInfo: Function, loading: boolean, setLoading: Function}} The User context.
 */
export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

export default UserContext;
