import React, { useState } from 'react';
import { Modal, Table, Button, Row, Col, Container, Image, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import './QuoteDisplay.css'; // Your CSS file for additional styling
import { useUser } from '../../context/UserContext';
import X4Logo from "../../images/Logo Concept NEW - Small - BLUE Satin.png"
import { roundTo } from '../../library/library';
import { XSquare } from 'react-bootstrap-icons';



const QuoteDisplay = ({ quote, show, onClose, onAccept, noClickAway }) => {
    const { siteInfo } = useUser();
    const [showJobNotes, setShowJobNotes] = useState(true);
    if (!quote || !quote.isset) {
        return null;
    }





    const renderTableRows = () => {
        return Object.values(quote.items).map((item, index) => (
            <tr key={index}>
                <td>{item.quantity}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>${item.cost}</td>
                <td>{item.notes}</td>
            </tr>
        ));
    };

    function printContent() {
        const content = document.querySelector('.quote-display-container').innerHTML;
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print</title>
                    <style>
                        /* General Styles */
                        body { font-family: Arial, sans-serif; }
                        .page-title {font-size: 2em; display: flex; justify-content: space-between;}
                        .quote-display-body { margin-top: 50px;}
                        .quote-display-items-title {margin-top: 50px; padding-bottom: 5px;}
                        .quote-info { display: flex; justify-content: space-between; }
                        .bill-to, .remit-to { width: 49%; text-align: left; }
                        .company-logo-image {max-width: 100px !important;}
                        h4 {font-size: 1.5em; margin-bottom: 0px !important;}
    
                        .bill-to div, .remit-to div { white-space: nowrap;}
                        .company-info div, .company-logo div { white-space: nowrap; }

                        .bill-to, .remit-to { align-self: flex-start; } 
                        .bill-to .underline, .remit-to .underline { text-decoration: underline;} 
                        .company-info, .company-logo { align-self: flex-start; } 
                        .company-info h2 {margin-bottom: 5px;}
                        .company-info p {margin: 0px;}
    
                        .totals {display: flex; justify-content: space-between; margin-top: 20px }
                        .totals-row {display: flex; justify-content: space-around;}
                        .col-a { }
                        .col-b { }
                        .col-b-1 {margin-end: 10px !important;}
                        .col-b-2 {margin-start: 10px !important;}
                        .totals-label { margin-right: 10px; }
    
                        .terms {
                            margin-top: 1rem !important; 
                            padding-right: 5rem !important; 
                            font-size: smaller !important; 
                        }
                        .terms .row {
                            display: flex !important;
                            flex-direction: row;
                        }
                        .terms .col-1 {
                            flex: 0 0 6% !important; 
                            text-align: right !important; 
                            padding-right: 1rem !important; 
                        }
                        .terms .col-11 {
                            flex: 0 0 94% !important; 
                            text-align: left !important; 
                        }
                        .acceptance {margin-top: 50px}
                        .acceptance h4 {margin-bottom: 0px}
                        .signature-section { display: flex; justify-content: space-around;}
                        .signature-date { margin-top: 30px; text-align: left; }
                        .signature-line, .date-line { padding-right: 10px; }
                        .w-50 {width: 50% !important;}
    
                        /* Table Styles */
                        table { width: 100%; border-collapse: collapse; margin-top: 0px; }
                        th, td { border: 1px solid black; padding: 5px; }
                        @media print {
                            .no-print {
                                display: none;
                            }
                
                        }
                        
                    </style>
                </head>
                <body>
                    ${content}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 500);
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            This quote has changes since you last viewed it.  Review carefully, and contact us if you have questions.
        </Tooltip>
    );

    console.log(quote);

    return (
        <Modal backdrop={noClickAway ? "static" : true} className="quote-display-modal" show={show} onHide={onClose} size="xl" centered>
            <Container fluid className="quote-display-container section-to-print">
                {/* Header with company info and logo */}
                <Modal.Header className="header rounded-0 quote-display-header d-flex flex-column align-items-end">
                    <div className='no-print'>
                        <Button className='mt-0 me-4' onClick={printContent}>Print</Button>
                        <XSquare type="button" size={40} onClick={onClose} />
                    </div>
                    <Modal.Title className='page-title d-flex w-100 justify-content-between'>

                        <div className='font-larger'>
                            {quote.activity.isUpdated ?
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 100, hide: 400 }}
                                    overlay={renderTooltip}
                                >
                                    <Badge pill bg="info" className='hover me-2'>Updated</Badge>
                                </OverlayTrigger>
                                : ""}

                            Quote</div>
                        <div>{quote.services[quote.selectedService]}</div> </Modal.Title>
                    <hr />
                </Modal.Header>
                <div className='quote-display-subheader'>
                    <Row className='subheader-row no-print'>
                        <Col className="company-info" >
                            <h2>{siteInfo.settings.companyName}</h2>
                            <p>{'<123 Street Address, City, State, Zip/Post>'}</p>
                            <p>{'webmaster@x4int.com'}</p>
                            <p>{'<Phone Number>'}</p>
                        </Col>
                        <Col className="company-logo">
                            <Image src={X4Logo} fluid className='company-logo-image' />
                        </Col>
                    </Row>

                    <Row className='quote-info justify-content-between no-screen'>
                        <Col xs={5} className="text-start">
                            <div className='company-info'>
                                <h2>{siteInfo.settings.companyName}</h2>
                                <p>{'<123 Street Address, City, State, Zip/Post>'}</p>
                                <p>{'webmaster@x4int.com'}</p>
                                <p>{'<Phone Number>'}</p>
                            </div>

                        </Col>
                        <Col xs={7} lg={5} className="text-start">
                            <div className='company-logo ms-3'>
                                <Image src={X4Logo} fluid className='company-logo-image' />
                            </div>
                        </Col>
                    </Row>
                </div>


                {/* Quote Information */}
                <Modal.Body className="quote-display-body">
                    <Row className='quote-info justify-content-between'>
                        <Col xs={5} className="text-start">
                            <div className='bill-to'>
                                <strong className='underline'>BILL TO</strong>
                                <div>
                                    <strong>Name:</strong>
                                    <span className='ps-2'>{quote.toName}</span>
                                </div>
                                <div>
                                    <strong>Company:</strong>
                                    <span className='ps-2'>{quote.toCompany}</span>
                                </div>
                                <div>
                                    <strong>Email:</strong>
                                    <span className='ps-2'>{quote.toEmail}</span>
                                </div>
                                <div>
                                    <strong>Phone:</strong>
                                    <span className='ps-2'>{quote.toMobileNumber}</span>
                                </div>
                            </div>

                        </Col>
                        <Col xs={7} lg={5} className="text-start">
                            <div className='remit-to ms-3'>
                                <strong className='underline'>REMIT TO</strong>
                                <div>
                                    <strong>Quote ID:</strong>
                                    <span className='ps-2'>{quote.id}</span>
                                </div>
                                <div>
                                    <strong>Date:</strong>
                                    <span className='ps-2'>{quote.date}</span>
                                </div>
                                <div>
                                    <strong>Submitted by:</strong>
                                    <span className='ps-2'>{quote.fromName}</span>
                                </div>
                                <div>
                                    <strong>Valid For:</strong>
                                    <span className='ps-2'>{quote.validityDurationDays} Days, till {quote.validTillDate}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>


                    {/* Table for Quote Details */}
                    <h4 className='quote-display-items-title'>Service Details</h4>
                    <Table striped bordered hover className='quote-display-items'>
                        <thead>
                            <tr>
                                <th>Quantity</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Cost</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableRows()}
                        </tbody>
                    </Table>

                    {/* Totals and Terms */}
                    <Row className="totals justify-content-between">
                        <Col xs={5} className='col-a'>
                            <p className='font-larger'><strong>Thank you for your business!</strong></p>
                            {/* ... Terms & Instructions */}
                        </Col>

                        <Col xs={6} lg={4} className="text-start justify-content-end col-b">
                            <Row className='totals-row'>
                                <Col className='col-b-1'>
                                    <div className='ms-5'>
                                        <div>
                                            <strong>Subtotal: &nbsp;</strong>
                                        </div>
                                        <div>
                                            <strong>Tax: &nbsp;</strong>
                                        </div>
                                        <div>
                                            <strong>Total: &nbsp;</strong>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-b-2'>
                                    <div className=''>
                                        <div>
                                            <span className=''>${roundTo(quote.amount, 2, true)}</span>
                                        </div>
                                        <div>
                                            <span className=''>${roundTo(quote.tax, 2, true)}</span>
                                        </div>
                                        <div>
                                            <span className=''>${roundTo(quote.total, 2, true)}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr />
                    {/* JOB NOTES SECTION */}
                    <h4>Job Notes</h4>
                    {showJobNotes ?
                        <div className='terms font-smaller pe-5 ms-0 ps-0'>
                            {!Object.values(quote.jobNotes).length ? <i>No job notes yet.</i> : <></>}
                            {Object.values(quote.jobNotes).map(({ date, note, name }, jobNumber) => {
                                return (
                                    <Row key={jobNumber}>
                                        <Col xs={1} className='text-end'>
                                            <p>{jobNumber + 1}.</p>
                                        </Col>
                                        <Col xs={11} className='mb-1'>
                                            <p>
                                                <strong>{date}: </strong> {note} <i><small><b className='color-darkblue'>By: {name}</b></small></i>
                                            </p>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                        : <Button onClick={() => setShowJobNotes(true)}>Show Job Notes</Button>}

                    <hr />

                    <div className='terms mt-3 font-smaller pe-5 ms-0 ps-0'>
                        <h4>Terms & Conditions</h4>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>1.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Scope of Work: </strong> {quote.services[quote.selectedService]} services as detailed above. Any changes to the scope of work must be agreed upon in writing by both parties.
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>2.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Payment Terms: </strong> A deposit of 50% is required to secure the services for the event date(s). The remaining balance is due 10 days prior to the event execution. Late payments are subject to interest at the rate of 1.5% per month on the outstanding balance. A 4% credit card processing fee will be assessed for all card payments. Checks accepted, contact us for information.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>3.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Cancellation Policy: </strong> Cancellations must be made in writing. If cancellation occurs 30 days after acceptance of this agreement, the deposit is forfeited. Cancellations made more than 30 days prior to the acceptance of this agreement will result in a full refund of the deposit.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>4.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Equipment: </strong> All audiovisual equipment provided remains the property of {siteInfo.settings.companyName}. The client is responsible for any damage or loss of equipment due to negligence or misuse while in the client's possession.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>5.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Subcontractors: </strong> {siteInfo.settings.companyName} reserves the right to employ subcontractors for any portion of the work. All subcontractors will be bound by the terms of this agreement.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>6.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Intellectual Property: </strong> Any intellectual property provided by the client remains the property of the client. Likewise, any intellectual property, including but not limited to designs, concepts, and presentations, developed by {siteInfo.settings.companyName} in the provision of services is the property of {siteInfo.settings.companyName} unless otherwise agreed upon.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>7.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Liability: </strong> {siteInfo.settings.companyName} shall not be liable for any indirect, special, or consequential damages arising out of this agreement. The client agrees to indemnify and hold {siteInfo.settings.companyName} harmless from any claims, damages, or expenses arising from the client's activities.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>8.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Force Majeure: </strong> Neither party shall be liable for delays or non-performance resulting from circumstances or causes beyond its reasonable control, including, but not limited to, acts of God, war, government action, terrorism, natural disaster, or utility outages.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>9.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Dispute Resolution: </strong> Any disputes arising out of this agreement shall first be addressed through good-faith negotiations. If a resolution cannot be reached, disputes shall be settled by arbitration in accordance with the rules of the American Arbitration Association.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={1} className='text-end'>
                                <p>10.</p>
                            </Col>
                            <Col xs={11} className='mb-2'>
                                <p>
                                    <strong>Acceptance: </strong>Acceptance of the quote, electronically or otherwise, signifies acceptance of these terms and conditions, which form a part of the agreement between the client and {siteInfo.settings.companyName}.
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <div className='no-screen acceptance'>
                        <h4 className=''>Acceptance</h4>
                        <div className="signature-section d-flex justify-content-around mt-0">
                            <div className='w-50 bold'>
                                <div md={4} className="signature-line">
                                    <p>Signature:</p>
                                    <hr />
                                </div>
                                <div md={4} className="signature-date">
                                    <p>Date:</p>
                                    <hr />
                                </div>
                            </div>
                            <div md={4} className="printed-name w-50 bold">
                                <p>Printed Name:</p>
                                <hr />
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Container>

            {/* Footer */}
            <Modal.Footer className="quote-display-footer rounded-0 column mb-5 pb-5">

                <span className=''>By clicking the "Accept" button, you agree to the terms and conditions of this quote and will be legally bound by this digital signature.</span>
                <div className='d-flex align-content-center'>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <Button className='mt-0 ms-3' variant="primary" onClick={() => onAccept(quote)}>
                        Accept Quote
                    </Button>
                </div>

            </Modal.Footer>
            <div>&nbsp;</div>
        </Modal>
    );
};

export default QuoteDisplay;


