import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { UserProvider } from "./context/UserContext"

import HomePage from "./pages/home/HomePage";
import AboutPage from "./pages/about/AboutPage";
import ServicesPage from "./pages/services/ServicesPage";
import ContactPage from "./pages/contact/ContactPage";
import Header from "./pages/header/Header";
import Footer from "./pages/footer/Footer";
import RegisterPage from "./pages/auth/CreateAccountPage";
import LoginPage from "./pages/auth/LoginPage";
import AccountPage from "./pages/protected/AccountPage";
import ProtectedRoute from "./pages/auth/ProtectedRoute";


import "./App.css"

//import Parse from "parse/dist/parse.min.js";
import LogoutPage from "./pages/auth/LogoutPage";
import SingleOrderDisplay from "./pages/protected/account/SingleOrderDisplay";
import SingleQuoteDisplay from "./pages/protected/account/SingleQuoteDisplay";
import TestimonialsPage from "./pages/testimonials/TestimonialsPage";
import PasswordReset from "./pages/auth/PasswordReset";

//TODO:  Add create a customer ability and add to "Create a quote"
//TODO:  Make customer testamonial submission page in portal and testamonial page.
//TODO:  Create support ticket system


function App() {


  return (
    <UserProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/testimonials" element={<TestimonialsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/account" element={<ProtectedRoute element={<AccountPage />} />} />
          <Route path="/account/quote/*" element={<ProtectedRoute element={<SingleQuoteDisplay />} />} />
          <Route path="/account/order/*" element={<ProtectedRoute element={<SingleOrderDisplay />} />} />
          <Route path="/home" element={<ProtectedRoute element={<AccountPage />} />} />
          <Route path="/users" element={<ProtectedRoute element={<AccountPage />} />} />
          <Route path="/profile" element={<ProtectedRoute element={<AccountPage />} />} />
          <Route path="/quotes" element={<ProtectedRoute element={<AccountPage />} />} />
          <Route path="/orders" element={<ProtectedRoute element={<AccountPage />} />} />
          <Route path="/invoices" element={<ProtectedRoute element={<AccountPage />} />} />
          <Route path="/payments" element={<ProtectedRoute element={<AccountPage />} />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/passwordreset" element={<PasswordReset />} />
        </Routes>
        <Footer />
      </Router>
    </UserProvider>

  );
}

export default App;
