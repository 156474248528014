import React, { useContext } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import './Home.css';  // Assuming you have a Home.css for custom styles
import UserContext from '../../context/UserContext';

import ProjectsImage from "../../images/projects.png"
import ServicesImage from "../../images/services.png"
import TestimonalsImage from "../../images/testimonials.png"
import { useNavigate } from 'react-router-dom';

function HomePage() {
    const { siteInfo } = useContext(UserContext);
    const navigate = useNavigate();
    return (
        <div>
            {/* Main Visual as Background */}
            <div className="main-visual">
                {/* Content Overlay */}
                <div className="content-overlay" style={{ marginTop: "50vh" }}>
                    {/* Introduction Section */}
                    <Container className="intro-section">
                        <Row>
                            <Col>
                                <h1 style={{ textAlign: "center" }}>Welcome to {siteInfo.settings.companyName}</h1>
                                <p style={{ textAlign: "left" }}>
                                    We have over twenty years of excellence in Audio Visual services uniting with innovative event technology to elevate your events. Specializing in AV consulting, production, and management, we're dedicated to transforming both local and national events into memorable experiences.</p><p style={{ textAlign: "left" }}>

                                    Our expertise, deeply rooted in serving a diverse array of events across the continental USA, equips us with unique insights and capabilities. While our primary focus is on American soil, our extensive experience and professional network also extend globally, making us a versatile choice for events anywhere in the world.</p><p style={{ textAlign: "left" }}>

                                    At {siteInfo.settings.companyName}, we believe in creating more than just events; we build lasting partnerships, ensuring every aspect of your AV needs is meticulously catered to. Our commitment to excellence and attention to detail is unwavering, whether we're orchestrating a local corporate gathering or supporting a large-scale international conference.</p><p style={{ textAlign: "left" }}>

                                    Partner with us to see how our passion for perfection and technical prowess can make your vision a reality. {siteInfo.settings.companyName} is not just about providing services; it's about creating an impactful, seamless, and unforgettable experience for every client, near or far.
                                </p>
                            </Col>
                        </Row>
                    </Container>

                    {/* Highlights Section */}
                    <Container className="highlights-section">
                        <Row>
                            <Col md={4} className="service-highlight" style={{ textAlign: "left" }}>
                                <Card style={{ width: '18rem' }}>
                                    <Card.Img variant="top" src={ServicesImage} />
                                    <Card.Body>
                                        <Card.Title>Our Services</Card.Title>
                                        <Card.Text>
                                            Discover our wide range of services tailored to your needs.
                                        </Card.Text>
                                        <Button variant="primary">Learn More</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4} className="project-highlight" style={{ textAlign: "left" }}>
                                <Card style={{ width: '18rem' }}>
                                    <Card.Img variant="top" src={ProjectsImage} />
                                    <Card.Body>
                                        <Card.Title>Recent Projects</Card.Title>
                                        <Card.Text>
                                            Explore our portfolio of recent, successful AV projects.
                                        </Card.Text>
                                        <Button variant="primary">View Projects</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4} className="testimonial-highlight" style={{ textAlign: "left" }}>
                                <Card style={{ width: '18rem' }}>
                                    <Card.Img variant="top" src={TestimonalsImage} />
                                    <Card.Body>
                                        <Card.Title>Client Testimonials</Card.Title>
                                        <Card.Text>
                                            Hear what our clients have to say about our exceptional services.
                                        </Card.Text>
                                        <Button onClick={() => navigate("/testimonials")} variant="primary">Read Testimonials</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
