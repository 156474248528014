import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import useAuth from "../../pages/auth/useAuth";


function LogoutPage() {
    const [logoutCalled, setLogoutCalled] = useState(false);
    const { logout, loading } = useAuth();


    useEffect(() => {
        let mounted = true;
        if (mounted && !logoutCalled) {
            logout();
            setLogoutCalled(true);
        }
        return () => mounted = false;
    }, [logoutCalled, logout]);

    return (

        <React.Fragment>
            {
                loading ? <Container className="login-container">
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            <div className='form-header-login rounded-top-3 p-2 mx-auto'>
                                <h2 className='text-dark'>Logout in progress</h2>
                            </div>
                            <Form className='form-container-login mt-0 rounded-top-0 mx-auto'>
                                <Form.Group className="mb-3" controlId="formBasicUsername">
                                    <Form.Label className='form-label'>Please wait...</Form.Label>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container> : <Navigate replace to="/" />
            } </React.Fragment>

    )
}

export default LogoutPage