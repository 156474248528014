import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { List } from 'react-bootstrap-icons';
import useMediaQuery from '../../library/useMediaQuery';
import { toTitleCase } from '../../library/library';

function AccountPageSideBar({ onActiveItemChange, currentUser, siteInfo }) {
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const isMatch = useMediaQuery('(max-width: 768px)');

    // Determine active item based on current URL
    const activeItem = location.pathname.split('/')[1] || 'home';

    useEffect(() => {
        let mounted = true;
        if (mounted && !isMatch && isOpen === false) setIsOpen(true);
        return () => mounted = false;
        // eslint-disable-next-line
    }, [isMatch]);


    const handleNavClick = (item) => {
        navigate(`/${item}`);
    };

    return (
        <div className="sidebar">
            &nbsp; {`${currentUser.firstname || currentUser.username}'s Dashboard`}
            <div className="sidebar-inside">
                <div className='d-flex align-items-center justify-content-start'>
                    <button onClick={() => setIsOpen(!isOpen)} className="hamburger">
                        <List /> {/* Hamburger icon */}
                    </button>
                    {!isOpen ? <div className='ms-2 ms-md-0 show-mobile'>{toTitleCase(activeItem)}</div> : <></>}
                </div>
                {isOpen && (
                    <div>
                        {currentUser.role === "Admin" ?
                            <Nav className="flex-column" activeKey={activeItem} defaultActiveKey="home">
                                {['home', 'profile', 'users', 'quotes', 'orders', 'payments'].map(item => (
                                    <Nav.Link
                                        key={item}
                                        eventKey={item}
                                        onClick={() => handleNavClick(item)}
                                    >
                                        {item.charAt(0).toUpperCase() + item.slice(1)}
                                    </Nav.Link>
                                ))}
                            </Nav>
                            :
                            <Nav className="flex-column" activeKey={activeItem} defaultActiveKey="home">
                                {['home', 'profile', 'quotes', 'orders', 'payments'].map(item => (
                                    <Nav.Link
                                        key={item}
                                        eventKey={item}
                                        onClick={() => handleNavClick(item)}
                                    >
                                        {item.charAt(0).toUpperCase() + item.slice(1)}
                                    </Nav.Link>
                                ))}
                            </Nav>
                        }

                    </div>
                )}


            </div>
        </div>

    );
}

export default AccountPageSideBar;
