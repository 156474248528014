import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

import "./ServicesModal.css"
import QuoteRequest from '../../classes/QuoteRequest';
import { isset } from '../../library/library';

function ServicesModal({ selectedService, showModal, handleCloseModal, handleModalOnChange, modalData, setModalData, handleModalSubmit, messages, currentUser }) {
    const services = new QuoteRequest();
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (isset(currentUser)) {
                setModalData((prev) => {
                    return {
                        ...prev,
                        name: prev.name ? prev.name : currentUser.name,
                        company: prev.company ? prev.company : currentUser.company,
                        mobileNumber: prev.mobileNumber ? prev.mobileNumber : currentUser.mobileNumber,
                        email: prev.email ? prev.email : currentUser.email
                    }
                })
            };
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [currentUser]);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleModalSubmit(event);
        }
        setValidated(true);
    };

    const renderServiceSpecificQuestions = () => {
        switch (selectedService) {
            case 'AVProduction':
                return (
                    <>
                        <Row>
                            <Col sm={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{services.getServiceDetails(selectedService, 1).question}</Form.Label>
                                    <Form.Control required type="text" placeholder={services.getServiceDetails(selectedService, 1).placeHolder} name={services.getServiceDetails(selectedService, 1).id} onChange={(e) => { handleModalOnChange(e) }} />
                                    <Form.Control.Feedback type="invalid">
                                        This answer is required.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{services.getServiceDetails(selectedService, 2).question}</Form.Label>
                                    <Form.Control required type="number" placeholder={services.getServiceDetails(selectedService, 2).placeHolder} name={services.getServiceDetails(selectedService, 2).id} onChange={(e) => { handleModalOnChange(e) }} />
                                    <Form.Control.Feedback type="invalid">
                                        This answer is required.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>


                        <Form.Group className="mb-3">
                            <Form.Label>{services.getServiceDetails(selectedService, 3).question}</Form.Label>
                            <Form.Control required type="text" placeholder={services.getServiceDetails(selectedService, 3).placeHolder} name={services.getServiceDetails(selectedService, 3).id} onChange={(e) => { handleModalOnChange(e) }} />
                            <Form.Control.Feedback type="invalid">
                                This answer is required.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </>
                );
            case 'Consulting':
                return (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>{services.getServiceDetails(selectedService, 1).question}</Form.Label>
                            <Form.Control required type="text" as="textarea" rows={3} placeholder={services.getServiceDetails(selectedService, 1).placeHolder} name={services.getServiceDetails(selectedService, 1).id} onChange={(e) => { handleModalOnChange(e) }} />
                            <Form.Control.Feedback type="invalid">
                                This answer is required.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{services.getServiceDetails(selectedService, 2).question}</Form.Label>
                            <Form.Control required type="text" as="textarea" rows={3} placeholder={services.getServiceDetails(selectedService, 2).placeHolder} name={services.getServiceDetails(selectedService, 2).id} onChange={(e) => { handleModalOnChange(e) }} />
                            <Form.Control.Feedback type="invalid">
                                This answer is required.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{services.getServiceDetails(selectedService, 3).question}</Form.Label>
                            <Form.Control required type="text" as="textarea" rows={3} placeholder={services.getServiceDetails(selectedService, 3).placeHolder} name={services.getServiceDetails(selectedService, 3).id} onChange={(e) => { handleModalOnChange(e) }} />
                            <Form.Control.Feedback type="invalid">
                                This answer is required.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </>
                );
            case 'OnSiteManagement':
                return (
                    <>

                        <Row>
                            <Col sm={9}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{services.getServiceDetails(selectedService, 1).question}</Form.Label>
                                    <Form.Control required type="text" as="textarea" rows={3} placeholder="Tell us a bit about your event. e.g., Needs, Full-scale event planning, Day-of coordination" name="eventScope" onChange={(e) => { handleModalOnChange(e) }} />
                                    <Form.Control.Feedback type="invalid">
                                        This answer is required.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{services.getServiceDetails(selectedService, 2).question}</Form.Label>
                                    <Form.Control required type="number" placeholder="# of Attendees" name="eventSize" onChange={(e) => { handleModalOnChange(e) }} />
                                    <Form.Control.Feedback type="invalid">
                                        This answer is required.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{services.getServiceDetails(selectedService, 3).question}</Form.Label>
                                    <Form.Control required type="text" placeholder="City, State, Venue Name" name="eventLocation" onChange={(e) => { handleModalOnChange(e) }} />
                                    <Form.Control.Feedback type="invalid">
                                        This answer is required.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                );
            default:
                return (
                    <></>
                );
        }

    };

    return (
        <Modal size="xl" className='services-modal-container' show={showModal} onHide={handleCloseModal}>
            <Modal.Header className='modal-header align-items-start' closeVariant='white' closeButton>
                <div className='d-inline mx-auto w-100'>
                    <Modal.Title>Request a Quote</Modal.Title>
                    <h5 className=''>{services.services[selectedService]}</h5>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>First & Last Name</Form.Label>
                                <Form.Control required type="text" name="name" defaultValue={
                                    isset(currentUser) && currentUser?.firstname && currentUser?.lastname ? currentUser.name : ""
                                } placeholder="Enter your name" onChange={(e) => { handleModalOnChange(e) }} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide your first and last name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control required type="text" name="company" defaultValue={
                                    isset(currentUser) && currentUser?.company ? currentUser.company : ""
                                } placeholder="Company Name" onChange={(e) => { handleModalOnChange(e) }} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide your company name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Mobile Contact Number</Form.Label>
                                <Form.Control required type="tel" name="mobileNumber" defaultValue={
                                    isset(currentUser) && currentUser?.mobileNumber ? currentUser.mobileNumber : ""
                                } placeholder="Mobile Number" onChange={(e) => { handleModalOnChange(e) }} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide your phone number.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Text Messaging Acceptance</Form.Label>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    label={modalData.acceptTexts ? "Yes, Please text me." : "No! I hate texting."}
                                    defaultChecked={modalData.acceptTexts}
                                    name="acceptTexts"
                                    onChange={(e) => { handleModalOnChange(e) }}
                                />
                            </Form.Group >
                        </Col>
                    </Row>

                    <Row className='align-items-end'>
                        <Col sm={8}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control required type="email" name="email" defaultValue={
                                    isset(currentUser) && currentUser?.email ? currentUser.email : ""
                                } placeholder="Enter email" onChange={(e) => { handleModalOnChange(e) }} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Anticipated Month and Year of Service</Form.Label>
                                <Form.Control required type="month" name="serviceMonth" onChange={(e) => { handleModalOnChange(e) }} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide the month and year of anticipated date of service.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    {renderServiceSpecificQuestions()}
                    <Modal.Footer className='modal-footer mx-auto'>
                        <Form.Label className={`pe-2 form-feedback${messages.success ? "" : "-error"}`}>{isset(messages) ? Object.values(messages.form).map((message, index) => {
                            return <div key={index}>{message}</div>
                        }) : <></>}</Form.Label>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>

                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ServicesModal;
