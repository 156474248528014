import React from 'react';
import Parse from 'parse';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import useAuth from "../../pages/auth/useAuth";
import { PersonCircle, BoxArrowRight } from 'react-bootstrap-icons'; // Import icons from react-bootstrap-icons
import { useUser } from '../../context/UserContext';
import LogoSmall from '../../images/Logo.jpg'

function Header() {
    const { currentUser } = useAuth();
    const { siteInfo } = useUser();

    // console.log("HEADER-currentUser", currentUser, "HEADER-siteInfo", siteInfo);

    return (
        <Navbar bg="light" expand="lg" className='navbar' fixed="top">
            <div className='text-danger bold ms-2' >{currentUser && currentUser.role === "Admin" ? "ADMIN" : ""}</div>
            <Container>
                <Navbar.Brand href="/">
                    <Image className="logo-small" src={LogoSmall} />
                    {siteInfo.settings.companyName.substring(3)}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                        <Nav.Link href="/services">Services</Nav.Link>
                        <Nav.Link href="/contact">Contact</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href="/account"><PersonCircle /> Account</Nav.Link>
                        {Parse.User.current() ? <Nav.Link href="/logout"><BoxArrowRight /> Logout</Nav.Link> : <Nav.Link href="/login"><PersonCircle /> Login</Nav.Link>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
