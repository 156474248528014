import React from 'react';
import { Container, Row, Col, Image, Card, Accordion } from 'react-bootstrap';
import MapImage from '../../images/world-map.png';
//import TeamMemberImage from '../../images/team-member.jpg'; // Example path
import TechBackgroundImage from '../../images/tech-background.png'; // Generated image for visual interest

import RespectImage from '../../images/respect.png';
import QualityAVImage from '../../images/quality-av.png';
import ServiceImage from '../../images/service.png';
import SatisifactionImage from '../../images/satisifaction.png';
import PartnershipImage from '../../images/partnership.png';
import MattImage from '../../images/Matt_Toon_Headshot_2.png';
import PaulImage from '../../images/Paul_Toon_Headshot_2.png';

import './AboutPage.css';
import TestimonialsPage from '../testimonials/TestimonialsPage';

function AboutPage() {
    return (
        <Container fluid className="about-container px-0" style={{ backgroundImage: `url(${TechBackgroundImage})` }}>
            <div className='binder'>
                {/* Company History with Tech Background */}
                <Row className="company-history-section" >
                    <Col>
                        <h2 className="text-center text-light">Our History</h2>
                        <Accordion defaultActiveKey="2" className='mx-auto'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Late 90s - Early 2000s: The Foundational Years</Accordion.Header>
                                <Accordion.Body>
                                    At the turn of the century, the seeds of X4 International were being sown. Our founders, each making their mark in the event industry, were immersed in the dynamic worlds of Audio Visual, hospitality, and food and beverage. This era was characterized by a relentless pursuit of expertise and a deepening understanding of what makes events truly memorable. Our founders were not just building their careers; they were becoming seasoned professionals, accumulating a wealth of experiences that would later become the cornerstone of X4 International.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2016: The Birth of X4 International</Accordion.Header>
                                <Accordion.Body>
                                    In 2016, the journey of X4 International officially began. With a vision to redefine event consulting, our founders pooled their decades of experience to launch a company that would soon become a beacon of innovation in the industry. Initially focusing on business consulting, X4 quickly established a reputation for insightful strategies and bespoke solutions. We were more than consultants; we were partners to our clients, dedicated to elevating their events to new heights.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>2023: A New Era in AV and Event Technology</Accordion.Header>
                                <Accordion.Body>
                                    The year 2023 marked a significant leap for X4 International as we launched our Audio Visual and Event Technology production company. This expansion was a natural progression of our journey, intertwining our consulting expertise with hands-on production capabilities. Today, we stand at the forefront of event technology, offering a full spectrum of services from conceptualization to execution. Our commitment to innovation and excellence continues to drive us forward, as we redefine the landscape of event technology and consulting.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>


                {/* Mission and Values */}
                <Row className='justify-content-center px-0 mx-0 pt-5 mission-row rounded-1'>
                    <Col className='px-0 mission-column'>
                        <Card className="my-2 rounded-1">
                            <Card.Body className='rounded-1'>
                                <Card.Title className='rounded-1'>Mission</Card.Title>
                                <Card.Text className='rounded-1'>
                                    At X4 International, our mission is to deliver unparalleled event experiences through exceptional audiovisual services. We are committed to elevating every event with the finest sound and video quality, ensuring a smooth and memorable experience for our clients. Excellence in customer service and technical expertise are the hallmarks of our approach, as we strive to exceed expectations in every aspect of AV production. Our goal is to provide top-tier event technology solutions that align with our clients' visions and budgets, transforming ordinary events into extraordinary experiences.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <h2 className="text-center text-light mt-5">Core Values</h2>
                    <Col md={6} lg={4} className='core-column'>
                        <Card>
                            <Card.Header> <Image src={SatisifactionImage} className='core-images' /></Card.Header>
                            <Card.Body>
                                <Card.Title>Customer Satisfaction</Card.Title>
                                <Card.Text>
                                    At the heart of everything we do lies our commitment to our clients' happiness and success. We believe in building lasting relationships based on trust and mutual respect.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='core-column'>
                        <Card>
                            <Card.Header> <Image src={ServiceImage} className='core-images' /></Card.Header>
                            <Card.Body>
                                <Card.Title>Exemplary Service</Card.Title>
                                <Card.Text>
                                    We set the standard for service in the AV industry, offering personalized attention, responsiveness, and a willingness to go above and beyond in every project.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='core-column'>
                        <Card>
                            <Card.Header><Image src={QualityAVImage} className='core-images' /> </Card.Header>
                            <Card.Body>
                                <Card.Title>Quality Audio and Video</Card.Title>
                                <Card.Text>
                                    Our passion for superior sound and visual experiences drives us to use only the best technology and techniques, ensuring each event we handle is a sensory delight.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='core-column'>
                        <Card>
                            <Card.Header> <Image src={RespectImage} className='core-images' /></Card.Header>
                            <Card.Body>
                                <Card.Title>Respect and Professionalism</Card.Title>
                                <Card.Text>
                                    We foster a culture of respect, both within our team and in our interactions with clients. Professionalism is our promise, from the first consultation to the final applause.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='core-column'>
                        <Card>
                            <Card.Header> <Image src={PartnershipImage} className='core-images' /></Card.Header>
                            <Card.Body>
                                <Card.Title>Partnership and Success</Card.Title>
                                <Card.Text>
                                    Our success is measured by the success of our clients' events. We work hand-in-hand with our clients, understanding their goals, and aligning our efforts to ensure these are met or exceeded.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>


                {/* Team Profiles */}
                <Row className="team-profiles py-5">
                    <h2 className="text-center text-light mt-5">Leadership Team</h2>

                    <Col md={6} lg={6} className='team-column'>
                        <Card className='rounded-3'>
                            <Card.Header className='rounded-3'> <Image src={PaulImage} className='team-images rounded-top-3' fluid /></Card.Header>
                            <Card.Body className='rounded-bottom-3'>
                                <Card.Title>Paul Bhatt</Card.Title>
                                <Card.Text className='rounded-bottom-3'>
                                    Founder | Sales
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={6} className='team-column'>
                        <Card className='rounded-3'>
                            <Card.Header className='rounded-3'> <Image src={MattImage} className='team-images rounded-top-3' fluid /></Card.Header>
                            <Card.Body className='rounded-bottom-3'>
                                <Card.Title>Matt Perrine</Card.Title>
                                <Card.Text className='rounded-bottom-3'>
                                    Founder | Operations
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* ... Additional team members ... */}
                </Row>


                {/* Testamonials */}
                <div className="">

                    <TestimonialsPage standalone={false} />

                </div>
                {/* Global Reach Map */}
                <Row className="global-reach py-5">
                    <h2 className="text-white">Global Reach</h2>
                    <Col>
                        <Image src={MapImage} fluid />
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default AboutPage;
