import { inString, is_string, isset, objectLen } from "../library/library";

/**
 * Messages Class
 * @param {string} type The type of message to set. One of "request", "form", "fields".
 * @param {string} message The message to set. If using type "fields", message must be pipe "|" delimited with the field name first. e.g. email|Must use proper email address.
 * @prop {boolean} isset
 */
class Messages {
    isset = false;
    request = {};
    form = {};
    fields = {};
    success = true;
    error = "";
    #types = ["request", "form", "fields"];

    /**
     * 
     * @param {string} type The type of message to set. One of "request", "form", "fields".
     * @param {string} message The message to set. If using type "fields", message must be pipe "|" delimited with the field name first. e.g. email|Must use proper email address.
     * @returns {object} A new Messages Object
     */
    constructor(type, message) {
        if (!isset(type) && !isset(message)) return this;
        this.setMessages(type, message);
    }


    /**
     * Sets the message within the class. Will push on additional messages for "request" and "form" types, but not for "fields".
     * @param {string} type The type of message to set. One of "request", "form", "fields".
     * @param {string} message The message to set. If using type "fields", message must be pipe "|" delimited with the field name first. e.g. email|Must use proper email address.
     */
    setMessages(type, message) {
        if (!this.#types.includes(type)) {
            this.error = "Type not found.";
            throw new Error(this.error);
        }
        if (!is_string(message)) {
            this.error = "Parameter 2 'message' must be a string.";
            throw new Error(this.error);
        }
        switch (type) {
            case "request":
            case "form":
                this[type][objectLen(this.request)] = message;
                break;
            case "fields":
                if (!inString(message, "|")) {
                    this.error = "Message must be pipe delimited with a leading field name when using type 'fields'.";
                    throw new Error(this.error);
                }
                this[type][message.split("|")[0]] = message.split("|")[1];
                break;

            default:
                this.error = `Type not found. Must be ${this.#types.toString()}`;
                throw new Error(this.error);
        }
        this.isset = true;
    }







}

export default Messages;