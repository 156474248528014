import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';

import "../../services/ServicesModal.css"
import QuoteRequest from '../../../classes/QuoteRequest';
import ButtonToolTip from '../../../library/components/ButtonToolTip';

/**
 * 
 * @param {{quoteRequest:QuoteRequest}} param0 
 * @returns 
 */
function QuoteRequestAdminView({ quoteRequest, show, handleClose, handleAccept, handleDecline, preQuotes }) {
    const services = new QuoteRequest();
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setDisabledButton(false);
        }
        return () => mounted = false;
    }, [quoteRequest, show]);

    if (!quoteRequest) {
        return (
            <></>
        );
    }

    const renderServiceSpecificQuestions = () => {
        switch (quoteRequest.selectedService) {
            case 'AVProduction':
            case 'Consulting':
            case 'OnSiteManagement':
                return (
                    <>
                        <Row>
                            <Col sm={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-larger pb-0 mb-0'>{services.getServiceDetails(quoteRequest.selectedService, 1).question}</Form.Label>
                                    <p className=''>{quoteRequest.answerOne}</p>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-larger pb-0 mb-0'>{services.getServiceDetails(quoteRequest.selectedService, 2).question}</Form.Label>
                                    <p className=''>{quoteRequest.answerTwo}</p>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3">
                            <Form.Label className='text-larger pb-0 mb-0'>{services.getServiceDetails(quoteRequest.selectedService, 3).question}</Form.Label>
                            <p className=''>{quoteRequest.answerThree}</p>
                        </Form.Group>
                    </>
                );

            default:
                return (
                    <></>
                );
        }

    };

    return (
        <Modal size="xl" className='services-modal-container' show={show} onHide={handleClose}>
            <Modal.Header className='modal-header align-items-start' closeVariant='white' closeButton>
                <div className='d-inline mx-auto w-100'>
                    <Modal.Title>Quote Request</Modal.Title>
                    <h5 className='text-primary bold'>{services.services[quoteRequest.selectedService]}</h5>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate >
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className='mb-0'>First & Last Name</Form.Label>
                                <p className='mt-0'>{quoteRequest.name}</p>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className='mb-0'>Company Name</Form.Label>
                                <p className='mt-0'>{quoteRequest.company}</p>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className='mb-0'>Mobile Contact Number</Form.Label>
                                <p className='mt-0 mb-0 pb-0'>{quoteRequest.mobileNumber}</p>
                                <i className={quoteRequest.acceptTexts ? "text-success" : "text-danger"}>{quoteRequest.acceptTexts ? "Customer accepts texts." : "This customer does not accept texts."}</i>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='align-items-end'>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className='mb-0'>Email Address</Form.Label>
                                <p className='mt-0'>{quoteRequest.email}</p>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Anticipated Month and Year of Service</Form.Label>
                                <p className='mt-0'>{quoteRequest.serviceMonth}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />
                    {renderServiceSpecificQuestions()}
                    <Modal.Footer className='modal-footer mx-auto justify-content-between'>

                        {quoteRequest.isNew ? <ButtonToolTip disabled={disabledButton} onClick={() => { setDisabledButton(true); handleDecline() }} id={123459} toolTipText={"This will cancel the quote request - you will be given a chance to notify the customer."} variant="danger">Decline</ButtonToolTip> : <></>}

                        {quoteRequest.isNew ? <></> :
                            <span className='text-warning'>This Quote Request has already been converted to a Pre-Quote with ID: {preQuotes.find(preQuote => preQuote.quoteRequestId === quoteRequest.objectId)?.objectId} </span>}
                        <ButtonToolTip disabled={disabledButton} onClick={() => { setDisabledButton(true); handleClose() }} id={984568}
                            toolTipText={quoteRequest.isNew ? "Click here if you're lazy." : "Nice Job."} variant="secondary">Close</ButtonToolTip>



                        {quoteRequest.isNew ? <ButtonToolTip disabled={disabledButton} onClick={() => { setDisabledButton(true); handleAccept() }} id={398746} toolTipText={"This will convert to a pre-quote and start the completion process. The customer will not be sent the quote till it's completed, not upon clicking accept."} variant="success">Accept</ButtonToolTip> : <></>}

                    </Modal.Footer>

                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default QuoteRequestAdminView;
