import { MyDate, formatNumberToCurrency, is_string, isset } from "../library/library";
import Quote from "./Quote";


class PreQuote extends Quote {
    preQuoteId = this.objectId;
    quoteRequestId = "";
    is = "PREQUOTE";



    constructor(preQuoteData, ready = true) {
        super(preQuoteData);
        this.ready = ready;
        if (!isset(preQuoteData)) {
            this.isset = false;
            return this;
        };
        this.isset = true;
        this.quoteRequestId = preQuoteData.quoteRequestId?.id || preQuoteData.quoteRequestId || "";
        if (this.serviceStartDate && is_string(this.serviceStartDate)) this.serviceStartDate = new Date(this.serviceStartDate);
        if (this.serviceEndDate && is_string(this.serviceEndDate)) this.serviceEndDate = new Date(this.serviceEndDate);
        this.activity.isNew = true;
    }


    getTabulation() {
        return {
            headers: ["Service", "To", "Company", "Email", "Mobile Number", "Amount", "Start Date", "End Date"],
            keys: ["selectedService", "toName", "toCompany", "toEmail", "toMobileNumber", "amount", "serviceStartDate", "serviceEndDate"],
            values: [this.selectedService,
            this.toName,
            this.toCompany,
            this.toEmail,
            this.toMobileNumber,
            formatNumberToCurrency(this.amount),
            this.serviceStartDate ? new MyDate(this.serviceStartDate).toString(false) : "",
            this.serviceStartDate ? new MyDate(this.serviceEndDate).toString(false) : ""
            ]
        }
    }

    getItemsTabulation() {
        return {
            headers: ["Quantity", "Name", "Description", "Cost", "Notes"],
            keys: ["Quantity", "Name", "Description", "Cost", "Notes"],
            values: Object.values(this),
        }
    }

}

export default PreQuote