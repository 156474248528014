import { useState } from 'react';
import Parse from 'parse';
import useLocalStorage from './useLocalStorage';
import { useUser } from '../context/UserContext';
import CountsAdminDash from '../classes/CountsAdminDash';
import Quote from "../classes/Quote";
import PreQuote from "../classes/PreQuote";
import QuoteRequest from '../classes/QuoteRequest';
import { CITYSTATE_LOOKUP_SHEET_ID, FIFTEEN_MINUTES, FIVE_MINUTES, ZIPCODE_LOOKUP_SHEET_ID, is_array, is_object, objectCopy, rehydrateObject } from './library';
import User from '../classes/User';
import { useRef } from 'react';
import Order from '../classes/Order';


/**
 * Custom hook for admin-related operations.
 * @typedef {import('../classes/CountsAdminDash').CountsAdminDash} CountsAdminDash
 * @returns {{
*  users: Array,
*  error: Error | null,
*  roles: Array,
*  counts: CountsAdminDash,
*  getUsers: Function,
*  clearUsers: Function,
*  resetUserPasswordByAdmin: Function,
*  getRoles: Function,
*  clearRoles: Function,
*  changeUserRoleByAdmin: Function,
*  sendEmailAsAdmin: Function,
*  getAdminDashboardCounts: Function
* }}
*/
const useAdmin = () => {
    const { currentUser, userIsValid, setUserIsValid } = useUser();
    const [users, setUsers, clearUsers] = useLocalStorage("allUsersList", [], FIFTEEN_MINUTES);
    const [user, setUser, clearUser] = useLocalStorage("userFetched", new User(), FIVE_MINUTES);
    const [roles, setRoles, clearRoles, rolesAreExpired] = useLocalStorage("roles", [], FIFTEEN_MINUTES);
    const [counts, setCounts, clearCounts, isCountsExpired] = useLocalStorage("adminDashCounts", new CountsAdminDash(), FIVE_MINUTES);
    const [error, setError] = useState(null);
    const [quotes, setQuotes, clearQuotes, quotesAreExpired] = useLocalStorage("quotes", [new Quote()], FIVE_MINUTES);
    const [quoteRequests, setQuoteRequests, clearAdminQuoteRequests, quoteRequestsAreExpired] = useLocalStorage("quoteRequests", [new QuoteRequest()], FIVE_MINUTES);
    const [preQuotes, setPreQuotes, clearPreQuotes, preQuotesAreExpired] = useLocalStorage("PreQuotes", [new PreQuote()], FIVE_MINUTES);
    const [orders, setOrders, clearOrders, ordersAreExpired] = useLocalStorage("orders", [new Order()], FIVE_MINUTES);
    const [ordersClosed, setOrdersClosed, clearOrdersClosed, ordersClosedAreExpired] = useLocalStorage("ordersClosed", [new Order()], FIVE_MINUTES);

    const fetchingGetUsers = useRef(false);
    const fetchingGetRoles = useRef(false);
    const fetchingDashboardCounts = useRef(false);
    const fetchingOpenQuotes = useRef(false);
    const fetchingQuoteRequests = useRef(false);
    const fetchingPreQuoteRequests = useRef(false);
    const fetchingOpenOrders = useRef(false);
    const fetchingClosedOrders = useRef(false);




    //Fetch zipcodes from the published x4 zipcode google sheets
    const fetchZipCodes = async (city, state) => {
        if (!userIsValid) return [];
        try {
            const query = encodeURIComponent(`SELECT A WHERE B = '${city}' AND C = '${state}'`); // Replace A and B with the column letters of city and state
            const url = `https://docs.google.com/spreadsheets/d/${ZIPCODE_LOOKUP_SHEET_ID}/gviz/tq?tq=${query}`;
            const response = await fetch(url);
            const text = await response.text();
            // Extract the JSON part from the response
            const jsonString = text.match(/google\.visualization\.Query\.setResponse\(([\s\S\w]+)\)/)[1];
            const json = JSON.parse(jsonString);
            const rows = json.table.rows;
            const formattedData = rows.map(row => {
                return row.c.map(cell => cell ? cell.v : '');
            });
            return formattedData;
        } catch (error) {
            console.log(error);
            console.error(error);
        }
    };

    //Fetch zipcodes from the published x4 zipcode google sheets
    const fetchCityStateByZipcode = async (zipcode) => {
        if (!userIsValid) return [];
        try {
            console.log("Querying for zipcode:", zipcode);
            // Ensure the query is encoded correctly
            const query = encodeURIComponent(`SELECT B, C WHERE A = ${zipcode}`);
            const url = `https://docs.google.com/spreadsheets/d/${ZIPCODE_LOOKUP_SHEET_ID}/gviz/tq?tq=${query}`;
            const response = await fetch(url);
            const text = await response.text();

            // Extract the JSON part from the response more robustly
            const jsonMatch = text.match(/google\.visualization\.Query\.setResponse\(([\s\S\w]+)\)/);
            if (!jsonMatch || jsonMatch.length < 2) {
                console.error("Failed to extract JSON from response");
                return [];
            }
            const jsonString = jsonMatch[1];
            const json = JSON.parse(jsonString);

            console.log("Response JSON:", json);

            // Check if rows exist in the response
            if (!json.table || !json.table.rows) {
                console.log("No data found for the given zipcode");
                return [];
            }

            const rows = json.table.rows;
            const formattedData = rows.map(row => row.c.map(cell => cell ? cell.v : ''));
            return formattedData;
        } catch (error) {
            console.error("Error in fetchCityStateByZipcode:", error);
            return [];
        }
    };

    // Fetch city, state, & county from the published x4 zipcode google sheets
    const fetchCityStateCounty = async (city = '', state = '', includeCounties = false) => {
        if (!userIsValid) return [];
        try {
            let query;
            if (city) {
                // Fetch states (and optionally counties) based on a city
                query = includeCounties
                    ? encodeURIComponent(`SELECT B, C WHERE A = '${city}'`)
                    : encodeURIComponent(`SELECT B WHERE A = '${city}'`);
            } else if (state) {
                // Fetch cities (and optionally counties) based on a state
                query = includeCounties
                    ? encodeURIComponent(`SELECT A, C WHERE B = '${state}'`)
                    : encodeURIComponent(`SELECT A WHERE B = '${state}'`);
            } else {
                throw new Error('Either city or state must be provided');
            }

            const url = `https://docs.google.com/spreadsheets/d/${CITYSTATE_LOOKUP_SHEET_ID}/gviz/tq?tq=${query}`;
            const response = await fetch(url);
            const text = await response.text();
            // Extract the JSON part from the response
            const jsonString = text.match(/google\.visualization\.Query\.setResponse\(([\s\S\w]+)\)/)[1];
            const json = JSON.parse(jsonString);
            const rows = json.table.rows;

            // Deduplicate data
            const deduplicatedData = new Set();
            rows.forEach(row => {
                const rowData = row.c.map(cell => cell ? cell.v : '').join(', ');
                deduplicatedData.add(rowData);
            });

            return Array.from(deduplicatedData);
        } catch (error) {
            console.error('Error fetching city/state/county data:', error);
            return [];
        }
    };

    //Get specific user by admin request
    const getUser = async (userId, forceNew = false) => {
        if (!userIsValid) return new User();
        if (forceNew) clearUser();
        try {
            // Call the cloud function and pass the userId
            const result = await Parse.Cloud.run('getUserByAdmin', { userId });
            const newUser = new User(result);
            setUser(newUser);
            return newUser;
        } catch (error) {
            if (error.code === 119) setUserIsValid(false);
            console.error('Error fetching user by admin:', error);
            throw error;
        }
    };

    // Get all users by Admin
    const getUsers = (forceNew = false) => {
        if (!userIsValid) return [new User()];
        if (forceNew) clearUsers();
        if (currentUser && !currentUser.role === "Admin") return;
        if ((!users || !users.length || forceNew) && currentUser.role === "Admin" && !fetchingGetUsers.current) {
            // console.log(user, !users, !users.length, forceNew, "&&", currentUser.role === "Admin", "&&", !fetchingGetUsers.current);
            fetchingGetUsers.current = true;
            Parse.Cloud.run('getUsers', { userRole: 'Admin' }).then(function (fetchedUsers) {
                if (is_array(fetchedUsers) && fetchedUsers.length) {
                    console.log(fetchedUsers);
                    const mappedUsers = fetchedUsers.map((userObject) => {
                        const newUser = new User(userObject);
                        newUser.ready = true;
                        return newUser;
                    });
                    setUsers(mappedUsers);
                    return;
                }
                // The getUsers cloud function ran successfully and returned users
                // setUsers(fetchedUsers);
            }, function (fetchError) {
                // The getUsers function failed to run
                console.error("fetchError", fetchError);
                setError(fetchError);
            }).finally(() => {
                fetchingGetUsers.current = false;
            });
        }
        if (users && users.length) {
            const mappedUsers = users.map((userObject) => {
                const copiedUser = objectCopy(userObject);
                copiedUser.attributes = copiedUser;
                const newUser = new User(copiedUser);
                newUser.ready = true;
                return newUser;
            });
            setUsers(mappedUsers);
            return;
        }
    };

    const resetUserPasswordByAdmin = async (userId) => {
        if (!userIsValid) return "";
        try {
            if (!currentUser || currentUser.role !== "Admin") throw new Error("You must be an Admin and logged in to use this feature.");
            const params = { userId };
            const tempPassword = await Parse.Cloud.run('adminResetUserPassword', params);
            return tempPassword;
        } catch (error) {
            if (error.code === 119) setUserIsValid(false);
            console.error('Error while resetting password: ', error);
        }
        console.log('You need to be logged in as an admin.');
    }

    const getRoles = async (byAssociation = false) => {
        console.log(userIsValid, fetchingGetRoles.current, rolesAreExpired());
        if (!userIsValid) return [];
        if (!fetchingGetRoles.current && rolesAreExpired()) {
            clearRoles();
            try {
                if (!currentUser || currentUser.role !== "Admin") throw new Error("You must be an Admin and logged in to use this feature.");
                fetchingGetRoles.current = true;
                const fetchedRoles = await Parse.Cloud.run('getRoles');
                const uniqueRoleNames = new Set();
                console.log(fetchedRoles);
                fetchedRoles.forEach(role => uniqueRoleNames.add(role['name']));
                if (byAssociation) {
                    setRoles(fetchedRoles);
                    return fetchedRoles;
                }
                setRoles(Array.from(uniqueRoleNames));
                fetchingGetRoles.current = false;
                return Array.from(uniqueRoleNames);
            } catch (error) {
                console.error('Error while fetching roles: ', error);
            } finally {
                fetchingGetRoles.current = false;
            }
        } else {
            fetchingGetRoles.current = false;
            return roles;
        }
    }

    const changeUserRoleByAdmin = async (userId, newRoleName) => {
        if (!userIsValid) return false;
        try {
            const result = await Parse.Cloud.run('changeUserRole', {
                userId,
                newRoleName,
            });
            console.log(result);
            getUsers(true);
            return true;
        } catch (error) {
            if (error.code === 119) setUserIsValid(false);
            console.error('Error while assigning new role: ', error);
        }
        return false;
    }

    /**
     * Sends an email using a cloud function. Must be logged into the system.
     * @param {string} to - The recipient's email address.
     * @param {string} subject - The subject of the email.
     * @param {string} body - The body of the email. This can be a plain text or HTML content based on the isHTML flag.
     * @param {boolean} [isHTML=false] - Flag indicating whether the body is HTML. Defaults to false.
     * @returns {Promise<boolean>} - Returns true if the email is sent successfully, otherwise false.
     */
    const sendEmailAsAdmin = async (to, subject, body, isHTML = false, from = "no-reply") => {
        if (!userIsValid) return false;
        if (!isHTML) {
            try {
                const result = await Parse.Cloud.run('sendEmail', { to, subject, body, from });
                return result;
            } catch (error) {
                console.error('Error sending email:', error);
                return false;
            }
        }
        if (isHTML) {
            try {
                const response = await Parse.Cloud.run("sendHTMLEmail", {
                    to,
                    subject,
                    body,
                    from
                });
                return response;
            } catch (error) {
                if (error.code === 119) setUserIsValid(false);
                console.error("Error sending email:", error);
                return false
            }
        }

    };

    const getAdminDashboardCounts = async (forceNew = false) => {
        if (!userIsValid) return new CountsAdminDash();
        if (forceNew) clearCounts();

        if ((isCountsExpired() || forceNew || !counts.isset) && !fetchingDashboardCounts.current) {
            try {
                fetchingDashboardCounts.current = true;
                const result = await Parse.Cloud.run('getDashboardCounts');
                const fetchedCounts = new CountsAdminDash(result);
                setCounts(fetchedCounts);
                return fetchedCounts;
            } catch (error) {
                console.error('Error fetching dashboard counts:', error);
                if (error.code === 119) setUserIsValid(false);
                const errorCounts = new CountsAdminDash();
                errorCounts.error = true;
                setCounts(errorCounts);
                return errorCounts;
            } finally {
                fetchingDashboardCounts.current = false;
            }
        }
        return counts;
    };

    const getAllOpenQuotesAsAdmin = async (forceNew = false) => {
        if (!userIsValid) return [new Quote()];
        if (forceNew) clearQuotes();

        // console.log("quotesAreExpired() || forceNew || !quotes[0].isset", quotesAreExpired(), forceNew, !quotes[0].isset);
        if ((quotesAreExpired() || forceNew || !quotes[0].isset) && !fetchingOpenQuotes.current) { //quote.isset is only set if data is returned, will always api request if no quotes
            try {
                fetchingOpenQuotes.current = true;
                const result = await Parse.Cloud.run('getAllOpenQuotes');
                console.log("getAllOpenQuotes", `quotesAreExpired()=${quotesAreExpired()}`, `forceNew=${forceNew}`, `!quotes[0].isset=${!quotes[0].isset}`, result);
                let fetchedQuotes = [new Quote()];
                fetchedQuotes[0].ready = true;
                if (is_array(result) && result.length) {
                    fetchedQuotes = result.map((quote) => {
                        const fetchedQuote = new Quote(quote);
                        fetchedQuote.ready = true;
                        return fetchedQuote;
                    });
                }
                //   console.log("SETTING getAllOpenQuotes", fetchedQuotes);
                setQuotes(fetchedQuotes);
                return fetchedQuotes;
            } catch (error) {
                if (error.code === 119) setUserIsValid(false);
                console.error('Error fetching quotes:', error);
                const errorQuotes = [new Quote()];
                errorQuotes.error = true;
                setQuotes(errorQuotes);
                return errorQuotes;
            } finally {
                fetchingOpenQuotes.current = false;
            }
        }
        //console.log("DRY QUOTES ***********************************************", quoteRequests);
        let reHydratedQuotes = [new Quote()];
        if (is_array(quotes) && quotes.length) {
            reHydratedQuotes = quotes.map((quote) => {
                quote.ready = !fetchingOpenQuotes.current;
                return rehydrateObject(new Quote(), quote);
            });
        }
        // console.log("reHydratedQuotes TO BE SET", reHydratedQuotes);
        setQuotes(reHydratedQuotes, false);
        return reHydratedQuotes;
    };

    const getAllOpenQuoteRequestsAsAdmin = async (forceNew = false) => {
        if (!userIsValid) return [new QuoteRequest()];
        if (forceNew) clearAdminQuoteRequests();

        if ((quoteRequestsAreExpired() || forceNew || !quoteRequests[0].isset) && !fetchingQuoteRequests.current) {
            //console.log("quoteRequestsAreExpired() || forceNew || !quoteRequests[0].isset", quoteRequestsAreExpired(), forceNew, !quoteRequests[0].isset);
            try {
                fetchingQuoteRequests.current = true;
                const result = await Parse.Cloud.run('getAllQuoteRequests');
                let fetchedQuoteRequests = [new QuoteRequest()];
                fetchedQuoteRequests[0].ready = true;
                if (is_array(result) && result.length) {
                    fetchedQuoteRequests = result.map((quote) => {
                        const singleQuote = new QuoteRequest();
                        singleQuote.saveQuoteRequest(quote);
                        return singleQuote;
                    });
                }
                // console.log("SETTING getAllQuoteRequests", fetchedQuoteRequests);
                setQuoteRequests(fetchedQuoteRequests);
                return fetchedQuoteRequests;
            } catch (error) {
                if (error.code === 119) setUserIsValid(false);
                console.error('Error fetching quote requests:', error);
                const errorQuoteRequest = [new QuoteRequest()];
                errorQuoteRequest[0].ready = true;
                errorQuoteRequest[0].error = true;
                setQuoteRequests(errorQuoteRequest);
                return errorQuoteRequest;
            } finally {
                fetchingQuoteRequests.current = false;
            }
        }
        //console.log("DRY QUOTE REQUEST ***********************************************", quoteRequests);
        let reHydratedQuoteRequest = [new QuoteRequest()];
        if (is_array(quoteRequests) && quoteRequests.length) {
            reHydratedQuoteRequest = quoteRequests.map((quoteRequest) => {
                quoteRequest.ready = !fetchingQuoteRequests.current;
                return rehydrateObject(new QuoteRequest(), quoteRequest);
            });
        }
        // console.log("reHydratedQuoteRequest", reHydratedQuoteRequest);
        setQuoteRequests(reHydratedQuoteRequest, false);
        return reHydratedQuoteRequest;
    };

    /**
     * Saves or updates the pre-quote data by admin.
     * @param {{}} preQuoteData 
     * @returns 
     */
    const savePreQuote = async (preQuoteData) => {
        if (!userIsValid) return { objectId: "", createdAt: new Date(), updatedAt: new Date(), isActive: false };
        try {
            const result = await Parse.Cloud.run('savePreQuote', preQuoteData);
            console.log(result);
            if (is_object(result)) return { objectId: result.objectId, createdAt: result.createdAt, updatedAt: result.updatedAt, isActive: result.isActive };
        } catch (error) {
            if (error.code === 119) setUserIsValid(false);
            console.error(error);
        }
        return false;
    }

    const getAllPreQuotes = async (forceNew = false) => {
        if (!userIsValid) return [new PreQuote()];
        if (forceNew) clearPreQuotes();

        // console.log("preQuotesAreExpired() || forceNew || !preQuotes[0].isset", preQuotesAreExpired(), forceNew, !preQuotes[0].isset);
        if ((preQuotesAreExpired() || forceNew || !preQuotes[0].isset) && !fetchingPreQuoteRequests.current) {
            try {
                fetchingPreQuoteRequests.current = true;
                const result = await Parse.Cloud.run('getAllPreQuotes');
                let fetchedPreQuotes = [new PreQuote()];
                fetchedPreQuotes[0].ready = true;
                if (is_array(result) && result.length) {
                    fetchedPreQuotes = result.map((preQuote) => {
                        const fetchedQuote = new PreQuote(preQuote);
                        fetchedQuote.ready = true;
                        return fetchedQuote;
                    });
                }
                setPreQuotes(fetchedPreQuotes);
                return fetchedPreQuotes;
            } catch (error) {
                if (error.code === 119) setUserIsValid(false);
                console.error('Error fetching preQuotes:', error);
                const errorQuotes = [new PreQuote()];
                errorQuotes.error = true;
                setPreQuotes(errorQuotes);
                return errorQuotes;
            } finally {
                fetchingPreQuoteRequests.current = false;
            }
        }
        let reHydratedQuotes = [new PreQuote()];
        if (is_array(preQuotes) && preQuotes.length) {
            reHydratedQuotes = preQuotes.map((preQuote) => {
                preQuote.ready = !fetchingPreQuoteRequests.current;
                return rehydrateObject(new PreQuote(), preQuote);
            });
        }
        // console.log("reHydratedQuotes TO BE SET", reHydratedQuotes);
        setPreQuotes(reHydratedQuotes, false);
        return reHydratedQuotes;
    };


    const deletePreQuote = async (preQuoteId) => {
        if (!userIsValid) return false;
        try {
            const result = await Parse.Cloud.run('deletePreQuote', { preQuoteId });
            return result; // Returns true if successfully deleted, false if already deleted
        } catch (error) {
            if (error.code === 119) setUserIsValid(false);
            console.error('Error deleting PreQuote:', error);
            throw error; // Rethrow the error to handle it in the calling component
        }
    };


    const convertPreQuoteToQuote = async (preQuoteId) => {
        if (!userIsValid) return "";
        try {
            // Call the cloud function and pass the preQuoteId as a parameter
            const result = await Parse.Cloud.run('convertPreQuoteToQuote', { preQuoteId });
            console.log('New Quote ID:', result);
            return result;
        } catch (error) {
            if (error.code === 119) setUserIsValid(false);
            // Handle the error by logging to console or showing an alert
            console.error('Error converting pre-quote to quote:', error);
            throw error; // Re-throw the error if you want calling function to handle it
        }
    };

    const saveQuote = async (quoteData) => {
        if (!userIsValid) return new Quote();
        try {
            const result = await Parse.Cloud.run('saveQuote', quoteData);
            return result;
        } catch (error) {
            if (error.code === 119) setUserIsValid(false);
            console.error('Error when saving quote: ', error);
            throw new Error(`Quote failed to save with Error: ${error.message}`);
        }
    };


    const getOpenOrdersByAdmin = async (forceNew) => {
        if (!userIsValid) return [new Order()];
        if (forceNew) clearOrders();

        // console.log("ordersAreExpired() || forceNew || !orders[0].isset", ordersAreExpired(), forceNew, !orders[0].isset);
        if ((ordersAreExpired() || forceNew || !orders[0].isset) && !fetchingOpenOrders.current) { //order.isset is only set if data is returned, will always api request if no orders
            try {
                fetchingOpenOrders.current = true;
                const result = await Parse.Cloud.run('getAllOpenOrders');
                console.log("getAllOpenOrders", `ordersAreExpired()=${ordersAreExpired()}`, `forceNew=${forceNew}`, `!orders[0].isset=${!orders[0].isset}`, result);
                let fetchedOrders = [new Order()];
                fetchedOrders[0].ready = true;
                if (is_array(result) && result.length) {
                    fetchedOrders = result.map((order) => {
                        const fetchedOrder = new Order(order);
                        fetchedOrder.ready = true;
                        return fetchedOrder;
                    });
                }
                console.log("SETTING getAllOpenOrders", fetchedOrders);
                setOrders(fetchedOrders);
                return fetchedOrders;
            } catch (error) {
                if (error.code === 119) setUserIsValid(false);
                console.error('Error fetching orders:', error);
                const errorOrders = [new Order()];
                errorOrders.error = true;
                setOrders(errorOrders);
                return errorOrders;
            } finally {
                fetchingOpenOrders.current = false;
            }
        }
        //console.log("DRY QUOTES ***********************************************", quoteRequests);
        let reHydratedOrders = [new Order()];
        if (is_array(orders) && orders.length) {
            reHydratedOrders = orders.map((order) => {
                console.log("Rehydrating", order);
                order.ready = !fetchingOpenOrders.current;
                return rehydrateObject(new Order(), new Order(order));
            });
        }
        console.log("reHydratedOrders TO BE SET", reHydratedOrders);
        setOrders(reHydratedOrders, false);
        return reHydratedOrders;
    }

    const getClosedOrdersByAdmin = async (forceNew) => {
        if (!userIsValid) return [new Order()];
        if (forceNew) clearOrdersClosed();

        // console.log("ordersClosedAreExpired() || forceNew || !ordersClosed[0].isset", ordersClosedAreExpired(), forceNew, !ordersClosed[0].isset);
        if ((ordersClosedAreExpired() || forceNew || !ordersClosed[0].isset) && !fetchingClosedOrders.current) { //order.isset is only set if data is returned, will always api request if no ordersClosed
            try {
                fetchingClosedOrders.current = true;
                const result = await Parse.Cloud.run('getAllClosedOrders');
                console.log("getAllClosedOrders", `ordersClosedAreExpired()=${ordersClosedAreExpired()}`, `forceNew=${forceNew}`, `!ordersClosed[0].isset=${!ordersClosed[0].isset}`, result);
                let fetchedOrders = [new Order()];
                fetchedOrders[0].ready = true;
                if (is_array(result) && result.length) {
                    fetchedOrders = result.map((order) => {
                        const fetchedOrder = new Order(order);
                        fetchedOrder.ready = true;
                        return fetchedOrder;
                    });
                }
                //   console.log("SETTING getAllClosedOrders", fetchedOrders);
                setOrdersClosed(fetchedOrders);
                return fetchedOrders;
            } catch (error) {
                if (error.code === 119) setUserIsValid(false);
                console.error('Error fetching ordersClosed:', error);
                const errorOrders = [new Order()];
                errorOrders.error = true;
                setOrdersClosed(errorOrders);
                return errorOrders;
            } finally {
                fetchingClosedOrders.current = false;
            }
        }
        //console.log("DRY QUOTES ***********************************************", quoteRequests);
        let reHydratedOrders = [new Order()];
        if (is_array(ordersClosed) && ordersClosed.length) {
            reHydratedOrders = ordersClosed.map((order) => {
                order.ready = !fetchingClosedOrders.current;
                return rehydrateObject(new Order(), order);
            });
        }
        // console.log("reHydratedOrders TO BE SET", reHydratedOrders);
        setOrdersClosed(reHydratedOrders, false);
        return reHydratedOrders;
    }

    const saveOrder = async (orderData) => {
        if (!userIsValid) return new Order();
        try {
            const result = await Parse.Cloud.run('saveOrder', orderData);
            return result;
        } catch (error) {
            if (error.code === 119) setUserIsValid(false);
            console.error('Error when saving order: ', error);
            throw new Error(`Order failed to save with Error: ${error.message}`);
        }
    };

    /**
     * Converts an order back to a quote, removing the acceptance. Both new orders and new quotes should be fetched after successful execution.
     * @param {{orderId:string,activity:{},jobNotes:{},jobNotesInternal:{}}} orderData 
     * @returns {Quote}
     */
    const convertOrderToQuote = async (orderData) => {
        if (!userIsValid) return new Order();
        try {
            const result = await Parse.Cloud.run('orderToQuote', orderData);
            return result;
        } catch (error) {
            if (error.code === 119) setUserIsValid(false);
            console.error('Error when converting order to quote: ', error);
            throw new Error(`Order failed to convert to quote with Error: ${error.message}`);
        }
    };

    return { users, user, error, roles, userIsValid, counts, quotes, quoteRequests, preQuotes, orders, ordersClosed, getUsers, getUser, setUser, setUserIsValid, clearUsers, clearUser, clearQuotes, clearCounts, clearAdminQuoteRequests, resetUserPasswordByAdmin, getRoles, clearRoles, changeUserRoleByAdmin, sendEmailAsAdmin, getAdminDashboardCounts, getAllOpenQuotesAsAdmin, getAllOpenQuoteRequestsAsAdmin, savePreQuote, deletePreQuote, clearPreQuotes, getAllPreQuotes, fetchZipCodes, fetchCityStateCounty, fetchCityStateByZipcode, convertPreQuoteToQuote, saveQuote, setOrders, clearOrders, ordersAreExpired, saveOrder, getOpenOrdersByAdmin, setOrdersClosed, clearOrdersClosed, ordersClosedAreExpired, getClosedOrdersByAdmin, convertOrderToQuote };
};

export default useAdmin;
