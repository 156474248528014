import { Check2Circle } from "react-bootstrap-icons";
import { MyDate, is_object, isset, objectFlipKeyValue, objectHasKey, objectLen, objectTextToKey } from "../library/library";


class QuoteRequest {
    isset = false;
    isNew = false;
    objectId = null;
    id = "";
    userId = "";
    error = "";
    ready = false;
    email = "";
    notes = "";
    name = "";
    company = "";
    acceptTexts = false;
    mobileNumber = "";
    serviceMonth = "";
    selectedService = "";
    questionOne = "";
    questionTwo = "";
    questionThree = "";
    answerOne = "";
    answerTwo = "";
    answerThree = "";
    isActive = "";
    createdQuote = "";
    createdAt = "";
    updatedAt = "";
    modalData = {};
    #services = { AVProduction: "AV Production", Consulting: "Consulting", OnSiteManagement: "On-Site Management" };
    #questions = {
        AVProduction: {
            1: {
                id: "eventType",
                question: "Event Type",
                placeHolder: "e.g., Corporate Event, Concert",
                answer: ""
            },
            2: {
                id: "attendeeCount",
                question: "Estimated Attendee Count",
                placeHolder: "Number of attendees",
                answer: ""
            },
            3: {
                id: "venueType",
                question: "Venue Type",
                placeHolder: "Indoor, Outdoor, etc.",
                answer: ""
            }
        },
        Consulting: {
            1: {
                id: "primaryChallange",
                question: "Primary Challenge",
                placeHolder: "Describe your main challenge",
                answer: ""
            },
            2: {
                id: "desiredOutcome",
                question: "Desired Outcome",
                placeHolder: "What success looks like",
                answer: ""
            },
            3: {
                id: "avSetup",
                question: "Specific Requirements or Constraints",
                placeHolder: "Describe the projects current requirements, constraints, current status, etc...",
                answer: ""
            }
        },
        OnSiteManagement: {
            1: {
                id: "eventScope",
                question: "Event Scope",
                placeHolder: "Tell us a bit about your event. e.g., Needs, Full-scale event planning, Day-of coordination",
                answer: ""
            },
            2: {
                id: "eventSize",
                question: "Event Size",
                placeHolder: "# of Attendees",
                answer: ""
            },
            3: {
                id: "eventLocation",
                question: "Event Location",
                placeHolder: "City, State, Venue Name",
                answer: ""
            }
        },

    }

    constructor(currentUser, selectedService) {
        if (currentUser === null || currentUser === undefined || currentUser === false || !currentUser.hasOwnProperty('id')) {
            if (selectedService) this.selectedService = selectedService;
            this.error = "No user found or user not passed at class instantiation."
            return this;
        }
        if (currentUser.id === "" || currentUser.id === null || currentUser.id === false) {
            if (selectedService) this.selectedService = selectedService;
            this.error = "User is not logged in."
            return this;
        }
        if (!isset(selectedService)) {
            this.error = "Required paramater 2, selectedService not supplied or is empty."
            if (currentUser && currentUser.hasOwnProperty('id') && currentUser.id) this.id = currentUser.id;
            console.error(this.error);
            return this;
        }
        this.isset = true;
        this.id = currentUser.id;
        this.email = currentUser.email;
        this.selectedService = selectedService;
    }

    get services() {
        return this.#services;
    }
    get questions() {
        return this.#questions;
    }

    get answers() {
        if (!isset(this.selectedService)) {
            this.error = "Required paramater selectedService must be set before getting answers.";
            throw new Error(this.error);
        }

        let allAnswers = {};
        Object.keys(this.#questions).forEach((service) => {
            if (service === this.selectedService) {
                allAnswers = {
                    1: this.#questions[service][1].answer,
                    2: this.#questions[service][2].answer,
                    3: this.#questions[service][3].answer,
                };
            }
        });
        return allAnswers;
    }

    getTabulation() {
        return {
            headers: ["New", "Service", "Timeframe", "Name", "Company", "Email", "Mobile Number", "Request Date",],
            keys: ["isNew", "selectedService", "serviceMonth", "name", "company", "email", "mobileNumber", "createdAt",],
            values: [this.isNew ? <Check2Circle size={18} color="green" /> : <></>, this.#services[this.selectedService], this.serviceMonth, this.name, this.company, this.email, this.mobileNumber, new MyDate(this.createdAt).toString(false)]
        }

    }


    saveFormData(modalData) {
        if (modalData === null || modalData === undefined || modalData === false || !modalData.hasOwnProperty('acceptTexts')) {
            this.error = "Form was submitted empty or is missing required entries."
            throw new Error(this.error);
        }
        if (!isset(this.selectedService)) {
            this.error = "No service provided, paramater 1 empty. A service must be provided to saveFormData().";
            throw new Error(this.error);
        }
        if (!objectHasKey(this.#services, this.selectedService)) {
            this.error = `Incorrect service provided - '${this.selectedService}' not found in services paramater`;
            throw new Error(this.error);
        }
        this.modalData = modalData;
        if (this.email !== modalData.email) this.appendNote(`Email supplied does not match user's registered email: ${modalData.email} vs ${this.email}`);
        this.email = modalData.email;
        this.name = modalData.name;
        this.company = modalData.company;
        this.acceptTexts = modalData.acceptTexts;
        this.mobileNumber = modalData.mobileNumber;
        this.serviceMonth = modalData.serviceMonth;
        if (!this.#saveAnswers()) {
            throw new Error(this.error);
        }
        this.ready = true;
        this.isset = true;
    }

    saveQuoteRequest(apiReturn) {
        console.log(apiReturn);
        if (!is_object(apiReturn)) {
            this.error = "API did not return an object as expected.";
            this.isset = false;
            return this;
        }
        this.ready = true;
        this.isset = true;
        this.objectId = apiReturn.objectId;
        this.id = apiReturn.userId;
        this.userId = apiReturn.userId;
        this.isNew = apiReturn.isNew;
        this.selectedService = apiReturn.selectedService;
        this.name = apiReturn.name;
        this.email = apiReturn.email;
        this.company = apiReturn.company;
        this.serviceMonth = apiReturn.serviceMonth;
        this.notes = apiReturn.notes;
        this.mobileNumber = apiReturn.mobileNumber;
        this.acceptTexts = apiReturn.acceptTexts;
        this.questionOne = apiReturn.questionOne;
        this.questionTwo = apiReturn.questionTwo;
        this.questionThree = apiReturn.questionThree;
        this.answerOne = apiReturn.answerOne;
        this.answerTwo = apiReturn.answerTwo;
        this.answerThree = apiReturn.answerThree;
        this.isActive = apiReturn.isActive;
        this.createdAt = apiReturn.createdAt;
        this.updatedAt = apiReturn.updatedAt;
        this.createdQuote = apiReturn.createdQuote;
        return this;
    }

    appendNote(note) {
        this.notes = `${this.notes} | ${note}`;
    }

    #saveAnswers() {
        if (this.selectedService === "") {
            this.error = "Service paramater 'selectedService' must be set before calling set answers().";
            throw new Error(this.error);
        }
        if (!isset(this.modalData)) {
            this.error = "Required paramater modalData has not be set.";
            throw new Error(this.error);
        }
        Object.values(this.#questions[this.selectedService]).forEach((questionObject, index) => {
            if (!objectHasKey(this.modalData, questionObject.id)) {
                this.error = `Incorrect or missing required modalData paramater (form modal name). ${questionObject.id} not found in modalData for service ${this.selectedService}.`;
                throw new Error(this.error);
            }
            this.#questions[this.selectedService][index + 1].answer = this.modalData[questionObject.id];
        });
        if (objectLen(this.answers) !== 3) {
            this.error = "Answers did not save correctly, blank or missing after save."
            return false;
        }
        return true;
    }



    getServiceDetails(selectedService, questionNumber) {
        const services = this.services;
        const questions = this.questions;
        const flippedServicesObject = objectFlipKeyValue(services, "", (value) => {
            return objectTextToKey(value);
        });
        let testVal = false;
        Object.values(flippedServicesObject).forEach((value) => {
            if (value === selectedService) testVal = true;
        });
        if (!testVal) {
            this.error = "Incorrect Service or Service does not exist in QuoteRequest class."
            console.error(this.error);
            throw new Error(this.error);
        }
        return questions[selectedService][questionNumber];

    }




}

export default QuoteRequest