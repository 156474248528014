import { useEffect } from 'react';
import Parse from 'parse';
import { useUser } from '../../context/UserContext';
import User from '../../classes/User';
import useAdmin from '../../library/useAdmin';
import useLocalStorage from '../../library/useLocalStorage';
import { ONE_MINUTE } from '../../library/library';


const app_id = process.env.REACT_APP_PARSE_APP_ID;
const host_url = process.env.REACT_APP_PARSE_HOST_URL;
const javascript_key = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;

Parse.initialize(app_id, javascript_key);
Parse.serverURL = host_url;
//const Session = Parse.Object.extend("_Session");
//const query = new Parse.Query(Session);

const useAuth = () => {
    const { currentUser, setCurrentUser, loading, setLoading, clearCache, userIsValid, setUserIsValid } = useUser();
    const { clearUsers, clearCounts, clearAdminQuoteRequests, clearQuotes, clearPreQuotes, clearOrdersClosed, clearOrders } = useAdmin();
    const [userRole, setUserRole, clearUserRole, userRoleIsExpired] = useLocalStorage('userRole', "", ONE_MINUTE);



    useEffect(() => {
        // Check for current user
        let mounted = true;
        (
            async () => {
                if (mounted) {
                    const userReturn = Parse.User.current();
                    if (mounted) {
                        const user = new User(userReturn);
                        //fetch and set user role
                        if (userReturn && userReturn.id) {
                            let forceNew = false;
                            if (!userIsValid) forceNew = true;
                            setLoading(true);
                            const role = await fetchCurrentUserRole(forceNew);
                            user.role = role;
                        }
                        user.isset = true;
                        if (user.role) setUserIsValid(true);
                        setCurrentUser(() => user);
                    }
                }
            }
        )();
        return () => mounted = false;
        // eslint-disable-next-line
    }, [userIsValid]);





    const login = async (username, password) => {
        setLoading(true);
        try {
            //login user
            const userReturn = await Parse.User.logIn(username, password);
            const user = new User(userReturn);
            //fetch and set user role
            const role = await fetchCurrentUserRole(true);
            user.role = role;
            setCurrentUser(() => user);
            return 1981;
        } catch (error) {
            console.error('Error while logging in user', error);
            return error.code;
        } finally {
            setLoading(false);
        }

    };

    const logout = async () => {
        clearUsers();
        clearCounts();
        clearCache(); // User Cache
        clearAdminQuoteRequests();
        clearOrders();
        clearQuotes();
        clearOrdersClosed();
        clearPreQuotes();
        clearUserRole();
        setLoading(true);
        try {
            const parseLogout = await Parse.User.logOut();
            console.log(parseLogout);
            const user = new User(null);
            setCurrentUser(() => user);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);

    };

    const register = async (username, password, email, recaptcha, recaptchaValidation) => {
        setLoading(true);
        const userReturn = new Parse.User();
        userReturn.set("username", username);
        userReturn.set("password", password);
        userReturn.set("email", email);
        userReturn.set("recaptcha", recaptcha);
        userReturn.set("recaptchaValidation", recaptchaValidation);

        try {
            await userReturn.signUp();
            const user = new User(userReturn);
            setCurrentUser(() => user);
            setLoading(false);
            return true;
        } catch (error) {
            console.error('Error while registering user', error);
            setLoading(false);
            return false;
        }
    };


    const fetchCurrentUserRole = async (forceNew = false) => {
        const currentUser = Parse.User.current();

        if ((!currentUser && !currentUser.getSessionToken()) || (currentUser && !currentUser.getSessionToken())) {
            setUserRole("");
            throw new Error('User not logged in');
        }

        try {
            if (userRoleIsExpired() || forceNew) {
                const role = await Parse.Cloud.run('getCurrentUserRole', {}, {
                    sessionToken: currentUser.getSessionToken()
                });
                setUserRole(role);
                return role;
            } else {
                return userRole;
            }
        } catch (error) {
            console.error('Error fetching current user role:', error);
            setUserRole("");
            return "";
        }
    }




    return { currentUser, login, logout, register, loading, setLoading, fetchCurrentUserRole };
};

export default useAuth;
