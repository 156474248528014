import React, { useState, useEffect } from 'react'
import { MyDate, intval, objectCopy } from '../../../../library/library';
import { Button, Col, Form, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ReactiveTable from './ReactiveTable';

import Order from '../../../../classes/Order';


function JobNotesPublicOrder({ jobNotesIn, setOrder, order, currentUser }) {
    const [jobNotesRow, setJobNotesRow] = useState(Object.values(jobNotesIn).length);
    const [jobNotesEditData, setJobNotesEditData] = useState({ date: "", userId: "", name: "", note: "" });


    useEffect(() => {
        let mounted = true;
        if (mounted && intval(jobNotesRow) < intval(Object.values(jobNotesIn).length) && jobNotesRow !== "") {
            setJobNotesEditData(jobNotesIn[jobNotesRow] || { date: "", userId: "", name: "", note: "" });
        }
        if (mounted && jobNotesRow === "") {
            setJobNotesEditData({ date: "", userId: "", name: "", note: "" });
        }

        if (checkJobNotesIDs(jobNotesIn)) {
            reKeyJobNotes(jobNotesIn);
        }

        return () => mounted = false;
        // eslint-disable-next-line
    }, [jobNotesRow, jobNotesIn]);


    const checkJobNotesIDs = (jobNotesIn) => {
        let test = false;
        let lastKey = 0;
        Object.keys(jobNotesIn).forEach((key) => {
            const intKey = intval(key);
            if (intKey > 0 && intKey !== lastKey + 1) {
                test = true;
            }
            lastKey = intval(key);
        });
        return test;
    }

    const reKeyJobNotes = (jobNotesIn) => {
        const newJobNotes = {};
        Object.values(jobNotesIn).forEach((value, index) => {
            newJobNotes[index] = value;
        });
        const newOrder = new Order(objectCopy(order));
        newOrder.jobNotes = objectCopy(newJobNotes);
        setOrder(newOrder);
    }


    // Update functions for Job Notes

    // Add/Update, Remove, Clear, and Undo Functions for Job Notes
    const addUpdateJobNote = () => {

        const newJobNote = objectCopy(jobNotesEditData);
        newJobNote.name = currentUser.name;
        newJobNote.userId = currentUser.id;
        const date = new MyDate();
        newJobNote.date = date.timeStamp;
        const newOrder = new Order(objectCopy(order));
        newOrder.jobNotes = objectCopy(order.jobNotes);
        newOrder.jobNotes[jobNotesRow] = newJobNote;
        setOrder(newOrder);
    };


    const removeJobNote = () => {

        const newOrder = new Order(objectCopy(order));
        newOrder.jobNotes = objectCopy(order.jobNotes);
        delete newOrder.jobNotes[jobNotesRow];
        setOrder(newOrder);
        setJobNotesRow("");
    };

    const handleJobNoteTableClick = (table, rowNum, rowData, cellData, event) => {
        setJobNotesRow(rowNum);
        setJobNotesEditData(jobNotesIn[rowNum] || { date: "", userId: "", name: "", note: "" });
    };




    const renderJobsPublicNameTooltip = (props) => {
        return (
            <Tooltip id="jobs-public-name-tooltip" {...props}>
                The name and ID of the posting are automatically updated based on your login information when you save or add a note.
            </Tooltip>
        )
    }

    const newItemPrep = () => {
        setJobNotesRow("");
        setTimeout(() => {
            setJobNotesRow(intval(Object.values(jobNotesIn).length));
        }, 10);
    }

    return (
        <>
            <ReactiveTable
                data={Object.values(jobNotesIn)}
                headerOverride={["Date", "User ID", "Name", "Note"]}
                clickHandler={handleJobNoteTableClick}
                useRowNumbers={true}
                rowNumberHeader="ID"
            />
            <Row>
                <Col xs={4} md={4} lg={2}>
                    <Form.Group>
                        <Form.Label className='text-black'>ID {(intval(jobNotesRow) === intval(Object.values(jobNotesIn).length) && jobNotesRow !== "")
                            || jobNotesRow === "" ?
                            <i className='text-success smaller shimmer text-button' onClick={newItemPrep}>New Note</i> : <></>
                        }</Form.Label>
                        <Form.Control
                            type="number"
                            name="jobId"
                            value={jobNotesRow}
                            onChange={(e) => setJobNotesRow(() => {
                                if (e.target.value >= 1 && e.target.value < intval(Object.values(jobNotesIn).length)) {
                                    return e.target.value;
                                }
                                if (e.target.value >= intval(Object.values(jobNotesIn).length)) {
                                    setJobNotesEditData((prev) => {
                                        return { ...prev, name: currentUser.name, note: "" };
                                    });
                                    return intval(Object.values(jobNotesIn).length);
                                }
                                if (e.target.value <= 0) {
                                    return 0;
                                }
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col xs={8} md={6} lg={3}>
                    <Form.Group>
                        <Form.Label className='text-black'>Name</Form.Label>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 300 }} overlay={renderJobsPublicNameTooltip}>
                            <Form.Control
                                readOnly
                                type="text"
                                name="name"
                                value={jobNotesEditData.name || ""}
                                onChange={(e) => setJobNotesEditData((prev) => {
                                    return { ...prev, [e.target.name]: currentUser.name };
                                })}
                            />
                        </OverlayTrigger>
                    </Form.Group>
                </Col>

                <Col md={12} lg={8}>
                    <Form.Group>
                        <Form.Label className='text-black'>Notes</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            name="note"
                            value={jobNotesEditData.note || ""}
                            onChange={(e) => setJobNotesEditData((prev) => {
                                return { ...prev, [e.target.name]: e.target.value };
                            })}
                        />
                    </Form.Group>
                </Col>

            </Row>
            <Row className='mt-3'>
                <Col>
                    {intval(jobNotesRow) === intval(Object.values(jobNotesIn).length) ?
                        <Button disabled={jobNotesEditData.note === ""} className='m-2' variant='success' onClick={addUpdateJobNote}>Save Note</Button> : <Button disabled={jobNotesRow === "" || jobNotesEditData.note === "" ? true : false} variant='success' onClick={addUpdateJobNote}>Update Note</Button>}

                    <Button className='m-2' variant="danger" onClick={removeJobNote}
                        disabled={intval(jobNotesRow) >= intval(Object.values(jobNotesIn).length) || jobNotesRow === "" ? true : false}>Delete Note</Button>
                    <Button className='m-2' variant="secondary" onClick={() => setJobNotesRow("")}>Clear</Button>

                </Col>
            </Row>
        </>
    )
}

export default JobNotesPublicOrder