import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col, Accordion, Card, FormControl, Tooltip, OverlayTrigger, Badge, Dropdown, Stack } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PreQuote from '../../../classes/PreQuote';
import CreateAQuoteToggle from './components/CreateAQuoteToggle';
import TextInputDDS from '../../../library/components/TextInputDDS';
import User from '../../../classes/User';
import { QuestionCircle, Window, WindowStack, XSquareFill } from 'react-bootstrap-icons';
import { MyDate, getUSStates, is_object, is_string, isset, objectCopy, objectFingerprint, objectHasKey, roundTo } from '../../../library/library';
import ServiceItemsSection from './components/ServiceItemsSection';
import JobNotesPublicSection from './components/JobNotesPublicSection';
import JobNotesInternalSection from './components/JobNotesInternalSection';
import { enUS } from 'date-fns/locale';
import useAdmin from '../../../library/useAdmin';
import useCustomAlert from '../../../library/useCustomAlert';


/*
const dateTimeStamp = new MyDate();

const testJobNotes = {
    0: { date: dateTimeStamp.timeStamp, userId: "3fsfs23rwf", name: "Jim Smith", note: "The first note." },
    1: { date: dateTimeStamp.timeStamp, userId: "3fsfs23rwf", name: "Jim Smith", note: "Here's a second note." },
    2: { date: dateTimeStamp.timeStamp, userId: "sdf3sdf333", name: "Bob Smith", note: "This is a thrid longer note. It will have lots of text and say lots of things that are probably very important but this is a run on sentance and it's only a test so it dosen't really matter." }
};

const testItems = {
    0: {
        quantity: 2,
        name: "Wireless Microphones",
        description: "High-quality wireless microphones for clear audio capture.",
        cost: "99.99",
        notes: "Includes receiver and batteries."
    },
    1: {
        quantity: 1,
        name: "Projector",
        description: "4K HD projector for presentations and videos.",
        cost: "299.99",
        notes: "Comes with all necessary cables."
    },
    2: {
        quantity: 4,
        name: "LED Panels",
        description: "Bright and energy-efficient LED panels for lighting.",
        cost: "249.99",
        notes: "Easy setup with adjustable stands."
    },
    3: {
        quantity: 3,
        name: "Mixing Console",
        description: "12-channel audio mixer for sound control.",
        cost: "199.99",
        notes: "USB interface for direct computer recording."
    },
    4: {
        quantity: 5,
        name: "Standing Speakers",
        description: "Large standing speakers for event sound amplification.",
        cost: "150.00",
        notes: "Ensure high-quality sound distribution."
    }
}
*/
const CreateAQuote = ({ show, handleSave, handleClose, preQuoteData = new PreQuote(), handleFinalize, currentUser = new User(), clearPreQuoteData, parentDeletePreQuote }) => {
    const [toggleWindowFullScreen, setToggleWindowFullScreen] = useState(false);
    const [preQuote, setPreQuote] = useState(() => {
        const thisPreQuote = new PreQuote(preQuoteData);
        thisPreQuote.fromUserId = currentUser.id || "";
        thisPreQuote.fromName = currentUser.name || "";
        thisPreQuote.fromCompany = currentUser.company || "";
        thisPreQuote.fromEmail = currentUser.email || "";
        thisPreQuote.fromMobileNumber = currentUser.mobileNumber || "";
        thisPreQuote.jobNotesInternal = {
            0: { date: new MyDate().timeStamp, userId: currentUser.id, name: currentUser.name, note: "New Pre-Quote created." }
        }
        return thisPreQuote;
    });
    const { fetchZipCodes, fetchCityStateCounty, fetchCityStateByZipcode, getUser, user, users, getUsers, clearUser, setUser, deletePreQuote } = useAdmin();
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const [customerDDS, setCustomerDDS] = useState("");
    const [amountTaxTotal, setAmountTaxTotal] = useState({ amount: 0, tax: 0, total: 0 });
    const [citiesList, setCitiesList] = useState([]);
    const [citiesLoading, setCitiesLoading] = useState(false);
    const [cityStateLoading, setCityStateLoading] = useState(false);
    const [zipcodeList, setZipcodeList] = useState([]);
    const [zipLoading, setZipLoading] = useState(false);
    const [city, setCity] = useState(preQuote.toCity || "");
    const [state, setState] = useState("");
    const [zip, setZip] = useState(preQuote.toZip || "");
    const [zipMustExist, setZipMustExist] = useState(false);
    const [address1, setAddress1] = useState(preQuote.toAddress1 || "");
    const [address2, setAddress2] = useState(preQuote.toAddress2 || "");
    const [addressIsValid, setAddressIsValid] = useState(true);
    const [placeIsLocked, setPlaceIsLocked] = useState(false);
    const [toggleClose, setToggleClose] = useState(false);



    // console.log("toggleClose", toggleClose, "user", user);

    //Sets current user to the 'from' user and adds new internal note to new prequote
    useEffect(() => {
        let mounted = true;
        if (toggleClose && mounted && show) {
            setToggleClose(false);
            clearUser();
            if (currentUser && currentUser?.name) {
                const newPreQuote = new PreQuote(preQuoteData);
                newPreQuote.fromUserId = currentUser.id;
                newPreQuote.fromName = currentUser.name;
                newPreQuote.fromCompany = currentUser.company;
                newPreQuote.fromEmail = currentUser.email;
                newPreQuote.fromMobileNumber = currentUser.mobileNumber;
                if (!newPreQuote.id) {
                    newPreQuote.jobNotesInternal = {
                        0: { date: new MyDate().timeStamp, userId: currentUser.id, name: currentUser.name, note: "New Pre-Quote created." }
                    }
                }
                setPreQuote(newPreQuote);
            }
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [toggleClose, show]);

    //On-Load clear user, fetch users, and set a refresh warning
    useEffect(() => {
        let mounted = true;
        if (mounted && show) {
            clearUser();
            getUsers();
            setRefreshWarning();
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [show]);

    //Lock address if populated on load.
    useEffect(() => {
        let mounted = true;
        if (mounted && show && preQuote.toAddress1 === address1
            && preQuote.toAddress2 === address2
            && preQuote.toCity === city
            && preQuote.toState === state
            && preQuote.toZip === zip
            && preQuote.toAddress1
            && preQuote.toCity
            && preQuote.toState
            && preQuote.toZip
        ) {
            setPlaceIsLocked(true);
        }
        return () => mounted = false;
    }, [preQuote, preQuote.state, address1, address2, city, state, zip, show]);


    useEffect(() => {
        let mounted = true;
        if (mounted && show && isset(preQuoteData)) {
            const thisPreQuote = new PreQuote(preQuoteData);
            thisPreQuote.fromUserId = currentUser.id;
            thisPreQuote.fromName = currentUser.name;
            thisPreQuote.fromCompany = currentUser.company;
            thisPreQuote.fromEmail = currentUser.email;
            thisPreQuote.fromMobileNumber = currentUser.mobileNumber;
            (
                async () => {
                    if (thisPreQuote.userId) {
                        if (user && thisPreQuote.toUserId === user.id) {
                            if (!thisPreQuote.userId) thisPreQuote.userId = user.id;
                            if (!thisPreQuote.toName && user.name) thisPreQuote.toName = user.name;
                            if (!thisPreQuote.toAddress1 && user.billingStreet) thisPreQuote.toAddress1 = user.billingStreet;
                            if (!thisPreQuote.toAddress2 && user.billingStreet2) thisPreQuote.toAddress2 = user.billingStreet2;
                            if (!thisPreQuote.toCity && user.billingCity) thisPreQuote.toCity = user.billingCity;
                            if (!thisPreQuote.toCompany && user.company) thisPreQuote.toCompany = user.company;
                            if (!thisPreQuote.toEmail && user.email) thisPreQuote.toEmail = user.email;
                            if (!thisPreQuote.toMobileNumber && user.mobileNumber) thisPreQuote.toMobileNumber = user.mobileNumber;
                            if (!thisPreQuote.toState && user.billingState) thisPreQuote.toState = user.billingState;
                            if (!thisPreQuote.toZip && user.billingZip) thisPreQuote.toZip = user.billingZip;
                            setPreQuote(thisPreQuote);
                            if (thisPreQuote.toAddress1) setAddress1(thisPreQuote.toAddress1)
                            if (thisPreQuote.toAddress2) setAddress2(thisPreQuote.toAddress2);
                            if (thisPreQuote.toState) refreshCityList(thisPreQuote.toState);
                            if (thisPreQuote.toState) setState(thisPreQuote.toState);
                            if (thisPreQuote.toCity) setCity(thisPreQuote.toCity);
                            if (thisPreQuote.toZip) setZip(thisPreQuote.toZip);
                            return () => mounted = false;
                        }

                        if (user && thisPreQuote.toUserId !== user.id && user.id) {
                            thisPreQuote.userId = user.id;
                            thisPreQuote.toName = user.name || "";
                            thisPreQuote.toAddress1 = user.billingStreet || "";
                            thisPreQuote.toAddress2 = user.billingStreet2 || "";
                            thisPreQuote.toCity = user.billingCity || "";
                            thisPreQuote.toCompany = user.company || "";
                            thisPreQuote.toEmail = user.email || "";
                            thisPreQuote.toMobileNumber = user.mobileNumber || "";
                            thisPreQuote.toState = user.billingState || "";
                            thisPreQuote.toZip = user.billingZip || "";
                            setPreQuote(thisPreQuote);
                            if (user.billingState) refreshCityList(user.billingState);
                            if (thisPreQuote.toAddress1) setAddress1(thisPreQuote.toAddress1)
                            if (thisPreQuote.toAddress2) setAddress2(thisPreQuote.toAddress2);
                            if (thisPreQuote.toCity) setCity(user.billingCity);
                            if (thisPreQuote.toState) setState(user.billingState);
                            if (thisPreQuote.toZip) setZip(user.billingZip);
                            return () => mounted = false;
                        }

                        const userReturn = await getUser(thisPreQuote.userId);
                        if (!thisPreQuote.userId) thisPreQuote.userId = userReturn.id || userReturn.objectId || "";
                        if (!thisPreQuote.toName) thisPreQuote.toName = userReturn.name || "";
                        if (!thisPreQuote.toAddress1) thisPreQuote.toAddress1 = userReturn.billingStreet || "";
                        if (!thisPreQuote.toAddress2) thisPreQuote.toAddress2 = userReturn.billingStreet2 || "";
                        if (!thisPreQuote.toCity) thisPreQuote.toCity = userReturn.billingCity || "";
                        if (!thisPreQuote.toCompany) thisPreQuote.toCompany = userReturn.company || "";
                        if (!thisPreQuote.toEmail) thisPreQuote.toEmail = userReturn.email || "";
                        if (!thisPreQuote.toMobileNumber) thisPreQuote.toMobileNumber = userReturn.mobileNumber || "";
                        if (!thisPreQuote.toState) thisPreQuote.toState = userReturn.billingState || "";
                        if (!thisPreQuote.toZip) thisPreQuote.toZip = userReturn.billingZip || "";
                        setPreQuote(thisPreQuote);
                    } else {
                        setPreQuote(thisPreQuote);
                    }
                }
            )();

        } else if (mounted && show && !isset(preQuoteData) && user && user.id && !toggleClose) {
            const thisUser = new User({ ...user, attributes: { ...user } });
            const newPreQuote = new PreQuote();
            newPreQuote.isset = true;
            newPreQuote.ready = true;
            newPreQuote.fromUserId = currentUser.id || "";
            newPreQuote.fromName = currentUser.name || "";
            newPreQuote.fromCompany = currentUser.company || "";
            newPreQuote.fromEmail = currentUser.email || "";
            newPreQuote.fromMobileNumber = currentUser.mobileNumber || "";
            if (!newPreQuote.userId) newPreQuote.userId = user.id;
            if (!newPreQuote.toName && thisUser.name) newPreQuote.toName = thisUser.name;
            if (!newPreQuote.toAddress1 && thisUser.billingStreet) newPreQuote.toAddress1 = thisUser.billingStreet;
            if (!newPreQuote.toAddress2 && thisUser.billingStreet2) newPreQuote.toAddress2 = thisUser.billingStreet2;
            if (!newPreQuote.toCity && thisUser.billingCity) newPreQuote.toCity = thisUser.billingCity;
            if (!newPreQuote.toCompany && thisUser.company) newPreQuote.toCompany = thisUser.company;
            if (!newPreQuote.toEmail && thisUser.email) newPreQuote.toEmail = thisUser.email;
            if (!newPreQuote.toMobileNumber && thisUser.mobileNumber) newPreQuote.toMobileNumber = thisUser.mobileNumber;
            if (!newPreQuote.toState && thisUser.billingState) newPreQuote.toState = thisUser.billingState;
            if (!newPreQuote.toZip && thisUser.billingZip) newPreQuote.toZip = thisUser.billingZip;
            setPreQuote(newPreQuote);
            if (newPreQuote.toAddress1) setAddress1(newPreQuote.toAddress1)
            if (newPreQuote.toAddress2) setAddress2(newPreQuote.toAddress2);
            if (thisUser.billingState) refreshCityList(thisUser.billingState);
            if (thisUser.billingState) setState(thisUser.billingState);
            if (newPreQuote.toCity) setCity(newPreQuote.toCity);
            if (newPreQuote.toZip) setZip(newPreQuote.toZip);
            newPreQuote.jobNotesInternal = {
                0: { date: new MyDate().timeStamp, userId: currentUser.id, name: currentUser.name, note: "New Pre-Quote created." }
            }
            return () => mounted = false;
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [preQuoteData, user, show]);

    useEffect(() => {
        let mounted = true;
        if (mounted && show && preQuote.toAddress1 && preQuote.toState && preQuote.toCity && preQuote.toZip) {
            setPlaceIsLocked(true);
        }
        if (mounted && show && addressExists("local") && !addressIsEqual()) {
            setPlaceIsLocked(false);
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [preQuote.toAddress1, preQuote.toState, preQuote.toCity, preQuote.toZip, city, show]);

    useEffect(() => {
        let mounted = true;
        //Clear cities, states, and zips when all empty
        if (mounted && show && !state && !city && !zip && !preQuote.toState && !preQuote.toCity && !preQuote.toZip) {
            setCitiesList([]);
            setZipcodeList([]);
        }
        //Find cities based on state when no zip is entered
        if (mounted && show && (state.length === 2 || preQuote.toState === 2) && !zip && !citiesList.length && !citiesLoading) {
            setCitiesLoading(true);
            (
                async () => {
                    const param = state.length === 2 ? state : preQuote.toState;
                    const citiesList = await fetchCityStateCounty("", param);
                    setCitiesList(citiesList);
                    setCitiesLoading(false);
                }
            )();
        }
        //Find cities based on state when state has changed
        if (mounted && show && (state.length === 2 || preQuote.toState === 2) && state !== preQuote.toState && !citiesLoading) {
            setCitiesLoading(true);
            (
                async () => {
                    const param = state.length === 2 ? state : preQuote.toState;
                    const citiesList = await fetchCityStateCounty("", param);
                    setCitiesList(citiesList);
                }
            )();
        }
        //Find city and state by zipcode
        if (mounted && show && zip.length === 5 && !city && !state && !preQuote.toState && !preQuote.toCity && !cityStateLoading) {
            setCityStateLoading(true);
            (
                async () => {
                    const cityStateList = await fetchCityStateByZipcode(zip);
                    //console.log(cityStateList);
                    if (cityStateList.length) {
                        setCitiesList([cityStateList[0][0]]);
                        // setStatesList([cityStateList[0][1]]);
                        setCity(cityStateList[0][0]);
                        setState(cityStateList[0][1]);
                        setZipcodeList([zip]);
                    } else {
                        setCitiesList([]);
                        // setStatesList([]);
                        setCity("");
                        setState("");
                    }
                    setCityStateLoading(false);
                }
            )();
        }
        //Find Zipcode when city and state are entered
        if (mounted && show && !preQuote.toState && !preQuote.toCity && !preQuote.toZip && city.length && state.length && !zip.length && !zipcodeList.length && !zipLoading) {
            setZipLoading(true);
            (
                async () => {
                    try {
                        const zipcodes = await fetchZipCodes(city, state);
                        if (zipcodes.length && Array.isArray(zipcodes)) {
                            const availableZips = zipcodes.flat(Infinity).map(zipCode => {
                                // Pad front leading zeros to 5 digits so it matches a zip code format
                                return zipCode.toString().padStart(5, '0');
                            });
                            setZipcodeList(availableZips);
                        }
                    } catch (error) {
                        console.error('Error fetching zip codes:', error);
                    } finally {
                        setZipLoading(false);
                    }
                }
            )();
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [state, city, zip, citiesLoading, show]);

    useEffect(() => {
        let mounted = true;
        if (mounted && show && address1.length) {
            setAddressIsValid(true);
        }
        return () => mounted = false;
    }, [address1, show]);

    useEffect(() => {
        let mounted = true;
        if (mounted && Object.values(preQuote.items).length) {
            setAmountTaxTotal(calculateAmtTaxTotal(preQuote));
        }
        return () => mounted = false;
    }, [preQuote]);

    const onConfirmRefresh = useCallback((event) => {
        event.preventDefault();
        return event.returnValue = "Are you sure you want to leave the page?";
    }, []);

    const setRefreshWarning = useCallback(() => {
        window.addEventListener("beforeunload", onConfirmRefresh, { capture: true });
    }, [onConfirmRefresh]);

    const removeRefreshWarning = useCallback(() => {
        window.removeEventListener("beforeunload", onConfirmRefresh, { capture: true });
    }, [onConfirmRefresh]);

    if (!show) return (<></>);

    const handleInputChange = (e) => {
        setPreQuote((prev) => {
            const newPreQuote = new PreQuote(prev);
            newPreQuote.isset = true;
            newPreQuote.ready = true;
            newPreQuote[e.target.name] = e.target.value
            return newPreQuote;
        });
    };

    const handleDateChange = (field, date) => {
        if (!date) return setPreQuote((prev) => {
            const newPreQuote = new PreQuote(prev);
            newPreQuote.isset = true;
            newPreQuote.ready = true;
            newPreQuote[field] = null;
            return newPreQuote;
        });
        setPreQuote((prev) => {
            const newPreQuote = new PreQuote(prev);
            newPreQuote.isset = true;
            newPreQuote.ready = true;
            newPreQuote[field] = date.toISOString()
            return newPreQuote;
        });
    };


    const handleSelectCustomer = (data) => {
        setCustomerDDS(data)
        setUser(users.filter((value) => {
            return `${value.firstname} ${value.lastname}` === data;
        })[0]);
    }



    const findZipCode = async (cityIn, stateIn) => {
        setZipMustExist(true);
        const zipcodes = await fetchZipCodes(cityIn, stateIn);
        if (zipcodes.length && Array.isArray(zipcodes)) {
            const availableZips = zipcodes.flat(Infinity).map(zipCode => {
                // Pad front leading zeros to 5 digits so it matches a zip code format
                return zipCode.toString().padStart(5, '0');
            });
            setZipcodeList(availableZips);
        }
    }


    const refreshCityList = async (stateIn) => {
        setCitiesLoading(true);
        const citiesList = await fetchCityStateCounty("", stateIn);
        setCitiesList(citiesList);
        setCitiesLoading(false);

    }

    const validateZipCode = async (cityIn, stateIn) => {
        setZipLoading(true);
        setZipMustExist(true);
        try {
            const zipcodes = await fetchZipCodes(cityIn, stateIn);
            if (zipcodes.length && Array.isArray(zipcodes)) {
                const availableZips = zipcodes.flat(Infinity).map(zipCodeReturned => {
                    // Pad front leading zeros to 5 digits so it matches a zip code format
                    return zipCodeReturned.toString().padStart(5, '0');
                });
                setZipcodeList(availableZips);
                return availableZips.find((value) => value === zip);
            }
            setZipcodeList([]);
            setZip("");
        } catch (error) {
            console.error('Error fetching zip codes:', error);
        } finally {
            setZipLoading(false);
        }

    }

    const clearCity = () => {
        setCity("");
        setCitiesList([]);
    }
    const clearState = () => {
        setState("");
        setCitiesLoading(false);
    }
    const clearZip = () => {
        setZipcodeList([]);
        setZip("");
    }

    const handleApplyAddress = async () => {
        if (!await validateZipCode(city || preQuote.toCity || "", state || preQuote.toState || "")) {
            setZip("");
            return;
        }
        if (address1 === "" && preQuote.toAddress1 === "") {
            setAddressIsValid(false);
            return;
        }

        setPreQuote((prev) => {
            const newPreQuote = new PreQuote(prev);
            newPreQuote.toAddress1 = address1;
            newPreQuote.toAddress2 = address2;
            newPreQuote.toCity = city;
            newPreQuote.toState = state;
            newPreQuote.toZip = zip;
            return newPreQuote;
        });
        setZipMustExist(false);
    }

    const isSaved = () => {
        if (!preQuoteData) return false; // No preQuoteData means it's made from scratch
        const localPreQuote = new PreQuote(preQuote);
        //console.log(preQuoteData, localPreQuote);
        const ignoreKeys = ["date", "ready", "isset", "items", "jobNotes", "jobNotesInternal", "activity"];
        //First level compare
        let passed = true;
        for (let index = 0; index < Object.entries(preQuoteData).length; index++) {
            const entry = Object.entries(preQuoteData)[index];
            if (ignoreKeys.includes(entry[0])) continue;
            if (entry[0] === "serviceStartDate" && is_string(entry[1]) && is_object(localPreQuote[entry[0]])) localPreQuote[entry[0]] = localPreQuote[entry[0]].toISOString();
            if (entry[0] === "serviceEndDate" && is_string(entry[1]) && is_object(localPreQuote[entry[0]])) localPreQuote[entry[0]] = localPreQuote[entry[0]].toISOString();
            if (entry[0] === "serviceStartDate" && is_object(localPreQuote[entry[0]])) localPreQuote[entry[0]] = localPreQuote[entry[0]].toISOString();
            if (entry[0] === "serviceEndDate" && is_object(localPreQuote[entry[0]])) localPreQuote[entry[0]] = localPreQuote[entry[0]].toISOString();
            if (entry[0] === "serviceStartDate" && is_object(entry[1])) entry[1] = entry[1].toISOString();
            if (entry[0] === "serviceEndDate" && is_object(entry[1])) entry[1] = entry[1].toISOString();
            if (localPreQuote[entry[0]] !== entry[1]) {
                // console.log(localPreQuote[entry[0]], entry[1], entry[0]);
                passed = false;
                break;
            }
        }
        if (!passed) return passed;
        //Compare object contents
        passed = isSavedObjectCheck(preQuoteData, localPreQuote, "items", passed)
        if (!passed) return passed;
        passed = isSavedObjectCheck(preQuoteData, localPreQuote, "jobNotes", passed)
        if (!passed) return passed;
        passed = isSavedObjectCheck(preQuoteData, localPreQuote, "jobNotesInternal", passed)
        if (!passed) return passed;
        return passed;
    }


    const isSavedObjectCheck = (cloudData, localData, objectKeyName, passed) => {
        const cloudItems = objectCopy(cloudData[objectKeyName]);
        const localItems = objectCopy(localData[objectKeyName]);
        if (Object.entries(cloudItems).length !== Object.entries(localItems).length) return false;

        for (let index = 0; index < Object.entries(cloudItems).length; index++) {
            const entry = Object.entries(cloudItems)[index];
            if (localItems[entry[0]] !== entry[1]) {
                const localItemObject = localItems[entry[0]];
                const cloudItemObject = entry[1];
                //console.log(objectKeyName, localItemObject, cloudItemObject, entry[0]);

                for (let index = 0; index < Object.entries(localItemObject).length; index++) {
                    const itemEntry = Object.entries(localItemObject)[index];
                    // console.log(objectKeyName, "cloudItemObject[itemEntry[0]] !== itemEntry[1]", cloudItemObject[itemEntry[0]], itemEntry[1]);
                    if (cloudItemObject[itemEntry[0]] !== itemEntry[1]) passed = false
                    if (!passed) break;
                }
                if (!passed) break;
            }
        }
        return passed;
    }


    const localHandleClose = () => {
        removeRefreshWarning();
        if (!isSaved()) {
            //showAlert(message, header, onCloseCallback, showAccept, acceptVerbiage, isError, acceptCallback)
            showAlert("You have unsaved items.  Are you sure you want to close before saving?", "NOT SAVED!", () => {
                return;
            }, true, "Yes, close WITHOUT saving.", true, () => {
                closeCallStack();
            }, "Cancel");
        } else {
            closeCallStack();
        }
        return;
    }

    const closeCallStack = () => {
        removeRefreshWarning();
        handleClose();
        clearUser();
        clearPreQuoteData();
        setPreQuote(new PreQuote());
        setAddress1("");
        setAddress2("");
        setCity("");
        setState("");
        setZip("");
        setCitiesList([]);
        setZipcodeList([]);
        setCustomerDDS("");
        setPlaceIsLocked(false);
        setToggleClose(true);
    }



    console.log("preQuote", preQuote);


    // Calculate amount, tax, and total
    const calculateAmtTaxTotal = (preQuoteIn) => {
        const NJ_SALES_TAX_RATE = 0.06625; // New Jersey Sales Tax Rate
        let amount = 0;
        const items = preQuoteIn.items;
        if (items) {
            Object.keys(items).forEach(key => {
                const item = items[key];
                const itemTotal = (item.quantity || 0) * (item.cost || 0);
                amount += itemTotal;
            });
        }
        const tax = amount * NJ_SALES_TAX_RATE;
        const total = amount + tax;
        return { amount, tax, total };
    }

    //preQuote.set("amount", amount);
    //preQuote.set("tax", tax);
    //preQuote.set("total", total);


    const handleSetPreQuoteItems = (setCallback) => {
        const newItems = setCallback();
        console.log(newItems);
        setPreQuote((prev) => setCallback(prev));
    }


    const localHandleSave = async () => {
        if (!preQuote.selectedService) {
            showAlert("You must select a service under 'Service Details' before saving.", "Missing required fields", undefined, undefined, undefined, true);
            return;
        }

        if (!preQuote.serviceStartDate) {
            showAlert("You must add a service start date.", "Missing required fields", undefined, undefined, undefined, true);
            return;
        }

        if (!preQuote.serviceEndDate) {
            showAlert("You must add a service end date", "Missing required fields", undefined, undefined, undefined, true);
            return;
        }
        const paramPreQuote = objectCopy(preQuote);
        paramPreQuote.activity.isUpdated = false;
        paramPreQuote.activity.updateHistory.push(new Date().toISOString());
        paramPreQuote.activity.isNew = true;
        const savedPreQuote = await handleSave(paramPreQuote);
        if (savedPreQuote) {
            showAlert(`Pre-Quote ID: ${savedPreQuote.objectId} has been saved successfully.`, "Saved!");
            setPreQuote((prev) => {
                const newPreQuote = new PreQuote(prev);
                newPreQuote.isset = true;
                newPreQuote.ready = true;
                newPreQuote.objectId = savedPreQuote.objectId;
                newPreQuote.createdAt = savedPreQuote.createdAt;
                newPreQuote.updatedAt = savedPreQuote.updatedAt;
                newPreQuote.isActive = savedPreQuote.isActive;
                return newPreQuote;
            })
            return;
        }
        showAlert("Unable to save at this time, please contact your site admin.", "Save Error!", undefined, undefined, undefined, true)
    }

    const localDeletePreQuote = (preQuoteId) => {
        showAlert([`Are you sure you want to delete Pre-Quote ID: ${preQuoteId}.`, ` This will permanently delete the Pre-Quote from the database and it CANNOT be recovered.`], "Are you sure?", () => {
            return;
        }, true, "Yes, delete", false, async () => {
            try {
                if (await deletePreQuote(preQuoteId)) {
                    showAlert(`Pre-Quote ID: ${preQuoteId} deleted successfully.`, "Pre-Quote Deleted!", async () => {
                        await parentDeletePreQuote();
                        return closeCallStack();
                    });
                } else {
                    showAlert("That Pre-Quote does not exist.", "Pre-Quote already Deleted.", async () => {
                        await parentDeletePreQuote();
                        return closeCallStack();
                    });
                }

            } catch (error) {
                showAlert(`An error occured while attempting to delete Pre-Quote ID: ${preQuoteId}. The server replied with an error of: ${error.message}`, "Save Error!", () => {
                    return;
                }, true, "Close", true, async () => {
                    await parentDeletePreQuote();
                    return closeCallStack();
                }, "Ok.");
            }
        }, "Cancel");
    }

    const localHandleFinalize = async (thisPreQuote) => {
        if (is_string(thisPreQuote.serviceStartDate)) thisPreQuote.serviceStartDate = new Date(thisPreQuote.serviceStartDate);
        if (is_string(thisPreQuote.serviceEndDate)) thisPreQuote.serviceEndDate = new Date(thisPreQuote.serviceEndDate);
        try {
            // console.log(thisPreQuote);
            const newQuoteId = await handleFinalize(thisPreQuote.objectId);
            showAlert(`Pre-Quote ID: ${thisPreQuote.objectId} conversion successfully to new Quote ID: ${newQuoteId}.  The customer will now have access to view this quote.`, "Quote submitted!", async () => {
                return closeCallStack();
            });
        } catch (error) {
            showAlert(`An error occured while attempting to submit Pre-Quote ID: ${thisPreQuote.objectId}. The server replied with an error of: ${error.message}.  Please contact your system admin. Hint: That's Matt.`, "Quote Submit Error!", () => {
                return;
            }, true, "Close", true, async () => {
                return closeCallStack();
            }, "Ok.");
        }
    }


    //TODO: Impliment Create new customer functionality.


    const addressExists = (cloudORlocal = 'both') => {
        switch (cloudORlocal) {
            case "local":
                //console.log(city, state, zip, address1);
                if (city.length && state.length && zip.length && address1.length) return true
                break;
            case "cloud":
                if (preQuote.toCity.length && preQuote.toState.length && preQuote.toZip.length && preQuote.toAddress1.length) return true
                break;
            case "both":
                if ((preQuote.toCity.length && preQuote.toState.length && preQuote.toZip.length && preQuote.toAddress1.length)
                    || (city.length && state.length && zip.length && address1.length)) return true
                break;
            default:
                break;
        }
        return false;
    }

    const addressIsEqual = () => {
        // console.log("ADDRESS $$$ addressIsEqual", preQuote.toCity === city, preQuote.toState === state, preQuote.toZip === zip, preQuote.toAddress1 === address1);
        if (preQuote.toCity === city && preQuote.toState === state && preQuote.toZip === zip && preQuote.toAddress1 === address1) return true;
        return false
    }





    const renderAddressTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <i>Street addresses are not validated.</i>
        </Tooltip>
    );

    // console.log("preQuoteData", preQuoteData, "preQuote", preQuote);
    // console.log(users);

    return (
        <Modal backdrop="static" show={show} onHide={localHandleClose} size={toggleWindowFullScreen ? "fullscreen" : "xl"}>
            <RenderAlertModal />
            <Modal.Header className='d-inline'>
                <div className='d-flex justify-content-between align-items-center'>
                    <Modal.Title>Create a Quote</Modal.Title>

                    {/* 
                    <Button onClick={() => {
                        const newPreQuote = new PreQuote(preQuote);
                        newPreQuote.jobNotes = testJobNotes;
                        setPreQuote(newPreQuote)
                    }}>Add Test Jobs</Button>
                    <Button onClick={() => {
                        const newPreQuote = new PreQuote(preQuote);
                        newPreQuote.items = testItems;
                        setPreQuote(newPreQuote)
                    }}>Add Test Items</Button>
                    */}
                    <div className='text-white'>
                        {preQuote.objectId ? <Badge className='me-2' bg="secondary">ID: {preQuote.objectId}</Badge> : <Badge className='me-2' bg="success">New Pre-Quote</Badge>}
                        {toggleWindowFullScreen ? <WindowStack className='myButton' size={20} onClick={() => { setToggleWindowFullScreen(false) }} /> :
                            <Window className='myButton' size={20} onClick={() => { setToggleWindowFullScreen(true) }} />}
                        <XSquareFill className='ms-3 myButton' size={20} onClick={localHandleClose} />
                    </div>

                </div>

            </Modal.Header>
            <Modal.Body>
                <Form autoComplete="off">
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Card className='bg-light'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="0">Preparer Info</CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse className="" eventKey="0">
                                <Card.Body className='p-0'>
                                    {/* COMPANY INFO SECTION ***** COMPANY INFO SECTION ***** COMPANY INFO SECTION ***** COMPANY INFO SECTION ***** COMPANY INFO SECTION *****  */}
                                    <Row className='p-2 pb-4'>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Full Name</Form.Label>
                                                <FormControl
                                                    type="text"
                                                    name="fromName"
                                                    value={preQuote.fromName}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Company</Form.Label>
                                                <FormControl
                                                    type="text"
                                                    name="fromCompany"
                                                    value={preQuote.fromCompany}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Email</Form.Label>
                                                <FormControl
                                                    type="email"
                                                    name="fromEmail"
                                                    value={preQuote.fromEmail}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Mobile Number</Form.Label>
                                                <FormControl
                                                    type="tel"
                                                    name="fromMobileNumber"
                                                    value={preQuote.fromMobileNumber}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className='bg-light'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="1">Customer Info</CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body className='p-0'>
                                    {/* CUSTOMER INFO SECTION ***** CUSTOMER INFO SECTION ***** CUSTOMER INFO SECTION ***** CUSTOMER INFO SECTION ***** CUSTOMER INFO SECTION ******/}
                                    <Row className='align-items-end  m-0 pt-2 px-2'>
                                        <Col sm={6} lg={4} xl={3} className='text-right'>
                                            <Button variant={customerDDS === "" ? "info" : "secondary"} disabled={customerDDS === "" ? false : true}>{customerDDS === "" ? "Create New Customer" : <i>Customer Selected</i>}</Button>
                                        </Col>
                                        <Col sm={6} lg={4} xl={3}>
                                            <TextInputDDS
                                                label={"Customer Selection"}
                                                placeholder={"Choose..."}
                                                options={users.map((userObject) => {
                                                    return objectHasKey(userObject, "name") ? userObject.name : `${userObject.firstname} ${userObject.lastname}`;
                                                })}
                                                selectedValue={customerDDS}
                                                onSelect={handleSelectCustomer}
                                            />
                                        </Col>
                                        <hr className='mt-4 mb-0' />
                                    </Row>
                                    <Row className='p-2 pb-4'>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Full Name</Form.Label>
                                                <FormControl
                                                    type="text"
                                                    name="toName"
                                                    value={preQuote.toName}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Company</Form.Label>
                                                <FormControl
                                                    type="text"
                                                    name="toCompany"
                                                    value={preQuote.toCompany}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Email</Form.Label>
                                                <FormControl
                                                    type="email"
                                                    name="toEmail"
                                                    value={preQuote.toEmail}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group>
                                                <Form.Label className='text-black'>Mobile Number</Form.Label>
                                                <FormControl
                                                    type="tel"
                                                    name="toMobileNumber"
                                                    value={preQuote.toMobileNumber}
                                                    onChange={handleInputChange}
                                                // isInvalid={!isValid}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className='bg-light'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="2">Time & Place</CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body className='p-0'>
                                    {/* TIME & PLACE SECTION ***** TIME & PLACE SECTION ***** TIME & PLACE SECTION ***** TIME & PLACE SECTION ***** TIME & PLACE SECTION ******/}
                                    <Row className=' m-0 pt-2 px-2'>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='text-black'>Service Start Date</Form.Label>
                                                <DatePicker
                                                    locale={enUS}
                                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                                    showIcon
                                                    minDate={new Date()}
                                                    selected={is_string(preQuote.serviceStartDate) ? new Date(preQuote.serviceStartDate) : preQuote.serviceStartDate}
                                                    onChange={(date) => handleDateChange('serviceStartDate', date)}
                                                    showTimeSelect
                                                    todayButton="Today"
                                                    isClearable={true}
                                                    placeholderText="Please select a start date."
                                                    style={{ zIndex: 1 }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='text-black'>Service End Date</Form.Label>
                                                <DatePicker
                                                    locale={enUS}
                                                    showIcon
                                                    minDate={new Date()}
                                                    dateFormat="MM/dd/yyyy hh:mm aa"
                                                    selected={is_string(preQuote.serviceEndDate) ? new Date(preQuote.serviceEndDate) : preQuote.serviceEndDate}
                                                    showTimeSelect
                                                    onChange={(date) => handleDateChange('serviceEndDate', date)}
                                                    todayButton="Today"
                                                    isClearable={true}
                                                    placeholderText="Please select an end date."
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className=' m-0 pt-2 px-2'>
                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group controlId="formGridAddress1">
                                                <Form.Label className='text-black d-flex'>Address
                                                    <OverlayTrigger overlay={renderAddressTooltip}>
                                                        <QuestionCircle size={12} className='ms-1 pointer text-secondary' />
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    disabled={placeIsLocked}
                                                    value={preQuote.toAddress1 === address1 ? preQuote.toAddress1 : address1}
                                                    onChange={(e) => setAddress1(e.target.value)}
                                                    isInvalid={!addressIsValid}
                                                    autoComplete="off"

                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    Street address is required.
                                                </Form.Control.Feedback>
                                                {preQuote.toAddress1 !== address1 && address1 !== "" ?
                                                    <i><small className='text-warning'>Setting not yet applied</small> </i> : <div>&nbsp;</div>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} lg={4} xl={3}>
                                            <Form.Group controlId="formGridAddress2">
                                                <Form.Label className='text-black'>Apt, floor, etc...</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    disabled={placeIsLocked}
                                                    value={address2}
                                                    onChange={(e) => setAddress2(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row className='m-0 p-2 pb-4 align-items-center'>
                                        <Col md={6} lg={4} xl={3}>

                                            <TextInputDDS
                                                label="City"
                                                disabled={placeIsLocked}
                                                autoMenuOpen={true}
                                                options={citiesList}
                                                selectedValue={city}
                                                onSelect={(value) => { setCity(value); setCitiesLoading(false); }}
                                                mustExist={preQuote.toState.length || state.length}
                                                onClear={clearCity}
                                                fixedFeedbackLocation={true}
                                                feedBackReplacement={preQuote.toCity !== city && city !== "" ?
                                                    <i><small className='text-warning'>Setting not yet applied</small> </i> : <div>&nbsp;</div>}
                                            />
                                            {state && !citiesList.length && !placeIsLocked ?
                                                <small className='text-primary link pointer icon-link icon-link-hover' onClick={() => refreshCityList(state || preQuote.toState || "")}>Lookup Cities</small> : <div></div>}

                                        </Col>

                                        <Col md={6} lg={4} xl={3}>
                                            <TextInputDDS
                                                label="State"
                                                placeholder="Choose..."
                                                disabled={placeIsLocked}
                                                options={getUSStates(false)}
                                                selectedValue={preQuote.toState ? preQuote.toState : state}
                                                onSelect={(value) => setState(value)}
                                                onClear={clearState}
                                                mustExist={true}
                                                fixedFeedbackLocation={true}
                                                feedBackReplacement={preQuote.toState !== state && state !== "" ?
                                                    <i><small className='text-warning'>Setting not yet applied</small> </i> : <div>&nbsp;</div>}
                                            />

                                        </Col>

                                        <Col md={5} lg={3} xl={2}>

                                            <TextInputDDS
                                                label="Zip"
                                                disabled={placeIsLocked}
                                                options={zipcodeList}
                                                selectedValue={zip}
                                                onSelect={(value) => { setZip(value) }}
                                                onClear={clearZip}
                                                mustExist={zipMustExist}
                                                fixedFeedbackLocation={true}
                                                autoMenuOpen={true}
                                                feedBackReplacement={preQuote.toZip !== zip && zip !== "" ?
                                                    <i><small className='text-warning'>Setting not yet applied</small> </i> : <div>&nbsp;</div>}
                                            />

                                        </Col>
                                        <Col md={3} xl={2}>
                                            {preQuote.toCity && preQuote.toState && city && state && !zip && !zipcodeList.length && !placeIsLocked ? <Button variant="info" onClick={() => findZipCode(city, state)}><i>Lookup Zip</i></Button> : <></>}
                                            {/* console.log("addressExists(local)", addressExists("local"), "!addressIsEqual()", !addressIsEqual()) */}
                                            {addressExists("local") && !addressIsEqual() ?
                                                <Button className='mx-1' onClick={() => handleApplyAddress()}>Apply</Button>
                                                : <></>}
                                            {addressExists("cloud") && placeIsLocked ? <Button variant='secondary' onClick={() => setPlaceIsLocked(false)}>Unlock to Edit</Button> : <></>}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className='bg-light-gray'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="3">Service Details</CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    {/* SERVICE DETAILS SECTION ***** SERVICE DETAILS SECTION ***** SERVICE DETAILS SECTION ***** SERVICE DETAILS SECTION ******/}
                                    <div className='d-flex align-items-end mb-4'>
                                        <h5 className='me-3'>Service Type: </h5>
                                        <Dropdown onSelect={(eventKey, event) => setPreQuote((prev) => {
                                            const newPreQuote = new PreQuote(prev);
                                            newPreQuote.isset = true;
                                            newPreQuote.ready = true;
                                            newPreQuote.selectedService = event.target.attributes.value.nodeValue
                                            return newPreQuote;
                                        })}
                                        >
                                            <Dropdown.Toggle variant={preQuote.selectedService ? "secondary" : "warning"}>
                                                {preQuote.selectedService && preQuote?.services ? preQuote.services[preQuote.selectedService] : <b>Select a service...</b>}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {preQuote?.services ? Object.entries(preQuote.services).map((entry, index) => {
                                                    return <Dropdown.Item key={entry[0]} aria-selected={entry[0]} eventKey={index + 1} value={entry[0]}>{entry[1]}</Dropdown.Item>
                                                }) : <></>}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <Stack direction="horizontal" className='justify-content-end'>
                                        <div className='px-2'><b>Amount:</b> ${roundTo(amountTaxTotal.amount, 2, true)}</div>
                                        <div className='px-2'><b>Tax:</b> ${roundTo(amountTaxTotal.tax, 2, true)}</div>
                                        <div className='ps-2'><b>Total:</b> ${roundTo(amountTaxTotal.total, 2, true)}</div>
                                    </Stack>
                                    <ServiceItemsSection preQuoteData={preQuoteData} preQuote={preQuote} setPreQuote={setPreQuote} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        {/* Job Notes | Public Section */}
                        <Card className='bg-light-blue'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="4">Job Notes | <span className='text-white '>Public</span></CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    {/* JOB NOTES SECTION ***** JOB NOTES SECTION ***** JOB NOTES SECTION ***** JOB NOTES SECTION ******/}
                                    <JobNotesPublicSection preQuote={preQuote} setPreQuote={setPreQuote} currentUser={currentUser} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        {/* Job Notes | Internal Section */}
                        <Card className='bg-warning'>
                            <Card.Header>
                                <CreateAQuoteToggle eventKey="5">Job Notes | <span className='text-warning bold'>Internal</span></CreateAQuoteToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    {/* JOB NOTES INTERNAL SECTION ***** JOB NOTES INTERNAL SECTION ***** JOB NOTES INTERNAL SECTION ***** JOB NOTES INTERNAL SECTION ******/}
                                    <JobNotesInternalSection preQuote={preQuote} setPreQuote={setPreQuote} currentUser={currentUser} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                <div>
                    <Button onClick={() => localDeletePreQuote(preQuote.id)} variant='danger'>Delete</Button>
                    <Button className='mx-4' variant="success" onClick={() => localHandleFinalize(preQuote)}>
                        Submit To Customer
                    </Button>

                </div>

                <div className='d-flex'>
                    <Button variant="primary" className='m-0 mx-4' onClick={localHandleSave}>
                        Save
                    </Button>

                    <Button variant="secondary" className="m-0" onClick={localHandleClose}>
                        Close
                    </Button>
                </div>

            </Modal.Footer>
        </Modal>
    );
};

export default CreateAQuote;
