import React, { useState } from 'react'
import Parse from 'parse';
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import useCustomAlert from '../../library/useCustomAlert';
import { useNavigate } from 'react-router-dom';

function PasswordReset() {
    const [email, setEmail] = useState("");
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const navigate = useNavigate();

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        if (!email) return;
        try {
            await Parse.User.requestPasswordReset(email);
            showAlert("If your email matches the email address we have on file, you'll recieve a link to reset your password.  If you have not received it, and have checked your spam, please contact customer support.", "Email Sent!", () => navigate("/login"));
        } catch (error) {
            showAlert("An unknown server as occured. If the problem persists please contact customer support.", "Server Error", () => { }, false, "", true, () => { }, "Ok");
            console.error(error);
        }

    }

    return (
        <Container className="login-container">
            <RenderAlertModal />
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <div className='form-header-login rounded-top-3 p-2 mx-auto'>
                        <h2 className='text-dark'>Password Reset</h2>

                    </div>
                    <Form className='form-container-login mt-0 rounded-top-0 mx-auto' onSubmit={handlePasswordReset}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className='form-label'>Please enter the email address associated with your account.</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="your-email@here.com"
                                className='form-control'
                                required={true}
                            />
                        </Form.Group>
                        <Button type='submit' >Send Link</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default PasswordReset