import React, { useState, useEffect, useRef } from 'react'
import '../AccountPage.css';
import Order from '../../../classes/Order';
import useAdmin from '../../../library/useAdmin';
import { useUser } from '../../../context/UserContext';
import useCustomAlert from '../../../library/useCustomAlert';
import { useNavigate } from 'react-router-dom';
import useMyLocation from '../../../library/useMyLocation';
import { MyDate } from '../../../library/library';
import OrderDisplay from '../OrderDisplay';
import { Spinner } from 'react-bootstrap';

function SingleOrderDisplay() {
    const { saveOrder, getOpenOrdersByAdmin, sendEmailAsAdmin, convertOrderToQuote, getAdminDashboardCounts, getAllOpenQuotesAsAdmin, getClosedOrdersByAdmin } = useAdmin();
    const { currentUser, directOpenQO, getUserQuoteOrOrder, fetchUserOrders } = useUser();
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const navigate = useNavigate();
    const [location] = useMyLocation({ from: "" });
    const [fromLocation] = useState(location.state?.from || "/account");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showOrder, setShowOrder] = useState(false);
    const loadingOrder = useRef(false);

    console.log("showOrder", showOrder, "selectedOrder", selectedOrder);

    //http://127.0.0.1:3000/account/order/NiQw86X5LC

    useEffect(() => {
        let mounted = true;
        if (mounted && location && location?.pathname && !loadingOrder.current) {
            const orderId = location.pathname.split("/")[3];
            console.log(location, orderId);
            if (orderId) {
                loadingOrder.current = true;
                (
                    async () => {
                        const thisQuoteOrOrder = await getUserQuoteOrOrder(orderId);
                        console.log(thisQuoteOrOrder.isset, thisQuoteOrOrder.ready, thisQuoteOrOrder.is === "ORDER", thisQuoteOrOrder);
                        if (thisQuoteOrOrder.isset && thisQuoteOrOrder.ready && thisQuoteOrOrder.is === "ORDER") {
                            console.log("Setting newly fetched selected Order");
                            setSelectedOrder(thisQuoteOrOrder);
                            setShowOrder(true);
                            loadingOrder.current = false;
                            return;
                        }
                        if (!thisQuoteOrOrder.isset && thisQuoteOrOrder.error) {
                            showAlert(thisQuoteOrOrder.error, "Loading Error", handleCloseOrder, false, "", true);
                        }
                        if (thisQuoteOrOrder.isset && thisQuoteOrOrder.ready && thisQuoteOrOrder.is === "QUOTE") {
                            console.log("Setting ShowOrder FALSE");
                            showAlert("That quote has already been converted to an order and a quote hyperlink was supplied. If you reached this page in error, please contact customer support.", "Quote not found", handleCloseOrder, false, "", true);
                            setShowOrder(false);

                        }
                        loadingOrder.current = false;
                    }
                )();
            }
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        console.log("Updated showOrder:", showOrder, "Updated selectedOrder:", selectedOrder, "directOpenQo", directOpenQO);
        if (directOpenQO && directOpenQO.isset && directOpenQO.ready && directOpenQO.is === "ORDER" && directOpenQO instanceof Order === true && !showOrder && !selectedOrder) {
            setSelectedOrder(directOpenQO);
            setShowOrder(true);
        }
    }, [showOrder, selectedOrder, directOpenQO]);



    const refreshOrders = () => {
        (
            async () => {
                const updated = await getUserQuoteOrOrder(location.pathname.split("/")[3], true);
                console.log("setting updated selected Order", updated);
                setSelectedOrder(updated);
            }
        )();
        if (currentUser.role === "Admin") {
            getOpenOrdersByAdmin(true);
            getClosedOrdersByAdmin(true);
            //  await handleGetAllOrders(true);
        }
        if (currentUser.role === "Customer") {
            fetchUserOrders('open', true);
            fetchUserOrders('closed', true);
            //  await handleGetAllOrders(true);
        }
    }

    const handleAccept = (orderIn, action) => {
        switch (action) {
            case "DELETE":
                handleDeleteOrder(orderIn);
                break;
            case "CANCEL":
                handleCancelOrder(orderIn);
                break;
            case "PAY":
                handleCloseOrder();
                break;
            case "NOTIFY":
                localHandleNotifyCustomer(orderIn);
                break;
            case "TOQUOTE":
                handleConvertOrderToQuote(orderIn);
                break;

            default:
                handleCloseOrder();
                break;
        }
    }


    const handleCloseOrder = () => {
        setShowOrder(false);
        navigate(fromLocation);
    }

    const handleConvertOrderToQuote = (orderIn) => {
        showAlert([`Are you sure you want to convert Order with ID: ${orderIn.orderId} back to a quote?`, ` The process is irrversible and the customer must accept the Quote again to return it to Order status.`], "Order Reversion Confirmation", () => {
            return;
        }, true, "Convert To Quote", false, async () => {
            try {
                const now = new MyDate();
                const conversionOrder = new Order(orderIn);
                conversionOrder.jobNotes[Object.values(orderIn.jobNotes).length] = { date: now.timeStamp, userId: currentUser.id, name: "System Admin", note: "Order converted back to quote for editing and reacceptance." };
                conversionOrder.jobNotesInternal[Object.values(orderIn.jobNotesInternal).length] = { date: now.timeStamp, userId: currentUser.id, name: currentUser.name, note: "Order conversion back to quote." };
                conversionOrder.activity.updateHistory.push(new Date().toISOString());
                conversionOrder.activity.isUpdated = false;
                await convertOrderToQuote(conversionOrder);
                refreshOrders();
                showAlert(`Order ID ${orderIn.id} has been converted back to a quote.`, "Conversion Successful!", () => {
                    setShowOrder(false);
                    getAdminDashboardCounts(true);
                    getAllOpenQuotesAsAdmin(true);
                });
            } catch (error) {
                showAlert(`Order conversion failed - please contact your system admin.`, "Server Error!");
            }
        }, "Cancel");
    }

    const localHandleNotifyCustomer = async (thisOrderData) => {
        if (await sendEmailAsAdmin(thisOrderData.toEmail, `Updated ${thisOrderData.services[thisOrderData.selectedService]} Order Notes`, `Hi ${thisOrderData.toName}, We've updated your order notes! You can view the order here: http://127.0.0.1:3000/account/order/${thisOrderData.id}.`, true)) {
            showAlert(`Customer notification successful. ${thisOrderData.toName} has recieved an email alert that their order notes have been updated.`, "Notification successful.");
            return;
        }
        showAlert(`An error occured while attempting to send the customer notification for Order ID: ${thisOrderData.id}. Please contact your site admin.`, "Notification Error!", () => { }, false, "", true);
    }

    const handleDeleteOrder = async (orderIn) => {
        showAlert([`Are you sure you want to delete Order with ID: ${orderIn.orderId}?`, ` The is permanent and the order cannot be recovered.`], "Order Deletion Confirmation", () => {
            return;
        }, true, "Yes, Unleash Destruction!", false, async () => {
            try {
                await orderIn.destroy({ id: currentUser.id, name: currentUser.name });
                refreshOrders();
                showAlert(`Order ID ${orderIn.id} has been deleted.`, "Order Destroyed.", () => {
                    setShowOrder(false);
                });
            } catch (error) {
                showAlert(`Order deletion failed - please contact your system admin.`, "Server Error!");
            }

        }, "Don't dissemble Johnny 5.");

    }

    const handleCancelOrder = async (orderIn) => {
        showAlert([`Are you sure you want to cancel Order with ID: ${orderIn.orderId}?`, ` The order will become inactive and can no longer be changed or utilized.`], "Order Cancel Confirmation", () => {
            return;
        }, true, "Yes, Sayonara!", false, async () => {
            try {
                await orderIn.cancel({ id: currentUser.id, name: currentUser.name });
                refreshOrders();
                showAlert(`Order ID ${orderIn.id} has been canceled.`, "Order Canceled!", () => {
                    setShowOrder(false);
                });
            } catch (error) {
                showAlert(`Order cancellation failed - please contact your system admin.`, "Server Error!");
            }

        }, "Just Kidding, Don't Cancel.");

    }


    const handleSaveOrder = async (request) => {
        try {
            const updatedOrder = await saveOrder(request);
            const newOrder = new Order(updatedOrder.attributes);
            newOrder.ready = true;
            newOrder.objectId = updatedOrder.id;
            newOrder.orderId = updatedOrder.id;
            setSelectedOrder(newOrder);
            getOpenOrdersByAdmin(true);
            return true;
        } catch (error) {
            showAlert(`An error occured while attempting to save Order with ID: ${request.orderId}. The server replied with an error of: ${error.message}`, "Save Error!", () => {
                return false;
            }, false, "", true);
        }
        return false;
    }



    const handleAddJobNotes = async (type = "", updatedOrder = new Order()) => {
        console.log("updatedOrder IN", updatedOrder);
        if (!updatedOrder?.exportForSave) updatedOrder = new Order(updatedOrder);
        showAlert([`Are you sure you want to save Order with ID: ${updatedOrder.orderId}.`, ` The changes will be immediately visible to both you and the customer. They will not be notified of the update unless you use the notification button at the bottom of the order.`], "Order Save Confirmation", () => {
            return;
        }, true, "Save!", false, async () => {
            type = type.toUpperCase();
            switch (type) {
                case "PUBLIC":
                    console.log(updatedOrder, updatedOrder.exportForSave());
                    if (await handleSaveOrder(updatedOrder.exportForSave())) {
                        showAlert(`Public Job Notes for order with ID: ${updatedOrder.orderId} saved successfully!`, "Note Saved");
                        refreshOrders();
                    }
                    break;
                case "INTERNAL":
                    if (await handleSaveOrder(updatedOrder.exportForSave())) {
                        showAlert(`Internal Job Notes for order with ID: ${updatedOrder.orderId} saved successfully!`, "Internal Note Saved");
                        refreshOrders();
                    }
                    break;

                default:
                    showAlert(`Something went wrong - that was not a valid save option. Please contact your system admin.`, "Save Error!", () => {
                        return;
                    }, false, "", true);
                    break;
            }
        }, "Nevermind.");

    }



    return (
        <div className='account-page'>
            <RenderAlertModal />
            {showOrder ?
                selectedOrder && <OrderDisplay order={selectedOrder} show={showOrder} onClose={handleCloseOrder} onAccept={handleAccept} addJobNotes={handleAddJobNotes} noClickAway={true} />
                :
                <div className='d-flex justify-content-center align-items-center w-100 text-white'>
                    <Spinner size={60} />
                    <h1 className='mx-3'>Loading, please wait...</h1>
                </div>
            }
        </div>
    )
}

export default SingleOrderDisplay