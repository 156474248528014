import React from 'react'
import { Table } from 'react-bootstrap'
import { getType, is_array, is_object, isset } from '../../../../library/library';

import './ReactiveTable.css';

/**
 * Component to render a reactive table.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the table.
 * @param {Array} props.data - The array of data to be displayed.
 * Each quote object should have a method `getTabulation` that returns an object with `headers` and `values`.
 * @param {Function} props.clickHandler - The function to handle click events on the table.
 * It should accept three arguments: type ('header' or 'row'), identifier (header name or objectId), and the event.
 * @param {string} [props.clickTypeHeader="single"] - The type of click on the header that triggers the `clickHandler`.
 * Accepts "single" for single click or "double" for double click.
 * @param {string} [props.clickTypeRow="double"] - The type of click on the row that triggers the `clickHandler`.
 * Accepts "single" for single click or "double" for double click.
 * 
 * @returns {React.ReactNode} The ReactiveTable component.
 */
function ReactiveTable({ title = "", data, clickHandler, clickTypeHeader = "single", clickTypeRow = "double", headerOverride = [], classCallbackName = "getTabulation", useRowNumbers = false, rowNumberHeader = "Item" }) {


    // console.log(getType(data[0][classCallbackName]), title, data);

    return (
        <div className='data-table-container' style={{ cursor: "pointer" }}>
            {title ?
                <div className='data-label-container'>
                    <h2 className='data-label'>{title}</h2>
                </div> : <></>
            }

            {isset(data) ?
                <>

                    <Table striped bordered hover responsive>
                        {getType(data[0][classCallbackName]) === "function" ? <>
                            <thead>
                                {isset(headerOverride) ?
                                    <tr>
                                        {headerOverride.map((header, index) => {
                                            return <th key={index}
                                                onClick={clickTypeHeader === "single" ? (e) => { clickHandler(`${title}|header`, header, e) } : () => { }}
                                                onDoubleClick={clickTypeHeader === "double" ? (e) => { clickHandler(`${title}|header`, header, e) } : () => { }}>{header}</th>
                                        })}
                                    </tr> :
                                    <tr>
                                        {data[0][classCallbackName]().headers.map((header, index) => {
                                            return <th key={index}
                                                onClick={clickTypeHeader === "single" ? (e) => { clickHandler(`${title}|header`, header, e) } : () => { }}
                                                onDoubleClick={clickTypeHeader === "double" ? (e) => { clickHandler(`${title}|header`, header, e) } : () => { }}>{header}</th>
                                        })}

                                    </tr>}
                            </thead>
                            <tbody>
                                {data.map((row, index) => (
                                    <tr key={index}>
                                        {row[classCallbackName]().values.map((value, index) => {

                                            // if (is_object(value) && value.hasOwnProperty("$$typeof") && !value.$$typeof.toString() === "Symbol(react.element)") console.log("11111#################################################", value, value.$$typeof.toString());
                                            if (is_object(value) && value.hasOwnProperty("$$typeof") && !value.$$typeof.toString() === "Symbol(react.element)") value = value.toString();
                                            return (<td key={`${row.objectId}|${index}`}
                                                onClick={clickTypeRow === "single" ? (e) => { clickHandler(`${title}|row`, row.objectId, e) } : () => { }}
                                                onDoubleClick={clickTypeRow === "double" ? (e) => { clickHandler(`${title}|row`, row.objectId, e) } : () => { }}
                                            >{value}</td>)
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </> : is_array(data) ?
                            <>
                                <thead>
                                    <tr>
                                        {useRowNumbers ? <th>{rowNumberHeader}</th> : <></>}

                                        {headerOverride.map((header, index) => {
                                            return <th key={index}
                                                onClick={clickTypeHeader === "single" ? (e) => { clickHandler(`${title}|header`, header, e) } : () => { }}
                                                onDoubleClick={clickTypeHeader === "double" ? (e) => { clickHandler(`${title}|header`, header, e) } : () => { }}>{header}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((row, indexRow) => (
                                        <tr key={indexRow}>
                                            {useRowNumbers ?
                                                Object.values(row).length ? <td>{indexRow}</td> : <></>
                                                : <></>}

                                            {Object.values(row).map((value, indexCell) => {
                                                // if (is_object(value)) console.log("22222#################################################", value, typeof value);
                                                if (is_object(value)) value = value.toString();
                                                return <td key={`${row.objectId}|${indexCell}`}
                                                    onClick={clickTypeRow === "single" ? (e) => { clickHandler(`${title}|row`, row, e) } : () => { }}
                                                    onDoubleClick={clickTypeRow === "double" ? (e) => { clickHandler(`${title}|row`, indexRow, row, e.target.childNodes[0].nodeValue, e) } : () => { }}
                                                >{value}</td>
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </>
                            :
                            <>No table Data Exists</>}
                    </Table>
                </> :

                <> {/* Don't do stuff */}

                </>

            }



        </div>
    )
}

export default ReactiveTable