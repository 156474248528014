import React from 'react';
import { useLocation } from 'react-router-dom';

import './AccountContent.css';
import { Container, Spinner } from 'react-bootstrap';
import AdminDashboard from './account/AdminDashboard';
import CustomerDashboard from './account/CustomerDashboard';
import Profile from "../protected/profile/Profile";
import Users from './adminData/Users';
import Payments from './adminData/Payments';
import Quotes from './adminData/Quotes';
import Orders from './adminData/Orders';
import UserQuotes from '../protected/userData/UserQuotes';
import UserOrders from '../protected/userData/UserOrders';

function AccountPageContent({ currentUser, siteInfo }) {
    const location = useLocation();
    // Determine active item based on current URL
    const activeItem = location.pathname.split('/')[1] || 'home';



    switch (currentUser.role) {
        case "Admin":
            switch (activeItem) {
                case 'home':
                case 'account':
                    return <Container className='account-content mt-0 ' fluid>
                        <AdminDashboard />
                    </Container>;
                case 'profile':
                    return <Container className='account-content' fluid><Profile /></Container>;
                case 'users':
                    return <Container className='account-content' fluid><Users />  </Container>;
                case 'payments':
                    return <Container className='account-content' fluid><Payments />  </Container>;
                case 'quotes':
                    return <Container className='account-content' fluid><Quotes />  </Container>;
                case 'orders':
                    return <Container className='account-content' fluid><Orders />  </Container>;


                default:
                    return <Container className='account-content' fluid>Select an item</Container>;
            }

        case "Customer":
            switch (activeItem) {
                case 'home':
                case 'account':
                    return <Container className='account-content mt-0 ' fluid>
                        <CustomerDashboard />
                    </Container>;
                case 'profile':
                    return <Container className='account-content' fluid><Profile /></Container>;
                case 'users':
                    return <Container className='account-content' fluid> <>Users</></Container>;
                case 'payments':
                    return <Container className='account-content' fluid> <>Payments</></Container>;
                case 'quotes':
                    return <Container className='account-content' fluid><UserQuotes /></Container>;
                case 'orders':
                    return <Container className='account-content' fluid><UserOrders /></Container>;

                default:
                    return <Container className='account-content' fluid>Select an item</Container>;
            }



        default:
            return <Container className='account-content' fluid><Spinner /> Loading, please wait...</Container>;
    }






}

export default AccountPageContent;
