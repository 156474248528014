import React, { useState, useEffect, useRef } from 'react';
import { Form, Dropdown, InputGroup, FormControl, Button } from 'react-bootstrap';
import { XSquareFill } from 'react-bootstrap-icons';
import { inString } from '../library';
import './TextInputDDS.css';

function TextInputDDS({ name, label, placeholder, options, selectedValue, onSelect, onClear = () => { }, mustExist = true, showClear = true, fixedFeedbackLocation = false, feedBackReplacement, disabled = false, autoMenuOpen = false }) {
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isValid, setIsValid] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const debounceTimeoutRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (selectedValue) {
            setInputValue(selectedValue);
        }
    }, [selectedValue]);



    useEffect(() => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            const isOptionValid = options.includes(inputValue);
            if (mustExist) setIsValid(isOptionValid || inputValue === '');
        }, 750); // 750 ms debounce time

        const filtered = options.filter(option =>
            option.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredOptions(filtered);

        // Open the dropdown if there are filtered options and the input is not empty
        setDropdownOpen(filtered.length > 0 && inputValue !== '' && selectedValue === "");
    }, [inputValue, options, selectedValue, mustExist]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && filteredOptions.length > 0) {
                setInputValue(filteredOptions[0]);
                setFilteredOptions([]);
                handleBlur();
            }
        };

        if (inputRef.current) {
            inputRef.current.addEventListener('keydown', handleKeyDown);
        }
        const copiedInputRefCurrent = inputRef.current;

        return () => {
            if (copiedInputRefCurrent) {
                copiedInputRefCurrent.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [filteredOptions]);


    useEffect(() => {
        let mounted = true;
        if (mounted && filteredOptions.length && !inputValue && !selectedValue && autoMenuOpen) {
            setDropdownOpen(true);
        }
        if (mounted && filteredOptions.length && inputValue !== selectedValue && autoMenuOpen) {
            setDropdownOpen(true);
        }
        return () => mounted = false;
    }, [filteredOptions, inputValue, selectedValue, autoMenuOpen]);

    const handleInputChange = (e) => {
        if (mustExist) {
            setInputValue(e.target.value);
            if (options.includes(e.target.value)) {
                onSelect(e.target.value, { target: { name: name, value: e.target.value } });
            }
        } else {
            setInputValue(e.target.value);
            onSelect(e.target.value, { target: { name: name, value: e.target.value } });
        }
    };

    const handleSelect = (value) => {
        setInputValue(value);
        onSelect(value, { target: { name: name, value: value } });
        setIsValid(true);
        setDropdownOpen(false);
    };

    const handleClear = () => {
        setInputValue('');
        setIsValid(true);
        setDropdownOpen(false);
        onSelect(''); // Notify the parent component about the clear action
        onClear();
    };

    const handleFocus = () => {
        if (filteredOptions.length > 0) {
            setDropdownOpen(true);
        }
    };

    const handleBlur = () => {
        setDropdownOpen(false);
    };

    const handleDropdownToggle = (state, event) => {
        // Check if the event is triggered by clicking on the dropdown button
        const target = event.originalEvent.target;
        // const source = event.source;
        //  const eventPhase = event.originalEvent.eventPhase;
        if (inString(target.nodeName, "DIV")) {
            console.log("CLICKED AWAY");
            setDropdownOpen(false);
        }
        if (inString(target.nodeName, "INPUT")) {
            console.log("CLICKED In Text Box");
            handleFocus();
        }
        if (inString(target.nodeName, "BUTTON")) {
            console.log("BUTTON");
            setDropdownOpen(!dropdownOpen);
        }
        //  console.log(state, event.source, target.nodeName, source, eventPhase);
        if (state) {
            return setDropdownOpen(!dropdownOpen);
        }
    };

    return (
        <Form.Group>
            {label && <Form.Label className='text-black'>{label}</Form.Label>}
            <div> {/* Wrapper for layout */}
                <InputGroup>
                    <FormControl
                        type="text"
                        ref={inputRef}
                        placeholder={placeholder}
                        value={inputValue}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        isInvalid={!isValid}
                        disabled={disabled}
                    />
                    {disabled ? <></> :
                        <Dropdown show={dropdownOpen} onToggle={(state, event) => handleDropdownToggle(state, event)} alignRight>
                            <Dropdown.Toggle as={Button} variant="secondary" id="dropdown-custom-components" style={{ zIndex: 0 }}>
                                <span className="visually-hidden">Toggle Dropdown</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                {filteredOptions.map((option, index) => (
                                    <Dropdown.Item key={index} onClick={() => handleSelect(option)}>
                                        {option}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    {selectedValue && showClear && !disabled ? <Button onClick={handleClear} className='m-0 p-0' variant='light' style={{ zIndex: 0 }}>
                        <XSquareFill size={36} />
                    </Button> : <></>}
                </InputGroup>
                {/* Render feedback or placeholder */}

                {fixedFeedbackLocation ?
                    !isValid ? (
                        <Form.Control.Feedback type="invalid" style={{ display: isValid ? 'none' : 'block' }}>
                            Please select a valid option.
                        </Form.Control.Feedback>
                    ) : (
                        // Placeholder to reserve space for feedback
                        feedBackReplacement ? { ...feedBackReplacement } :
                            <div style={{ minHeight: '1.5em', display: "block" }}></div>
                    )
                    :
                    <Form.Control.Feedback type="invalid">
                        Please select a valid option.
                    </Form.Control.Feedback>
                }
            </div>

        </Form.Group>
    );
}

export default TextInputDDS;
