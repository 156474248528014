import React from 'react';
import { Button } from 'react-bootstrap';


const CopyButton = ({ textToCopy, color, size, className, svgClass }) => {
    const copyTextToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            alert('Text copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    return (
        <div className={`btn ${className}`} onClick={copyTextToClipboard} >
            <svg xmlns="http://www.w3.org/2000/svg" width={size || 16} height={size || 16} fill={color || "currentColor"} className={svgClass || "bi bi-copy"}
                viewBox={`0 0 16 16`}>
                <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
            </svg>
        </div>
    );
};

export default CopyButton;
