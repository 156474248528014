import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import useCustomAlert from '../../../library/useCustomAlert';
import PasswordResetModal from '../profile/PasswordResetModal';
import { useUser } from '../../../context/UserContext';
import useAuth from '../../auth/useAuth';

import './AdminDashboard.css';






function CustomerDashboard() {
    const { RenderAlertModal, showAlert, hideAlert } = useCustomAlert();
    const { currentUser, dashboardCounts, getDashboardCounts } = useUser();
    const { logout, setLoading } = useAuth();




    useEffect(() => {
        let mounted = true;
        if (mounted) setLoading(false)
        if (mounted) getDashboardCounts();
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);





    return (
        <Container className='dashboard-container' fluid>

            <div className='d-flex'>
                <h1>Customer Dashboard</h1>
                <ArrowClockwise className='icon-as-button no-background ms-2'
                    onClick={() => {
                        return getDashboardCounts(true);
                    }}
                    size={30} />

            </div>

            {/* Widgets Section */}
            <Row className="widgets my-3">

                {/* Widget for Number of Quotes */}
                <Col xl={2} lg={3} md={4} sm={5} xs={6}>
                    <Card className='widget'>
                        <Card.Body>
                            <Card.Title className='px-2 py-1'>Quotes</Card.Title>
                            <Card.Text className='p-2'>
                                Pending Requests: {dashboardCounts.quotes.requests || 0}<br />
                                {/*  Total: {dashboardCounts.quotes.total || 0} <br /> */}
                                Open: {dashboardCounts.quotes.open || 0} <br />
                                Closed: {dashboardCounts.quotes.closed || 0} <br />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Widget for Number of Orders */}
                <Col xl={2} lg={3} md={4} sm={5} xs={6}>
                    <Card className='widget'>
                        <Card.Body>
                            <Card.Title className='px-2 py-1'>Orders</Card.Title>
                            <Card.Text className='p-2'>
                                {/* Total: {dashboardCounts.orders.total || 0} <br /> */}
                                Open: {dashboardCounts.orders.open || 0} <br />
                                Closed: {dashboardCounts.orders.closed || 0} <br />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Widget for Number of Invoices */}
                <Col xl={2} lg={3} md={4} sm={5} xs={6}>
                    <Card className='widget'>
                        <Card.Body>
                            <Card.Title className='px-2 py-1'>Invoices</Card.Title>
                            <Card.Text className='p-2'>
                                Ready to Pay: {dashboardCounts.invoices.pending || 0} <br />
                                Paid: {dashboardCounts.invoices.paid || 0} <br />
                                All: {dashboardCounts.invoices.total || 0} <br />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Administrative Features Section */}
            <RenderAlertModal />
            <Row className='admin-features'>
                <Col>
                    <div className='admin-feature'>
                        <h2>Common Tasks</h2>
                        <PasswordResetModal currentUser={currentUser} logout={logout} />
                    </div>
                </Col>
            </Row>

        </Container>
    );
}

export default CustomerDashboard;
