import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner, Button } from 'react-bootstrap'

import Order from '../../../classes/Order';
import ReactiveTable from '../adminData/components/ReactiveTable';
import { useUser } from '../../../context/UserContext';
import { isset } from '../../../library/library';
import { useNavigate } from 'react-router-dom';
import useCustomAlert from '../../../library/useCustomAlert';
import useMyLocation from '../../../library/useMyLocation';
import OrderDisplay from '../OrderDisplay';
import { ArrowClockwise } from 'react-bootstrap-icons';


function UserOrders() {
    const { fetchUserOrders, openOrders, closedOrders, currentUser } = useUser();
    const { RenderAlertModal, showAlert } = useCustomAlert();
    const navigate = useNavigate();
    const [location, setLocation] = useMyLocation({ from: "" });
    const [fromLocation, setFromLocation] = useState(location.state?.from || "/account");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showOrder, setShowOrder] = useState(false);




    useEffect(() => {
        let mounted = true;
        if (mounted) {
            (
                async () => {
                    try {
                        await handleGetOpenOrders();
                        await handleGetClosedOrders();
                    } catch (error) {
                        console.error(error);
                    }
                }
            )();

        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);



    useEffect(() => {
        let mounted = true;
        if (mounted && location.state?.from) {
            setFromLocation(location.state.from)
        }
        return () => mounted = false;
        // eslint-disable-next-line
    }, []);


    const refreshOrders = async () => {
        await handleGetOpenOrders(true);
        await handleGetClosedOrders(true);
        //  await handleGetAllOrders(true);
    }



    const handleGetOpenOrders = async (forceRefresh = false) => {
        await fetchUserOrders('open', forceRefresh);
    }

    const handleGetClosedOrders = async (forceRefresh = false) => {
        await fetchUserOrders('closed', forceRefresh);
    }


    const orderRowClick = (location, value, e) => {
        console.log("orderRowClick Callback", location, value, e.target.childNodes[0]);
        // Find the order in the openOrders array using the order ID
        const selectedOrder = openOrders.find(order => order.objectId === value);
        if (selectedOrder) {
            // Logic to display the selected order
            // You can set the selected order to a state and then render the orderDisplay component with this state
            setSelectedOrder(selectedOrder); // Assuming you have a state called `setSelectedorder`
            setShowOrder(true);
        } else {
            console.error("Order not found");
            showAlert(`That order has been closed.`, "Closed Order");
            // Handle the scenario where the order is not found
        }
    }


    const handleAccept = (orderIn, action) => {
        switch (action) {
            case "CANCEL":
                handleCancelOrder(orderIn);
                break;
            case "PAY":
                handleCloseOrder();
                break;

            default:
                handleCloseOrder();
                break;
        }
    }


    const handleCloseOrder = () => {
        setShowOrder(false);
    }

    const handleCancelOrder = async (orderIn) => {
        showAlert([`Are you sure you want to cancel Order with ID: ${orderIn.orderId}.`, ` The order will become inactive and can no longer be changed or utilized.`], "Order Cancel Confirmation", () => {
            return;
        }, true, "Yes, Cancel Order.", false, async () => {
            try {
                await orderIn.cancel({ id: currentUser.id, name: currentUser.name });
                refreshOrders();
                showAlert(`Order ID ${orderIn.id} has been canceled.  We hope to earn your business in the future!`, "Order Canceled!", () => {
                    setShowOrder(false);
                });
            } catch (error) {
                showAlert(`Order cancellation failed - please contact customer support.`, "Server Error!");
            }

        }, "No, don't Cancel!");

    }

    console.log("openOrders", openOrders);
    // console.log("closedOrders", closedOrders);
    //console.log("allOrders", allOrders);


    return (
        <div className='p-md-4 pt-md-0'>
            <RenderAlertModal />
            <div className='d-flex justify-content-end'>

            </div>
            <div className='d-flex mt-2'>
                <h1>Orders Home</h1>
                <ArrowClockwise className='icon-as-button no-background ms-2'
                    onClick={() => {
                        return refreshOrders(true);
                    }}
                    size={30} />
            </div>

            <Row className='mt-3'>
                <Col>

                    {selectedOrder && <OrderDisplay order={selectedOrder} show={showOrder} onClose={handleCloseOrder} onAccept={handleAccept} />}
                    {openOrders[0].ready ? (openOrders[0].objectId ? <ReactiveTable data={openOrders} title={"Open"} clickHandler={orderRowClick} /> :
                        <div className='data-label-container color-black bold rounded-bottom-3'>No Open Orders</div>) : <div className='d-flex align-items-center'><Spinner className='me-3' /> Loading, please wait...</div>}
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    {closedOrders[0].ready ? (closedOrders[0].objectId ? <ReactiveTable data={closedOrders} title={"Closed"} clickHandler={orderRowClick} /> :
                        <div className='data-label-container color-black bold rounded-bottom-3'>No Closed Orders</div>) : <div className='d-flex align-items-center'><Spinner className='me-3' /> Loading, please wait...</div>}
                </Col>
            </Row>


        </div>

    );
}

export default UserOrders



